// Modules
import React from 'react'
import { useQuery } from 'react-query'
import { Link, useLocation, useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
import { Can } from "../../config/Common/Can"
// API
import { getAPI } from '../../functions/Api'
// Components
import { Layouts } from "../../Layouts/Layouts"
import { Button } from "../../components/Atoms/Button"
import { LoadingTable } from '../../components/Atoms/LoadingTable'
import { Error } from '../../components/Molecules/Error/Error'
import { DetailsUser } from '../../components/Molecules/Master/DetailsUser'
import { DetailsImages } from "../../components/Molecules/Master/DetailsImages"
// Functions
import { CommonFunctions } from "../../functions/CommonFunction"
// Config
import {
  getRoleURL,
  sysKeyGetURL
} from "../../config/Common/apiURL"
import { storageState } from "../../config/Common/RecoilState"
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faIdCard } from '@fortawesome/free-solid-svg-icons'
// Stylesheets
import '../../stylesheets/pages/Master/Master.scss'

// *************** Type *************** //
type Props = {
  getUrl: string
}

export const DetailsMaster: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { pathname } = useLocation()
  const path1 = pathname.split('/')[1]
  // 現在地の情報
  const {
    userType,
    uId
  } = useParams<Record<any, any>>()
  // ローカルストレージのデータを取得
  const storage = useRecoilValue(storageState)
  const { commons } = CommonFunctions()

  // *************** ReactQuery *************** //
  const promiseQuery = async () => {
    // user Data
    const userData = await getAPI(`${getRoleURL}/${userType}/${uId}`)
    // user Label Data
    const sysLabelData = await getAPI(`${sysKeyGetURL}/${path1}`)
    return {
      userData,
      sysLabelData
    }
  }

  const {
    isLoading,
    isError,
    data
  } = useQuery (
    uId,
    async () => promiseQuery()
  )

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="detailsWrap text-center">
        { isLoading ? (
          // ローディング時
          <LoadingTable />
        ) : isError ? (
          // エラー時
          <Error />
        ) : (
          <>
            <div className="topButtonWrap text-right">
              <Can
                I="read"
                a="docTemplateButton"
              >
                <Link
                  className="toMasterButton py-4 px-5 font-bold inline-block"
                  to="/DocTemplateForm"
                >
                  申請書テンプレ作成
                </Link>
              </Can>
              { (storage.admin || (storage.uId === uId)) && (
                <Link
                  className="toMasterButton inline-block ml-3 py-4 px-5 font-bold"
                  to={`/${userType}/${uId}/${data && data.userData.step ? data.userData.step : 'basic' }`}
                >
                  <FontAwesomeIcon icon={faIdCard} />
                  マスターデータ作成
                </Link>
              )}
            </div>
            {/* 詳細一覧 */}
            <DetailsUser
              data={data && data.userData}
              label={data && data.sysLabelData}
            />
            {/* 画像一覧 */}
            { data?.userData.images && (
              <DetailsImages
                id={data && data.userData.id}
                data={data && data.userData.images}
              />
            )}
            { (storage.userType === 'staffs') || !storage.adminApproval ? (
              <Link
                className="cancelButton ph5-per pv1-per"
                to="/"
              >
                トップへ
              </Link>
            ) : (
              <Button
                classProps="cancelButton ph5-per pv1-per"
                text="戻る"
                function={commons.backPage}
              />
            )}
          </>
        )}
      </main>
    </Layouts>
  )
}

export default DetailsMaster