// Modules
import React from "react"
// Swiper components
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel
} from 'swiper'
SwiperCore.use([
  Navigation,
  Pagination,
  Mousewheel,
])

// *************** Type *************** //
type Props = {
  images?: any
  docData?: any
}

export const DocImages: React.VFC<Props> = (
  props: Props
) => {
  // *************** Functions *************** //
  return (
    <Swiper
      className="swiperImage"
      autoHeight
      speed={700}
      pagination={{
        clickable: true,
        dynamicBullets: true
      }}
      navigation
      mousewheel
      loop
    >
      { props.images && props.images.map((png: any, i: number) => (
        <SwiperSlide key={i}>
          <img
            src={png}
            alt={`${props.docData.documentName}の画像ファイル`}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
