// Modules
import React from "react"
// Components
import { Textarea } from "../../Atoms/Textarea"
import { Button } from "../../Atoms/Button"
import { Modal } from "../../Atoms/Modal"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"

// *************** Type *************** //
type Props = {
  setModalType: Function
  modalData?: any
  dataState?: any
  setDataState: Function
}

export const UserMemoModal: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** JSX *************** //
  return (
    <Modal
      className="catalogModal absolute z-50 top-0 left-0"
      modalFunction={commons}
    >
      <div className="masterCatalogOverLay fixed top-1/4 left-1/4 z-20 w-1/2 p-10 rounded-xl">
        <h3 className="masterCatalogTitle pb-1 font-bold text-xl">
          ユーザーメモ
        </h3>
        <div>
          <div className="mt-2">
            <p className="font-bold">
              名前：{ props.dataState[props.modalData.index].name }
            </p>
          </div>
          <Textarea
            className="masterCatalogInput w-full mt-3 p-1 rounded-md"
            name="detail"
            value={props.dataState[props.modalData.index]['detail']}
            function={(e: React.ChangeEvent<HTMLTextAreaElement>) => commons.arraySetValue(
              props.modalData.index,
              e.target.name,
              e.target.value,
              props.dataState,
              props.setDataState
            )}
          />
          <Button
            classProps="cancelButton inline-block mt-5 p-1"
            text="閉じる"
            function={() => {
              commons.closeModal()
              props.setModalType('detail')
            }}
          />
        </div>
      </div>
    </Modal>
  )
}