// Modules
import React from 'react'
import {
  Switch,
  Route
} from "react-router-dom"
// Components
import { NotApproval } from '../pages/Top/NotApproval'
import { ChangeSysKey } from "../pages/Master/ChangeSysKey"
// Config
import {
  masterFormRoute,
  routePath
} from "../config/Routes"

export const NotUseContent: React.VFC = () => {
  // *************** JSX *************** //
  return (
    <Switch>
      <Route
        path='/'
        exact
      >
        <NotApproval />
      </Route>
      { routePath.map((route: any, i: number) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}

      {/*　マスターデータ登録ページ　*/}
      { masterFormRoute.map((form: any, f: number) => (
        <Route
          key={f}
          path={form.path}
          exact
        >
          <ChangeSysKey
            questionUrl={form.questionUrl}
            step={form.step}
            cautionText={form.cautionText}
          />
        </Route>
      ))}

    </Switch>
  )
}