// Modules
import React from 'react'
// Components
import { Label } from '../../Atoms/Label'
import { Select } from "../../Atoms/Select"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"

// *************** Type *************** //
type Props = {
  usersArray?: Array<any>
  form?: any
  setForm: Function
  errorMessage?: any
  setErrorMessage: Function
}

export const SelectUser: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  const visaType = [
    { text: '特定技能', value: 'specificSkill' },
    { text: '技能実習', value: 'skillTraining' }
  ]

  // *************** JSX *************** //
  return (
    <ul className="sysKeySelect mt-5">
      <h3>選択項目</h3>
      <li>
        <Label
          className="pb-1"
          htmlFor="visa"
          text="就労VISA"
        />
        <select
          id="visa"
          name="visa"
          value={props.form ? props.form?.visa : ''}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            commons.checkValidation(
              false,
              e.target.name,
              '就労VISA',
              '',
              e.target.value,
              '',
              props.setErrorMessage,
              false
            )
            commons.setValue(
              e.target.name,
              e.target.value,
              props.setForm
            )
          }}
        >
          <optgroup label="就労VISA">
            <option
              hidden
            >
              就労VISAを選択してください
            </option>
            { visaType.map((visa, h) => (
              <option
                key={h}
                value={visa.value}
              >
                {visa.text}
              </option>
            ))}
          </optgroup>
        </select>
        { props.errorMessage && (
          <p className="errorMessage fw-b">
            { props.errorMessage.visa }
          </p>
        )}
      </li>
      { props.usersArray && props.usersArray.map((u: any, j: number) => (
        <li
          className="selectList"
          key={j}
        >
          <Label
            className="pb-1"
            htmlFor={u.user}
            text={`${u.name}選択`}
          />
          <Select
            name={u.user}
            label={u.name}
            value={props.form ? props.form[u.user] : ''}
            options={u.options}
            optionKey={u.optionKey}
            optionValue="id"
            function={(e: React.ChangeEvent<HTMLSelectElement>) => commons.setValue(
              e.target.name,
              e.target.value,
              props.setForm
            )}
          />
        </li>
      ))}
    </ul>
  )
}
