// Modules
import { atom } from 'recoil'

// *************** RecoilState *************** //
// モーダルON/OFF
export const modalState = atom({
  key: 'modalState',
  default: false
})
// memoモーダルON/OFF
export const memoModal = atom({
  key: 'memoModal',
  default: false
})
// menuモーダルON/OFF
export const accountModal = atom({
  key: 'accountModal',
  default: false
})

// 成功メッセージ
export const successState = atom({
  key: 'success',
  default: false
})
// エラーメッセージ
export const errorState = atom({
  key: 'error',
  default: false
})
// ログインデータ
export const storageState = atom({
  key: 'login',
  default: JSON.parse(String(localStorage.getItem('user')))
})