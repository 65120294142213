// Modules
import React from 'react'
// Components
import { Tcell } from "./Tcell"

// *************** Type *************** //
type Props = {
  index?: number
  children?: any
  // array?: any,
  // cellTag: string
  // property: string
  trClass?: string
  cellClass?: string
}

export const Trow: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <tr className={props.trClass}>
    {props.children}
    {/*{ props.array.map((array: any, i: number) => (*/}
    {/*  <Tcell*/}
    {/*    tag={props.tag}*/}
    {/*    index={i}*/}
    {/*    text={ props.property ? array[props.property] : null }*/}
    {/*    cellClass={props.cellClass}*/}
    {/*  />*/}
    {/*))}*/}
  </tr>
)