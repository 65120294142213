// *************** Array *************** //
export const interviewTab = [
  {
    text: '応募者',
    path: '/applyStaffs'
  },
  {
    text: '面接者',
    path: '/interviewStaffs'
  }
]

export const recruitTab = [
  {
    text: '面接者',
    path: '/interviewStaffs'
  },
  {
    text: '内定者',
    path: '/recruitStaffs'
  }
]

export const rsosInterviewTab = [
  {
    text: '応募者',
    path: '/applyStaffs'
  },
  {
    text: '面接者',
    path: '/interviewStaffs'
  },
  {
    text: '内定者',
    path: '/recruitStaffs'
  }
]

// 応募者
export const applicantFixThead = [
  { title: "詳細", sticky: true, isSort: false },
  { title: "国籍", sticky: true, isSort: false },
  { title: "名前", sticky: true, isSort: false },
]

export const applicantThead = [
  { title: "性別", sticky: false, isSort: false },
  { title: "メールアドレス", sticky: false, isSort: false },
]
// テーブルボディ項目
export const applicantFixTbody = [
  { label: 'country' },
  { label: 'name' }
]
export const applicantTbody = [
  { label: 'gender' },
  { label: 'email' },
]

// 応募フォーム
export const applyInput: any = [
  { name: "companies", label: "受け入れ企業ID", number: 1, required: true },
  { name: "agencies", label: "送り出し機関ID", number: 2, required: false },
  { name: "rsos", label: "登録支援機関ID", number: 3, required: false },
  { name: "id", label: "候補者ID", number: 4, required: true },
]

export const applicantColumns = [
  {
    width: 130,
    Header: '国籍',
    accessor: 'country',
    sticky: 'left'
  },
  {
    width: 180,
    Header: '名前',
    accessor: 'name',
    sticky: 'left'
  },
  {
    Header: '性別',
    accessor: 'gender',
  },
  {
    width: 280,
    Header: 'メールアドレス',
    accessor: 'email',
  },
  {
    Header: '受け入れ企業名',
    accessor: 'companyName',
  },
  {
    Header: '送り出し機関名',
    accessor: 'agencyName',
  },
  {
    Header: '登録支援機関名',
    accessor: 'rsoName',
  }
]