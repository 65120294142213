// 管理者用
export const adminMenu = [
  {
    list: "申請書",
    link: '/document',
    can: 'documentMenu'
  },
  {
    list: "人材管理",
    link: `/staffs`,
    can: 'masterMenu'
  },
  {
    list: "ステータス",
    link: '/SpecifiedSkill/enterStatus',
    can: 'statusMenu'
  },
  {
    list: "面接",
    link: `/interviewStaffs`,
    can: 'interviewMenu'
  },
  {
    list: "投稿",
    link: `/post`,
    can: 'postMenu'
  },
  {
    list: "職歴",
    link: `/career`,
    can: 'careerMenu'
  }
]