// Modules
import React, {
  useEffect,
  useState
} from "react"
import {
  useMutation,
  useQuery,
  useQueryClient
} from "react-query"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { Button } from '../../components/Atoms/Button'
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import { Error } from "../../components/Molecules/Error/Error"
import { DownloadButton } from "../../components/Atoms/DownloadButton"
import { OpenButton } from "../../components/Atoms/OpenButton"
import { CareerModal } from "../../components/Molecules/Career/CareerModal"
// API
import { getAPI, postAPI } from "../../functions/Api"
// Config
import {
  specificSkillURL,
  skillTrainingURL,
  staffsURL,
  dynamicSubGet,
  dynamicArrayURL
} from "../../config/Common/apiURL"
import {
  specificStep,
  skillTrainingStep,
  specificCategory,
  skillCategory,
  specifiedGroup,
  // specificFields,
  specifiedFields,
  skillFields
} from "../../config/Document/DocumentConfig"
import { modalState } from "../../config/Common/RecoilState"
// Functions
import { CommonFunctions } from "../../functions/CommonFunction"
import { DocumentFunctions } from "../../functions/Document/DocumentFunctions"
// Stylesheets
import "../../stylesheets/components/Molecules/Table.scss"

type Props = {
  url: string
}

export const PersonalDocuments = (
  props: Props
) => {
  // ***************  Const *************** //
  const [staffState, setStaffState] = useState<any>({})
  const [careerList, setCareerList] = useState<Array<any>>([])
  const [isCategory, setIsCategory] = useState<any>()
  const [isGroup, setIsGroup] = useState<any>()
  const [isField, setIsField] = useState<any>()
  const [isDocument, setIsDocument] = useState<any>()
  // paramsデータ
  const {
    visa,
    id,
    language
  } = useParams<Record<any, any>>()
  // ビザ別提出先
  const stepList = visa === "specificSkill" ? specificStep
  : skillTrainingStep
  // 就労ビザによってURLを変える
  const visaURL = visa === "specificSkill" ? specificSkillURL
  : skillTrainingURL
  // 就労ビザ別でカテゴリーを変える
  const categoryList = visa === "specificSkill" ? specificCategory
  : skillCategory
  // 就労ビザ別でグループを変える
  const groupList = visa === "specificSkill" ? specifiedGroup
    : specifiedGroup
  // 就労ビザで分野リストを変える
  const fieldsList = visa === "specificSkill" ? specifiedFields
  : skillFields

  // *************** React Query *************** //
  const promises = async () => {
    // 候補者個別データ
    const staffData = await getAPI(`${staffsURL}/${id}`)
    //
    const careerData = await getAPI(`${dynamicSubGet}/career/id/==/${id}`)
    //
    const personalDoc = await getAPI(visaURL)
    // 外部で使用可能にする
    return {
      staffData,
      careerData,
      personalDoc
    }
  }
  const {
    isFetching,
    isLoading,
    isError,
    data
  } = useQuery(
    "personalDoc",
    async () => promises()
  )

  const queryClient = useQueryClient()
  const mutation = useMutation(data => postAPI(
    `${dynamicArrayURL}/staffs/career/span`,
    data
  ), {
    onError: async () => console.log('更新に失敗しました。'),
    onSuccess: async () => console.log('更新に成功しました。')
  })

  // *************** Functions *************** //
  const { commons } = CommonFunctions()
  const isModal = useRecoilValue(modalState)

  const { functions } = DocumentFunctions()

  useEffect(() => {
    if (isLoading) return
    else {
      setStaffState(data?.staffData)
      setCareerList(data?.careerData)
    }
  }, [isFetching, isModal])

  // *************** JSX (パーツ) *************** //
  const types = {
    /**
     * 提出先一覧
     * @returns {JSX.Element}
     */
    steps: () => {
      return (
        <ul className="docStep mt-8">
          { stepList.map((step, h) => (
            <li
              key={h}
              className="stepList"
            >
              <h3 className="font-bold fs25-px">{ step.label }</h3>
              {/* 書類様式一覧 */}
              { categoryList.map((category: any, c: number) => (
                step.category === category.step && types.categories(category, c)
              ))}
            </li>
          ))}
        </ul>
      )
    },
    /**
     * カテゴリー一覧
     * @param category カテゴリーデータ
     * @param i カテゴリーのindex
     * @returns {JSX.Element}
     */
    categories: (
      category: any,
      c: number
    ) => (
      <ul className="docCategory mt-8 pl-5">
        <li
          key={c}
          className="categoryList p-8 rounded-md bg-white"
          onClick={() => functions.openCategory(
            c,
            isCategory,
            setIsGroup,
            setIsCategory
          )}
        >
          <h4 className="inline-block font-bold text-xl cursor-pointer">
            { category.label }
          </h4>
          {/* グループ一覧 */}
          <ul
            className={`docGroup invisible opacity-0 max-h-0 mt-2 pl-3
              ${isCategory === c ? "open" : ""}
            `}
          >
            { groupList.map((group: any, g: number) => (
              category.group === group.category && types.groups(group, g)
            ))}
          </ul>
        </li>
      </ul>
    ),
    /**
     * グループ一覧
     * @param category カテゴリーデータ
     * @param i カテゴリーのindex
     * @returns {JSX.Element}
     */
    groups: (
      group: any,
      g: number
    ) => (
      <li
        key={g}
        className="groupList mt-2 first:mt-0 rounded-md"
        onClick={() => functions.openGroup(
          g,
          isGroup,
          setIsField,
          setIsGroup
        )}
      >
        { group.label &&
          <h4 className="p-2 rounded-md text-white font-bold text-xl cursor-pointer">
            { group.label }
          </h4>
        }
        {/* 分野一覧 */}
        { isGroup === g && (
          <ul
            className={`docFields transform invisible opacity-0 max-h-0 translate-x-4 p-3 rounded-md
              ${isGroup === g ? "open" : ""}
            `}
          >
            { fieldsList.map((field: any, f: number) => (
              group.field === field.group && types.fields(field, f)
            ))}
          </ul>
        )}
      </li>
    ),
    /**
     * 分野一覧
     * @param {*} i カテゴリーのindex
     * @param {*} dc カテゴリー一覧（２種）
     */
    fields: (
      field: any,
      f: number
    ) => (
      <li
        key={f}
        className="fieldsList cursor-pointer"
        onClick={() => functions.openField(
          f,
          isField,
          setIsDocument,
          setIsField
        )}
      >
        { types.documents(field) }
      </li>
    ),
    /**
     * 申請書一覧
     * @param field
     */
    documents: (field: any) => (
      data?.personalDoc.sort((a:any, b:any) => a.categoryNumber - b.categoryNumber).map((d:any, j:number) => (
        field.group === d.group && field.document === d.document && (
        <li key={j}>
          { d.sysKeys.length && d.documentCategory.slice(0,2) !== '別記' ? (
            <OpenButton
              className="transition duration-100 ease-in p-1 hover:text-blue-400"
              href={`/document/${visa}/${id}/${language}/${d.dId}`}
              text={`${ d.documentCategory.split("式")[1] } ${ d.documentName }`}
            />
          ) : d.sysKeys.length && d.documentCategory.slice(0,2) === '別記' ? (
            <OpenButton
              className="transition duration-100 ease-in p-1 hover:text-blue-400"
              href={`/document/${visa}/${id}/${language}/${d.dId}`}
              text={`${ d.documentCategory.split("記")[1] } ${ d.documentName }`}
            />
          ) : (
            <DownloadButton
              className="transition duration-100 ease-in p-1 hover:text-blue-400"
              href={d.url}
              text={`${ d.documentCategory.split("式")[1] } ${ d.documentName }`}
            />
          )}
        </li>
      )))
    )
  }

  // *************** JSX (全体) *************** //
  return (
    <Layouts>
      <main className="documentCatalog">
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <div className="documentsMenu">
            <div className="">
              <h2 className="text-3xl font-bold">
                申請書一覧
              </h2>
            </div>
            {/* 申請書一覧 */}
            { types.steps() }
          </div>
        )}
        <div className="flex justify-between fixed right-5 bottom-5 z-10 w-80 h-12">
          <Button
            classProps="cancelButton flex-1 pv1-per whitespace-nowrap"
            text="戻る"
            function={commons.backPage}
          />
          <Button
            classProps="updateButton flex-1 ml-5 pv-1 whitespace-nowrap"
            text="職歴"
            function={commons.openModal}
          />
        </div>
        { isModal && (
          <CareerModal
            modalData={staffState}
            setModalData={setStaffState}
            mutation={mutation}
            careerList={careerList}
            setCareerList={setCareerList}
          />
        )}
      </main>
    </Layouts>
  )
}

export default PersonalDocuments