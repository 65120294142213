// Modules
import {
  useEffect,
  useState
} from "react"
import {
  useMutation,
  useQuery,
  useQueryClient
} from "react-query"
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
// API
import { getAPI, postAPI } from "../../functions/Api"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import { Error } from "../../components/Molecules/Error/Error"
import { Button } from "../../components/Atoms/Button"
import { StatusTop } from "../../components/Molecules/Status/StatusTop"
import { MemoModal } from "../../components/Molecules/Status/MemoModal"
import { StatusTable } from "../../components/Molecules/Status/StatusTable"
import { UserModal } from "../../components/Molecules/Status/UserModal"
// Function
import { CommonFunctions } from "../../functions/CommonFunction"
// Config
import {
  memoModal,
  modalState,
  storageState
} from "../../config/Common/RecoilState"
import { dynamicPostURL } from "../../config/Common/apiURL"
import { Can } from "../../config/Common/Can"
// Stylesheets
import '../../stylesheets/pages/Status/Status.scss'

// *************** Type *************** //
type Props = {
  can?: any
  userUrl: string
  getUrl: string
  queryKey: string
  postUrl: string
  title: string
  theadFixed: Array<any>
  thead: Array<any>
  tbody: Array<any>
  buttonText: string
  buttonValue: any
  linkPath: string
  linkText: string
  helpContent: any
  columns?: any
}

export const StatusCatalog: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const [staffState, setStaffState] = useState<Array<any>>([{}])
  const [statusState, setStatusState] = useState<Array<any>>([])
  const isModal = useRecoilValue(memoModal)
  const isDetail = useRecoilValue(modalState)
  const [modalData, setModalData] = useState<any>({})
  const { cId, userId } = useParams<Record<any, any>>()
  const { admin, userType, uId } = useRecoilValue(storageState)
  const [modalPosY, setModalPosY] = useState<Number>(0)
  const { commons } = CommonFunctions()

  // *************** React Query *************** //
  const promises = async () => {
    const staffData = await getAPI(
      admin ? props.userUrl
      : userId ? `${props.userUrl}/${userId}/${userType}/==/${uId}`
      : `${props.userUrl}/${cId}`
    )
    const statusData = await getAPI(
      admin ? props.getUrl
      : userId ? `${props.getUrl}/${userId}/${userType}/==/${uId}`
      : `${props.getUrl}/${cId}`
    )
    return {
      staffData,
      statusData
    }
  }

  const {
    isFetching,
    isLoading,
    isError,
    data: getData,
  } = useQuery(
    props.queryKey,
    () => promises()
  )

  // useMutation（更新＆削除）
  const queryClient = useQueryClient()
  const mutation = useMutation(data => postAPI(
    props.postUrl,
    data
  ), {
    // エラー発生時の処理
    onError: async previousValue => {
      // キャッシュに元のデータをセットする
      queryClient.setQueryData(props.queryKey, previousValue)
      console.log('更新に失敗しました。')
    },
    // 成功時の処理
    onSuccess: async () => {
      // キャッシュデータを再度取得
      const query: any = await queryClient.fetchQuery(props.queryKey)
      console.log(query.statusData)

      // stateに格納
      setStaffState(query.staffData)
      setStatusState(query.statusData)
    }
  })

  // *************** useEffect *************** //
  useEffect(() => {
    if (isLoading) return
    if (getData) {
      setStaffState(getData.staffData)
      setStatusState(getData.statusData)
    }
  }, [isFetching])

  // *************** JSX *************** //
  return (
    <Layouts>
      <main>
        <StatusTop
          title={props.title}
          link={
            admin ? props.linkPath
            : userId ? `${props.linkPath}/${userId}`
            : `${props.linkPath}/${cId}`
          }
          linkText={props.linkText}
          helpContent={props.helpContent}
        />
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <Can
            I="read"
            a={props.can}
          >
            <StatusTable
              staffData={staffState}
              statusData={statusState}
              theadFixed={props.theadFixed}
              thead={props.thead}
              tbody={props.tbody}
              setStaffState={setStaffState}
              setStatusState={setStatusState}
              setModalData={setModalData}
              buttonText={props.buttonText}
              buttonValue={props.buttonValue}
              setModalPosY={setModalPosY}
            />
          </Can>
        )}
        <Button
          classProps={`updateButton fixed bottom-5 right-5 py-3 px-12
            ${mutation.isLoading && 'disable'}
            ${mutation.isSuccess && 'success'}
            ${mutation.isError && 'error'}
          `}
          text={ mutation.isLoading
            ? '更新中・・・' : mutation.isSuccess
            ? '更新しました' : mutation.isError
            ? '送信エラー' : '更新'
          }
          disabled={ (mutation.isLoading || mutation.isError) && true }
          function={() => commons.postArrayMutation(
            mutation,
            statusState,
            staffState
          )}
        />
        {/* モーダル */}
        { isModal &&
          <MemoModal
            staffData={staffState}
            statusData={statusState}
            setStaffState={setStaffState}
            setStatusState={setStatusState}
            modalData={modalData}
          />
        }
        {/* 詳細モーダル */}
        { isDetail && (
          <UserModal
            text={props.buttonText}
            value={props.buttonValue}
            modalData={modalData}
            staffState={staffState}
            setStaffState={setStaffState}
            modalPosY={modalPosY}
          />
        )}
      </main>
    </Layouts>
  )
}