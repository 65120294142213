// Modules
import React, {
  ReactNode
} from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  href: string | undefined
  children?: ReactNode
  text?: string
  target?: boolean
  rel?: boolean
}

export const DownloadButton: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <a
    className={props.className}
    target={props.target ? '_blank' : ''}
    rel={props.rel ? 'noreferrer' : ''}
    href={props.href}
    download
  >
    { props.children ? props.children : props.text}
  </a>
)