import React from 'react'
import StaticHeader from "../../Layouts/StaticHeader";
import "../../stylesheets/Layouts/StaticContent.scss"

const Rule = () => {
  return (
    <div>
      <StaticHeader />
      <div className="pageContents">
        <h3>利用規約</h3>
        <div className="container">
          <h4>免責事項</h4>
          <p>当社ホームページに記載されている、株式会社シオンの計画、見通し、戦略などのうち、歴史的事実でないものは、将来の業績に関する見通しであり、現在入手可能な情報から得られた当社経営者の判断に基づいております。実際の業績は、さまざまな重要な要素によりこれらの業績見通しとは異なる結果となりうる事をご承知おき下さい。
            <br /><br />
            このホームページは、投資家判断の参考となる情報提供のみを目的として作成しております。当ホームページおよびそこに記載されるいかなる情報も、投資勧誘を目的としたものではございません。
            <br /><br />
            当社はこのホームページに掲載した情報に誤りがあった場合や、第三者によるデータの改竄等があった場合、データ伝送等によって障害が生じた場合に関し、一切責任を負うものではありません。
          </p>

          <h4>著作権について</h4>
          <p>
            当ホームページに掲載されている文書・写真・イラスト・画像・動画等の著作物に関する著作権は、株式会社サイバーエージェントおよび第三者が有しております。著作権法に定められた私的使用の範囲内において使用する場合を除き、事前に株式会社サイバーエージェントに許諾を得ることなく、無断で転載、複製、改変、公衆送信、翻訳、販売等をすることはできません。当社はこのホームページに掲載した情報に誤りがあった場合や、第三者によるデータの改竄等があった場合、データ伝送等によって障害が生じた場合に関し、一切責任を負うものではありません。
          </p>

          <h4>推奨環境について</h4>
        </div>
      </div>
    </div>
  )
}

export default Rule