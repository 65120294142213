// Modules
import React from 'react'
import { useParams } from "react-router-dom"
import { useRecoilValue } from "recoil"
// Components
import { Breadcrumbs } from "../components/Molecules/Layouts/Breadcrumbs"
import { LoginInfo } from "../components/Molecules/Layouts/LoginInfo"
import { TabMenu } from '../components/Molecules/Layouts/TabMenu'
// Config
import { Can } from "../config/Common/Can"
import { storageState } from "../config/Common/RecoilState"
import { AdminBreadcrumbs } from "../config/Layouts/AdminBreadcrumbs"
// Stylesheets
import '../stylesheets/Layouts/Header.scss'

// *************** Type *************** //
type Props = {
  tab?: any
  abilityKey?: any
  selectUser?: string | undefined
}

export const Header: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { cId, userId } = useParams<Record<any, any>>()
  const { admin, userType, uId } = useRecoilValue(storageState)

  // *************** JSX *************** //
  return (
    <header className="header">
      <div className="flex justify-between items-center p-2">
        {/* パンくずリスト */}
        { admin ? <AdminBreadcrumbs /> : <Breadcrumbs /> }
        {/* ログイン情報 */}
        <LoginInfo />
      </div>

      { props.tab && props.abilityKey && (
        <Can
          I="read"
          a={props.abilityKey}
        >
          <TabMenu
            tab={props.tab}
            selectUser={props.selectUser}
            cId={cId}
            uId={uId}
            userType={userType}
            userId={userId}
            admin={admin}
          />
        </Can>
      )}
    </header>
  )
}