// Modules
import React from 'react'
import { Link } from "react-router-dom"
import ja from 'date-fns/locale/ja'
import moment from "moment"
// Component
import { Trow } from "../Common/Trow"
import { Tcell } from "../Common/Tcell"
import { Select } from "../../Atoms/Select"
import { Button } from "../../Atoms/Button"
import { DateInput } from "../../Atoms/DateInput"
// Functions
import { StatusFunctions } from "../../../functions/Status/StatusFunctions"
import { CommonFunctions } from "../../../functions/CommonFunction"

export const StatusTable = (
  props
) => {
  // *************** Functions *************** //
  const { functions } = StatusFunctions()
  const { commons } = CommonFunctions()
  
  // *************** Tag *************** //
  const tags = {
    /**
     * pタグ
     * @param label
     * @returns {JSX.Element}
     */
    text: (label) => ( <p>{label}</p> ),
    /**
     * aタグ
     * @param label
     * @param href
     * @returns {JSX.Element}
     */
    link: (
      label,
      href
    ) => ( <Link to={href}>{label}</Link> ),
    /**
     * selectタグ
     * @param name inputのvalue
     * @param options selectタグの選択欄
     * @param label ステータスのタイトル名
     * @param value 入力内容
     * @param title ステータスのタイトル（英語）
     * @param index テーブルの行
     * @param sIndex ステータスの列
     * @returns {JSX.Element}
     */
    select: (
      name,
      options,
      optionKey,
      optionValue,
      label,
      value,
      title,
      index,
      sIndex
    ) => (
      <Select
        name={name}
        options={options}
        optionKey={optionKey}
        optionValue={optionValue}
        label={label}
        value={value}
        function={(e) => functions.handleSelectForState(
          title,
          e,
          index,
          sIndex,
          props.staffData,
          props.statusData,
          props.setStaffState,
          props.setStatusState
        )}
      />
    ),
    date: (
      name,
      value,
      title,
      index,
      sIndex
    ) => (
      <DateInput
        className="datepicker w-full text-center"
        dataFormat="yyyy-MM-dd"
        name={name}
        localeName="ja"
        localeData={ja}
        value={value ? new Date(value) : ""}
        function={(date) => {
          const eData = {
            target: {
              name: name,
              value: moment(date).format("YYYY-MM-DD")
            }
          }
          functions.handleSelectForState(
            title,
            eData,
            index,
            sIndex,
            props.staffData,
            props.statusData,
            props.setStaffState,
            props.setStatusState
          )
        }}
      />
    )
  }
  
  // *************** JSX *************** //
  return (
    <div className="tableWrap flex">
      <div className="tableFixed pos-r">
        {/* 固定テーブル */}
        <table>
          {/* テーブルヘッダー */}
          <thead>
            <Trow>
              <Tcell
                tag="th"
                cellClass={`w8-rem font-bold text-sm`}
              >
                就労後へ
              </Tcell>
              { props.theadFixed.map((h, i) => (
                <Tcell
                  tag="th"
                  index={i}
                  cellClass={`w8-rem font-bold fs14-px`}
                >
                  { h.title }
                </Tcell>
              ))}
            </Trow>
          </thead>
          {/* テーブルボディ */}
          <tbody>
            { props.staffData.map((staff, j) => (
              <Trow index={j}>
                <Tcell
                  tag="td"
                  cellClass={`fs12-px w8-rem`}
                >
                  <Button
                    classProps="updateButton py-1 px-2"
                    name="immigration"
                    value={props.buttonValue}
                    text="詳細"
                    function={() => {
                      commons.openModal()
                      props.setModalPosY && props.setModalPosY(64 * (j + 1) + 32)
                      props.setModalData({
                        index: j,
                      })
                    }}
                  />
                </Tcell>
                {/* 固定セル */}
                { props.tbody.map((b, k) => (
                  <Tcell
                    tag="td"
                    index={k}
                    cellClass={`fs12-px w8-rem
                      ${!staff.visa ? 'empty' : ''}
                      client${j}
                      ${b.className}
                      ${k === 0 && staff.overdue ? "overdue" : ""}
                      ${k === 3 && !staff.visa ? "disabled" : ""}
                    `}
                  >
                    { b.tag === 'link' ?
                    tags[b.tag](
                      b.label,
                      '/'
                    ) : b.tag === 'date' ?
                    tags[b.tag](
                      b.label,
                      staff[b.label],
                      '',
                      j,
                      null
                    ) : tags[b.tag](typeof(b.label) === 'object'
                      ? b.label.map((array, i) => (
                        <span key={i}>{staff[array]}<br /></span>
                      ))
                      : staff[b.label]
                    )}
                  </Tcell>
                ))}
              </Trow>
            ))}
          </tbody>
        </table>
      </div>
      <div className="tableFlow">
        <table className="">
          {/* テーブルヘッダー */}
          <thead>
          <Trow>
            { props.thead.map((h, i) => (
              <Tcell
                tag="th"
                index={i}
                cellClass={`w16-rem font-bold fs14-px`}
              >
                { h.title }
              </Tcell>
            ))}
          </Trow>
          </thead>
          {/* テーブルボディ */}
          <tbody>
          { props.staffData.map((staff, j) => (
            <Trow index={j}>
              {/*　各ステータス　*/}
              { props.statusData.sort((a, b) => a.number - b.number)
                .map((status, m) => staff.id === status.id && (
                <>
                  {/* ステータス */}
                  <Tcell
                    tag="td"
                    index={m}
                    cellClass={`fs12-px w16-rem
                      ${!staff.visa || !status.dateValue || status.disabled || (!staff.agencies && status.agencies) ? "disabled" : ""}
                      ${status.warning ? "warning" : ""}
                      ${status.overdue ? "overdue" : ""}
                    `}
                  >
                    { status.tag === 'select' ?
                      tags[status.tag](
                        'tagValue',
                        status.tagOptions,
                        'text',
                        'value',
                        status.label,
                        status.tagValue,
                        status.title,
                        j,
                        m
                      )
                    : tags.date(
                      'tagValue',
                      status.tagValue,
                      status.title,
                      j,
                      m
                    )}
                    {/* メモボタン */}
                    { staff.visa &&
                    <Button
                      classProps="memoButton fs12-px ml10-px ph20-px pv5-px"
                      text="メモ"
                      function={() => functions.launchMemo(
                        j,
                        m,
                        staff,
                        status,
                        props.setModalData
                      )}
                    />
                    }
                  </Tcell>
                  {/* 追加オプション */}
                  { status.subTag && (
                    <Tcell
                      tag="td"
                      key={m}
                      cellClass={`fs12-px w16-rem
                      ${!staff.visa || !status.dateValue || status.disabled || (!staff.agencies && status.agencies) ? "disabled" : ""}
                      ${status.warning ? "warning" : ""}
                      ${status.overdue ? "overdue" : ""}
                    `}
                    >
                      { status.subTag === 'select' ?
                        tags[status.subTag](
                          'subValue',
                          status.subOptions,
                          'text',
                          'value',
                          status.subLabel,
                          status.subValue,
                          status.title,
                          j,
                          m
                        )
                      : tags[status.subTag](
                        'subValue',
                        status.subValue,
                        status.subName,
                        j,
                        m
                      )}
                    </Tcell>
                  )}
                  {/* 日付 */}
                  <Tcell
                    tag="td"
                    index={m}
                    cellClass={`fs12-px w16-rem
                      ${!staff.visa || !status.dateValue || status.disabled || (!staff.agencies && status.agencies) ? "disabled" : ""}
                      ${status.warning ? "warning" : ""}
                      ${status.overdue ? "overdue" : ""}
                    `}
                  >
                    { tags.date(
                      'dateValue',
                      status.dateValue,
                      status.title,
                      j,
                      m
                    )}
                  </Tcell>
                </>
              ))}
            </Trow>
          ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
