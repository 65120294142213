// Modules
import React from 'react'
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { PersonalStatus } from '../../components/Molecules/Status/PersonalStatus'
// Config
import { storageState } from "../../config/Common/RecoilState"
// Stylesheets
import '../../stylesheets/pages/Status/DetailsStatus.scss'

export const StaffStatus: React.VFC = () => {
  // *************** Const *************** //
  const { userName } = useRecoilValue(storageState)

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="detailsStatus">
        <h2 className="fs3-rem fw-b">
          {userName}
        </h2>
        <PersonalStatus />
        <Link
          className="cancelButton block w-full mt-5 mx-auto py-3 px-4 text-center
            lg:w-1/3 lg:mt-20 lg:py-4 lg:px-8
          "
          to="/"
        >
          トップに戻る
        </Link>
      </main>
    </Layouts>
  )
}