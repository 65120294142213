// Modules
import {
  Ability,
  AbilityBuilder,
  AbilityClass
} from "@casl/ability"

// *************** Type *************** //
type Actions =
  | 'manage'
  | 'create'
  | 'read'
  | 'update'
  | 'delete'
  | 'leave'
type Subjects =
  | 'all'
  | 'menu'
  | 'documentMenu'
  | 'masterMenu'
  | 'statusMenu'
  | 'interviewMenu'
  | 'interviewTab'
  | 'postMenu'
  | 'careerMenu'
  | 'header'
  | 'docTemplateButton'
  | 'poloButton'
  | 'kumiaiTab'
  | 'rsosTab'
  | 'agenciesTab'
  | 'companiesTab'
  | 'staffsTab'
  | 'masterTop'
  | 'documentTop'
  | 'statusTop'
  | 'interviewTop'
  | 'postTop'
  | 'careerTop'
  | 'countClient'
  | 'rsosList'
  | 'immigration'
  | 'delete_flg'
  | 'masterButton'
  | 'catalogDelete'
  | 'visaSelect'
  | 'agenciesSelect'
  | 'companyList'
  | 'agencyList'
  | 'companiesCatalog'
  | 'userSelectAgencyArea'
  | 'userSelectRsoArea'
  | 'apply'
  | 'interview'
  | 'recruit'
  | 'hired'
  | 'notHired'
  | 'changeStatusButton'
  | 'specifiedStatus'
  | 'traineeStatus'
  | 'enterStatus'
  | 'enteredStatus'
  | 'allDelete'
  | 'quarterButton'
  | 'deleteCareer'
  | 'addCareer'
  | 'saveCareer'
  | 'connectUser'
  | 'companies'
  | 'rsos'
  | 'agencies'
  | 'kumiai'
export type AppAbility = Ability<[Actions, Subjects]>
// Const
export const appAbility = Ability as AbilityClass<AppAbility>

export const defineRulesFor = (user: string) => {
  // *************** Const *************** //
  const {
    can,
    cannot,
    rules
  } = new AbilityBuilder(appAbility)

  // All
  can('read', 'connectUser')

  // admin
  if (user === 'admin') {
    can('read', 'all')
    can('create', 'all')
    // トップメニュー
    cannot('read', 'postTop')
    // メニュー
    cannot('read', 'postMenu')
    // 人材管理
    // cannot('read', 'recruit')
    // cannot('read', 'hired')
    cannot('read', 'notHired')
  }

  // staffs
  if (user === 'staffs') {
    cannot('read', 'menu')
    cannot('read', 'header')
  } else {
    can('read', 'menu')
    can('read', 'header')
  }

  // companies
  if (user === 'companies') {
    can('read', 'companies')
    // Header Tab
    can('read', 'interviewTab')
    // master tab
    can('read', 'poloButton')
    // menu
    can('read', 'masterMenu')
    can('read', 'statusMenu')
    can('read', 'interviewMenu')
    can('read', 'careerMenu')
    // top menu
    can('read', 'masterTop')
    can('read', 'statusTop')
    can('read', 'interviewTop')
    can('read', 'careerTop')
    can('read', 'companiesCatalog')
    // POLO申請書
    can('read', 'agencyList')
    // 人材管理
    can('read', 'recruit')
    can('read', 'hired')
    can('read', 'notHired')
    // 詳細モーダル
    can('read', 'delete_flg')
    can('read', 'immigration')
    // ステータス
    can('read', 'changeStatusButton')
    can('read', 'specifiedStatus')
    can('read', 'traineeStatus')
    can('read', 'enterStatus')
    can('read', 'enteredStatus')
  }

  // rsos（登録支援機関）
  if (user === 'rsos') {
    can('read', 'rsos')
    can('read', 'poloButton')
    // Header Tab
    can('read', 'interviewTab')
    // 詳細モーダル
    can('read', 'delete_flg')
    can('read', 'immigration')
    // POLO申請書
    can('read', 'companyList')
    can('read', 'agencyList')
    // ステータス
    can('read', 'changeStatusButton')
    can('read', 'specifiedStatus')
    can('read', 'traineeStatus')
    can('read', 'enterStatus')
    can('read', 'enteredStatus')
    // 職歴
    can('read', 'quarterButton')
    can('read', 'allDelete')
    can('read', 'deleteCareer')
    can('read', 'addCareer')
    can('read', 'saveCareer')
    // トップメニュー
    can('read', 'documentTop')
    can('read', 'masterTop')
    can('read', 'statusTop')
    can('read', 'interviewTop')
    can('read', 'careerTop')
    can('read', 'countClient')
    // メニュー項目
    can('read', 'documentMenu')
    can('read', 'masterMenu')
    can('read', 'statusMenu')
    can('read', 'interviewMenu')
    can('read', 'careerMenu')
    //UserSelect
    can('read', 'userSelectAgencyArea')
    // 人材管理
    can('read', 'recruit')
    can('read', 'apply')
    can('read', 'interview')
    can('read', 'hired')
    can('read', 'notHired')
  }

  // agencies
  if (user === 'agencies') {
    // マスタータブ
    can('read', 'agencies')
    // 面接タブ
    can('read', 'interviewTab')
    // メニュー項目
    can('read', 'masterMenu')
    can('read', 'statusMenu')
    can('read', 'interviewMenu')
    can('read', 'postMenu')
    can('read', 'careerMenu')
    // トップメニュー
    can('read', 'masterTop')
    can('read', 'statusTop')
    can('read', 'interviewTop')
    can('read', 'postTop')
    can('read', 'careerTop')
    //UserSelect
    can('read', 'userSelectRsoArea')
    // 人材管理
    can('read', 'apply')
    can('read', 'interview')
    // ステータス
    can('read', 'specifiedStatus')
    can('read', 'traineeStatus')
    can('read', 'enterStatus')
  }

  // kumiai
  if (user === 'kumiai') {
    // 権限あり項目
    can('read', 'kumiaiTab')
    // 権限無し項目
  }
  return rules
}

export const buildAbilityFor = (user: string): AppAbility => {
  return new appAbility(defineRulesFor(user))
}
