// Modules
import React, {
  useState,
  useEffect
} from "react"
import { Link, useParams } from "react-router-dom"
import { useQuery } from "react-query"
import { useRecoilValue } from "recoil"
// Component
import { Layouts } from "../../Layouts/Layouts"
import { Button } from "../../components/Atoms/Button"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import Error from "../../components/Molecules/Error/Error"
import { ChangeForm } from "../../components/Molecules/Master/ChangeForm"
import { MasterFormList } from "../../components/Molecules/Master/MasterFormList"
import { UploadImageList } from "../../components/Molecules/Master/UploadImageList"
import { SelectUser } from "../../components/Molecules/Master/SelectUser"
import { SelectMultiUser } from "../../components/Molecules/Master/SelectMultiUser"
// Functions
import { getAPI } from "../../functions/Api"
import { CommonFunctions } from "../../functions/CommonFunction"
import { SysKeyFunctions } from "../../functions/Master/SysKeyFunctions"
// Config
import {
  companiesURL,
  getRoleURL,
  kumiaiURL,
  postUserURL,
  rsosURL,
  agenciesURL
} from "../../config/Common/apiURL"
import { Can } from "../../config/Common/Can"
import { storageState } from "../../config/Common/RecoilState"
// Stylesheets
import '../../stylesheets/pages/Master/Master.scss'

// *************** Type *************** //
type Props = {
  questionUrl: string
  step: string
  cautionText: string
}

export const ChangeSysKey: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  // Pathに関する定義
  const { userType, id } = useParams<Record<string, string>>()
  // ストレージのデータを取得
  const storage = useRecoilValue(storageState)
  // GET & POST URL
  const getURL = `${getRoleURL}/${userType}/${id}`
  const postURL = `${postUserURL}/${userType}/${id}`
  const detailURL = `/${userType}/detail/${id}`
  // Function
  const { commons } = CommonFunctions()
  const { functions } = SysKeyFunctions()

  // *************** React Query *************** //
  /**
   * GET APIをまとめる
   */
  const promiseQuery = async () => {
    const usersData = await getAPI(getURL)
    // 組合ページの時送り出し機関の
    const companiesData = userType === 'staffs'
      ? await getAPI(companiesURL) : null
    //
    const rsosData = userType === 'staffs'
      ? await getAPI(rsosURL) : null
    //
    const agenciesData = userType === 'staffs' || userType === 'kumiai'
    || userType === 'rsos' && storage.admin
    ? await getAPI(agenciesURL) : null
    // const userQuestions = await getAPI(`${sysKeyGetURL}/${userType}`)
    const userQuestions = await getAPI(`${props.questionUrl}/${userType}`)
    // 外部で使用可能にする
    return {
      usersData,
      companiesData,
      rsosData,
      agenciesData,
      userQuestions
    }
  }

  // 非同期通信を実行する
  const {
    isLoading,
    isError,
    isFetching,
    data
  } = useQuery(
    "promise",
    async () => promiseQuery()
  )

  // *************** State *************** //
  const [questions, setQuestions] = useState<any>([])
  const [form, setForm] = useState<any>()
  const [userImages, setUserImages] = useState<any>([])
  const [errorMessages, setErrorMessages] = useState<any>({})
  const [fileImage, setFileImage] = useState<Array<any>>([])
  const [storageImg, setStorageImg] = useState<Array<any>>([])
  const [agencies, setAgencies] = useState<Array<any>>([])

  // Array
  const usersArray = [
    {
      user: 'companies',
      name: '受け入れ企業',
      options: data?.companiesData,
      optionKey: 'company-name'
    },
    {
      user: 'rsos',
      name: '登録支援機関',
      options: data?.rsosData,
      optionKey: 'rso-name'
    },
    {
      user: 'agencies',
      name: '送り出し機関',
      options: data?.agenciesData,
      optionKey: 'agency-name'
    },
    // { user: 'kumiai',
    //   name: '組合団体',
    //   options: kumiaiSelectData
    // }
  ]

  // ページの出入りで発火する
  useEffect(() => {
    // ロードが完了していなければ処理終了
    if (isLoading) return
    // データがあれば
    if (data) {
      setForm(data.usersData)
      setQuestions(data.userQuestions)
      // 画像のセット
      setUserImages(data.usersData.images)
      // 選択肢の追加
      const newUserArray = Object.assign([], usersArray)
      const newUserArray_companies: any = newUserArray[0]
      const newUserArray_ros: any = newUserArray[1]
      const newUserArray_agencies: any = newUserArray[2]
      newUserArray_companies.options = data.companiesData
      newUserArray_ros.options = data.rsosData
      newUserArray_agencies.options = data.agenciesData

      // validation
      data.userQuestions.forEach((m: any) => {
        if (m.required && !data.usersData[m.name]) {
          setErrorMessages((state: any) => ({
            ...state,
            [m.name]: `※ ${m.label}は必須項目です`
          }))
        }
        if (m.check && m.required && !data.usersData[m.name]) {
          setErrorMessages((state: any) => ({
            ...state,
            [`${m.name}_check`]: `※ ${m.label}（確認用）は必須項目です`
          }))
        }
      })

      if (userType === 'kumiai' || storage.admin) {
        commons.formatSelect(
          data.agenciesData && data.agenciesData,
          'agency-name',
          'id',
          setAgencies,
        )
      }
    }
  }, [isFetching])

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="masterChange">
        <h2 className="mt-8 font-bold fs30-px text-center">
          マスターデータ作成
        </h2>
        {　isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <div
            className="
              masterFlex mx-auto mt-8 p-3 rounded-md
              lg:p-20
            "
          >
            <ChangeForm
              baseUrl={`/${userType}/${id}`}
              userType={userType}
            />
            { errorMessages && (
              <p className="errorMessage mt-4 font-bold text-lg text-center">
                ※ {props.cautionText}
              </p>
            )}
            <ul className="formList flex flex-col">
              {/* Masterデータ入力フォーム */}
              { questions && questions.sort((a: any, b: any) => a.number - b.number)
              .map((m: any, i: number) => (
                <MasterFormList
                  index={i}
                  data={m}
                  form={form}
                  setForm={setForm}
                  errorMessages={errorMessages}
                  setErrorMessages={setErrorMessages}
                />
              ))}
              {/* 画像アップロード */}
              { props.step !== 'basic' && (
                <UploadImageList
                  userImages={userImages}
                  setUserImages={setUserImages}
                  fileImage={fileImage}
                  setFileImage={setFileImage}
                  storageImg={storageImg}
                  setStorageImg={setStorageImg}
                  form={form}
                  setForm={setForm}
                />
              )}
            </ul>
            {/* sysKey & VISA選択フォーム */}
            {userType === 'staffs' && (
              <Can
                I="read"
                a="visaSelect"
              >
                <SelectUser
                  usersArray={usersArray}
                  form={form}
                  setForm={setForm}
                  errorMessage={errorMessages}
                  setErrorMessage={setErrorMessages}
                />
              </Can>
            )}
            {/* 登録支援機関と送り出し機関紐付け */}
            {/*{ userType === 'rsos' && (*/}
            {/*  <Can*/}
            {/*    I="read"*/}
            {/*    a="agenciesSelect"*/}
            {/*  >*/}
            {/*    <SelectMultiUser*/}
            {/*      labelText="送り出し機関"*/}
            {/*      name="agencies"*/}
            {/*      options={agencies}*/}
            {/*      placeholder="送り出し機関を選択..."*/}
            {/*      setState={setForm}*/}
            {/*      // value={ form.agencies ? form.agencies : '' }*/}
            {/*    />*/}
            {/*  </Can>*/}
            {/*)}*/}
            {/* フォーム送信 & 戻るボタン */}
            <div className="buttonWrap flex justify-center">
              <Link
                className="cancelButton py-2 px-4 lg:py-3 lg:px-12"
                to={detailURL}
              >
                詳細ページへ
              </Link>
              <Button
                classProps={`updateButton py-2 px-4 lg:py-3 lg:px-12
                  ${!!Object.values(errorMessages).filter((error: any) => error).length && 'disable'}
                `}
                text="登録"
                function={ async() => {
                  await functions.masterSubmit(
                    data?.usersData.id,
                    form,
                    storageImg,
                    fileImage,
                    postURL,
                    detailURL
                  )
                  storage.uId === id &&
                  await functions.setStorage(
                    storage.uId,
                    storage.userType,
                    storage.admin,
                    storage.userType === 'staffs' && storage.step === 'basic' ? 'jobOffer'
                    : storage.userType !== 'staffs' && storage.step === 'basic' ? 'other'
                    : 'basic',
                    form
                  )
                }}
                disabled={!!Object.values(errorMessages).filter((error: any) => error).length}
              />
            </div>
          </div>
        )}
      </main>
    </Layouts>
  )
}

export default ChangeSysKey