// Modules
import React from 'react'
// Components
import { Label } from "../../Atoms/Label"
import { Button } from "../../Atoms/Button"
import { Input } from "../../Atoms/Input"
import { Image } from "../../Atoms/Image"
import pdfImage from "../../../Images/PDF.png"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"

// *************** Type *************** //
type Props = {
  className?: string
  formFile?: any
  setFormFile: Function
  storageFile?: any
  setStorageFile: Function
}

export const FileInput: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** JSX *************** //
  return (
    <div>
      <Label
        className="pb-1 text-center cursor-default"
        htmlFor="masterForm-image"
        text="画像アップロード"
      />
      <div className="formSet p-4 border-2 border-solid border-red-500">
        <Input
          className="block w-full cursor-pointer"
          name="image"
          type="file"
          multiple={true}
          accept="image/*, .pdf"
          function={(e: any) => commons.previewImages(
            e.target.files,
            props.formFile,
            props.setFormFile,
            props.storageFile,
            props.setStorageFile
          )}
        />

        {/* アップロード済み画像の表示 */}
        <ul className="UploadImages flex justify-center w-90 mx-auto">
          { props.formFile && props.formFile.map((image: any, i: number) => (
            <li className="UploadImages-item mt-3">
              <Button
                classProps="cancelButton block ml-auto py-1 px-2"
                text="削除"
                function={() => {
                  commons.removeArray(
                    i,
                    props.formFile,
                    props.setFormFile
                  )
                  commons.removeArray(
                    i,
                    props.storageFile,
                    props.setStorageFile
                  )
                }}
              />
              <div
                key={i}
                className={`imageBlock w-1/2 mx-auto`}
              >
                <Image
                  src={ image.name.substr(-3) === 'pdf' ?
                    pdfImage : image.path
                  }
                  alt={image.label}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}