// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  tag: string
  index?: number
  text?: any
  children?: any
  cellClass?: string
}

export const Tcell: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  props.tag === 'th' ? (
    <th
      key={props.index}
      className={props.cellClass}
    >
      { props.children ? props.children : props.text }
    </th>
  ) : (
    <td
      key={props.index}
      className={props.cellClass}
    >
      { props.children ? props.children : props.text }
    </td>
  )
)