// Modules
import React from "react"
import { NavLink } from "react-router-dom"
import withBreadcrumbs from "react-router-breadcrumbs-hoc"
// FontAwesome
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
// Config
import { breadRoutes } from "../../../config/Layouts/breadcrumbsRoute"

export const Breadcrumbs = withBreadcrumbs(
  breadRoutes,
  { disableDefaults: true }
)(({ breadcrumbs }: any) => {
    // *************** JSX *************** //
  return(
    <ul className="breadcrumbs flex items-center justify-between">
      { breadcrumbs && breadcrumbs.map(({ breadcrumb, match }: any) => (
        <li
          className="flex text-white"
          key={match.url}
        >
          { match.url !== '/' && (
            <span className="mx-2 text-white">
            <FontAwesomeIcon icon={faAngleRight} />
          </span>
          )}
          <NavLink
            to={match.url}
          >
            {breadcrumb}
          </NavLink>
        </li>
      ))}
    </ul>
  )
})