// Modules
import { useRecoilState } from "recoil"
import { useHistory } from "react-router-dom"
// Config
import { modalState } from "../config/Common/RecoilState"
import { TabMenuOptions } from "../config/Common/CommonConfig"
import { storage } from "../config/Common/firebaseConfig"

export const CommonFunctions = () => {
  // *************** Const *************** //
  const history = useHistory()
  const [isModal, setIsModal] = useRecoilState(modalState)

  // *************** Functions *************** //
  const commons = {
    /**
     *
     * @param path
     * @param array
     * @param setState
     */
    getStorageFile: async (
      path: string,
      array: any,
      setState: Function
    ) => {
      const fileLinks = []
      for ( const obj of array ) {
        const pathRef = storage.ref()
        const url = await pathRef.child(`${path}/${obj.name}`).getDownloadURL()
        .catch(err => {
          return console.log(err)
        })
        fileLinks.push(url)
      }
      setState(fileLinks)
    },
    /**
     * ローカルストレージにデータを格納する
     * @param uId
     * @param userType
     * @param userName
     * @param admin
     */
    setStorage: async (
      uId: string,
      userType: string,
      userName: string,
      admin: boolean
    ) => {
      // ストラレージに入れるデータ
      const loginData = {
        uId: uId,
        userType: userType,
        userName: userName,
        admin: admin
      }
      // localストレージのuIdとadminを格納
      localStorage.setItem('user', JSON.stringify(loginData))
    },
    /**
     * 指定したPATHへ遷移する
     * @param path 移動先のパス
     */
    movePage: (path: any) => history.push(path),
    /**
     * 前のページへ戻る
     */
    backPage: () => history.goBack(),
    /**
     * 指定した数字分だけ戻る
     * @param number
     */
    dynamicBack: (number: any) => history.go(number),
    /**
     * モーダルを表示する
     */
    openModal: () => setIsModal(true),
    /**
     * モーダルを閉じる
     */
    closeModal: () => setIsModal(false),
    /**
     * inputの値をstateに格納する
     * @param name
     * @param value
     * @param setState stateに格納する
     */
    setValue: (
      name: string,
      value: any,
      setState: any
    ) => {
      setState((state: any) => ({
        ...state,
        [name]: value
      }))
    },
    setValue2: (
      e: any,
      mergeData: any,
      setState: any
    ) => {
      // 新しい連想配列として定義
      let newData = Object.assign({}, mergeData)
      const {
        name,
        value
      } = e.target
      // 各カラム名に入力した値を代入
      newData[name] = value
      // propsのsetStateに格納する
      setState(newData)
    },
    /**
     *
     * @param i
     * @param name
     * @param value
     * @param mergeData
     * @param setState
     */
    setArrayValue: (
      i: number,
      name: string,
      value: any,
      mergeData: any,
      setState: Function
    ) => {
      // 新しい連想配列として定義
      let newData = Object.assign([], mergeData)
      // 各カラム名に入力した値を代入
      newData[i][name] = value
      console.log(newData)
      // propsのsetStateに格納する
      setState(newData)
    },
    setArrayTrue: (
      name: any,
      checked: boolean,
      value: any,
      id: string,
      mergeObj: any,
      state: any,
      setState: Function
    ) => {
      // obj arrayを定義
      const array = Object.assign([], state)
      let newData: any = {}
      // checkboxがtrueならデータを格納
      if (checked) {
        newData = {
          ...mergeObj,
          id:  id,
          [name]: value
        }
        array.push(newData)
      }
      // すでに配列にデータがあるなら
      if (array.length) {
        for (let i = 0; i < array.length; i = (i + 1) | 0) {
          // checkboxがfalseなら配列削除
          if (
            array[i].id === id
            && array[i][name] === value
            && !checked
          ) array.splice (i, 1)
          // 同じ配列にデータを代入
          else if (
            array[i].id === id
            && array[i][name] === value
            && checked
          ) array[i] = newData
        }
      }
      // 各カラム名に入力した値を代入
      setState(array)
    },
    arraySetValue: (
      index: number,
      name: string,
      value: any,
      arrayState: any,
      setArrayState: Function
    ) => {
      // タイトルが存在するなら
      let newArray = Object.assign ([], arrayState)
      // valueの挿入
      if (name === 'delete_flg' && typeof value === "boolean") {
        // 削除の場合
        newArray[index][name] = value
      } else {
        // 編集の場合
        arrayState[index][name] = value
        newArray[index].update_flg = true
      }
      console.log (newArray)
      setArrayState(newArray)
    },
    setFile: (
      selectFile: any,
      state: any,
      setState: any
    ) => {
      console.log (selectFile)
      setState(selectFile)

      for (const file of selectFile) {
        console.log (file.name)
      }
    },
    /**
     * ファイルが被っているか判断
     * @param name
     * @param images
     */
    checkExist: (
      name: string,
      images: Array<any>
    ) => {
      let flg = false
      for (const img of images) {
        if (img.name === name) {
          flg = true
          break
        }
      }
      return flg
    },
    /**
     *
     * @param files
     * @param formFile
     * @param setFormFile
     * @param storageFile
     * @param setStorageFile
     */
    previewImages: async (
      files: Array<any>,
      formFile: any,
      setFormFile: Function,
      storageFile: any,
      setStorageFile: Function
    ) => {
      // ファイルデータ格納用配列
      let imagesList = Object.assign([], formFile)
      let storageImages = Object.assign([], storageFile)
      let isExist
      //
      // 画像数分処理を回す
      for (const file of files) {
        if (!isExist) {
          // Storage用画像一覧
          storageImages.push(file)
          // 画像を読み込む
          await commons.insertImages(
            imagesList,
            file
          )
        }
      }
      setStorageFile(storageImages)
      setFormFile(imagesList)
    },
    /**
     * stateに画像データを格納する
     * @param array 格納用配列
     * @param file 選択した画像
     */
    insertImages: async (
      array: Array<any>,
      file: any
    ): Promise<FileReader> => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        // 読み込みエラー処理
        reader.onerror = () => {
          return console.log('Failed to upload File...')
        }
        // 読み込み成功処理
        reader.onload = () => {
          resolve(reader)
          // 画像データを配列に格納する
          array.push({
            name: file.name,
            path: reader.result
          })
        }
        // ファイル内容を読み込む
        reader.readAsDataURL(file)
      })
    },
    /**
     * GoogleStorageにファイルを保存する
     * @param ref
     * @param storageImg
     * @param images
     * @param array
     */
    saveStorage: async (
      ref: string,
      storageImg: any,
      // images: Array<any>,
      // array: Array<any>
    ) => {
      const storageRef = storage.ref(ref)
      // 選択した画像の分だけ処理を回す
      for (const img of storageImg) {
        // storageに保存する
        await storageRef.child(img.name).put(img)
        // const uploadTask = await storageRef.child(img.name).put(img)

        // 選択した画像分だけ処理を回す
        // for (const image of images) {
        //   // ファイル名がマッチしなければスキップ
        //   if (img.name !== image.name) continue
        //   // ファイルのURLを取得する
        //   image.url = await uploadTask.ref.getDownloadURL()
        //   array.push(image)
        // }
      }
    },
    /**
     * multiSelect出来るようにlabel, valueに変更
     * @param data
     * @param labelName
     * @param valueName
     * @param setstate
     */
    formatSelect: (
      data: Array<any>,
      labelName: string,
      valueName: string,
      setstate: Function
    ) => {
      const array = []
      // format用オブジェクト
      let obj = {}
      //
      if (data) {
        for (const d of data) {
          obj = {
            label: d[labelName] ? d[labelName] : 'データがありません',
            value: d[valueName] ? d[valueName] : 'データがありません'
          }
          array.push(obj)
        }
        setstate(array)
      }
    },
    /**
     * 入力内容にバリデーションをかける
     * @param required
     * @param name
     * @param label
     * @param regex
     * @param value
     * @param errorText
     * @param setErrorMessage
     * @param check
     */
    checkValidation: (
      required: boolean,
      name: string,
      label: string,
      regex: any,
      value: any,
      errorText: string,
      setErrorMessage: Function,
      check: boolean
    ) => {
      if (required && value === '') {
        setErrorMessage((errorMessage: any) => ({
          ...errorMessage,
          [name]: `${label}を入力してください。`
        }))
      } else if (!value.match(regex)) {
        setErrorMessage((errorMessage: any) => ({
          ...errorMessage,
          [name]: errorText
        }))
      } else if (check && value !== regex) {
        setErrorMessage((errorMessage: any) => ({
          ...errorMessage,
          [name]: errorText
        }))
      } else {
        setErrorMessage((errorMessage: any) => ({
          ...errorMessage,
          [name]: ''
        }))
      }
    },
    /**
     * 削除用の配列データを作成
     * @param obj
     * @param state
     * @param setState
     */
    addDeleteFlg: (
      obj: any,
      state: any,
      setState: Function
    ) => {
      // 追加済み削除リストと結合
      let newArray = Object.assign([], state)
      // 選択したリストに削除フラグを追加
      obj.delete_flg = true
      newArray.push(obj)
      setState(newArray)
    },
    /**
     * 全ての配列を削除データとして作成
     * @param state
     * @param setState
     */
    fullDeleteFlg: (
      state: any,
      setState: Function
    ) => {
      let newArray = Object.assign([], state)
      // 全ての配列に削除フラグを追加
      for (const obj of newArray) obj.delete_flg = true
      setState(newArray)
    },
    /**
     * IDが一致した配列を格納する
     */
    filterArray: (
      id: string,
      array: Array<any>,
      setState: Function
    ) => {
      const filterArray: Array<any> = []
      // userIDに一致する配列を格納する
      array.filter((a: any) => {
        a.id === id && filterArray.push(a)
      })
      setState(filterArray)
    },
    /**
     * 配列を追加する処理
     */
    addArray: (
      i: number | undefined,
      id: string,
      obj: any,
      state: any,
      setState: Function
    ) => {
      const array = Object.assign([], state)
      // objectにIDを追加
      obj.id = id
      // 新しい配列を追加
      array.push(obj)
      setState(array)
    },
    /**
     * 指定した配列を削除する
     * @param i
     * @param state
     * @param setState
     */
    removeArray: (
      i: number,
      state: any,
      setState: Function
    ) => {
      // 指定した位置の要素を一つ取り除く
      state.splice(i, 1)
      setState([...state])
    },
    /**
     * データを全削除する（配列を空にする）
     * @param setState
     */
    clearArray: (setState: Function) => setState([]),
    /**
     * useMutationにデータを送る
     * @param mutation
     * @param mergeData
     * @param sendData
     */
    postMutation: (
      mutation: any,
      mergeData: any,
      sendData: any
    ) => {
      // 入力データと別データをまとめる
      const postData = (Object.assign(mergeData, sendData))
      // useMutationにデータを送る
      mutation.mutate(postData)
    },
    /**
     * 配列用POST処理
     * @param mutation
     * @param mergeData
     * @param arrayState
     */
    postArrayMutation: (
      mutation: any,
      mergeData: any,
      arrayState: any
    ) => {
      // POST用配列データ
      let postArray: Array<any> = []
      let mergedArray = arrayState.concat(mergeData)
      for (const obj of mergedArray) {
        // flgがある配列だけにする
        if (
          obj.insert_flg
          || obj.update_flg
          || obj.delete_flg
        ) {
          console.log (obj)
          postArray.push(obj)
        }
      }
      mutation.mutate(postArray)
    },
  }

  return { commons }
}

// 言語変換関数
export const translateFunc = (
  language: any
): { [key in any]: string } => {
  language = String(language)
  return {
    'TabMenu': TabMenuOptions[`${language}`]
  }
}