// *************** Const *************** //
// 一つ目の『_』を基準にsplitする
export const splitRegex = /(?<=^[^_]+?)_/

// 言語一覧
export const languageList = [
  { type: "", text: "標準" },
  { type: "en", text: "英語" },
  { type: "ph", text: "フィリピン" },
  { type: "vn", text: "ベトナム" },
  { type: "mm", text: "ミャンマー" }
]

// 書類作成段階
export const specificStep = [
  { label: '入国管理', category: 'immigration' }
]

export const skillTrainingStep = [
  { label: '機構', category: 'mechanism' },
  { label: '入国管理', category: 'immigration' }
]

// 特定技能申請書カテゴリー
export const specificCategory = [
  {
    step: 'immigration',
    label: '省令様式',
    group: 'ministerial'
  },
  {
    step: 'immigration',
    label: '参考様式',
    group: 'reference'
  },
  {
    step: 'immigration',
    label: '分野参考様式',
    group: 'specific'
  }
]

// 技能実習申請書カテゴリー
export const skillCategory = [
  {
    step: 'mechanism',
    label: '省令様式',
    title: 'ministerial'
  },
  {
    step: 'mechanism',
    label: '参考様式',
    title: '参考様式',
    name: 'reference'
  },
  {
    step: 'immigration',
    label: '省令様式',
    title: 'ministerial'
  }
]

// 特定技能申請書グループ
export const specifiedGroup = [
  {
    label: '特定技能外国人の在留諸申請に関するもの',
    category: 'reference',
    field: 'specifiedResidence'
  },
  {
    label: '定期又は随時届出に関するもの',
    category: 'reference',
    field: 'regular'
  },
  {
    label: '介護分野',
    category: 'specific',
    field: 'nursing'
  },
  {
    label: 'ビルクリーニング分野',
    category: 'specific',
    field: 'cleaning'
  },
  {
    label: '素形材産業分野',
    category: 'specific',
    field: 'material'
  },
  {
    label: '産業機械製造業分野',
    category: 'specific',
    field: 'mechanic'
  },
  {
    label: '電気・電子情報関連産業分野',
    category: 'specific',
    field: 'electronic'
  },{
    label: '建設分野',
    category: 'specific',
    field: 'construction'
  },{
    label: '造船・舶用用工業分野',
    category: 'specific',
    field: 'shipping'
  },{
    label: '自動車整備分野',
    category: 'specific',
    field: 'car'
  },{
    label: '航空分野',
    category: 'specific',
    field: 'aviation'
  },{
    label: '宿泊分野',
    category: 'specific',
    field: 'lodging'
  },
  {
    label: '農業分野',
    category: 'specific',
    field: 'agriculture'
  },
  {
    label: '漁業分野',
    category: 'specific',
    field: 'fishery'
  },
  {
    label: '飲食料品製造業分野',
    category: 'specific',
    field: 'grocery'
  },
  {
    label: '外食業分野',
    category: 'specific',
    field: 'restaurant'
  },{
    label: '別記',
    category: 'ministerial',
    field: 'separate'
  }
]

// 特定技能分野リスト
export const specifiedFields = [
  { label: "参考様式第１", group: 'specifiedResidence', document: 1 },
  { label: "参考様式第３", group: 'regular', document: 3 },
  { label: "参考様式第５", group: 'regular', document: 5 },
  { label: "分野参考様式第１", group: 'nursing', document: 1 },
  { label: "分野参考様式第２", group: 'cleaning', document: 2 },
  { label: "分野参考様式第３", group: 'material', document: 3 },
  { label: "分野参考様式第４", group: 'mechanic', document: 4 },
  { label: "分野参考様式第５", group: 'electronic', document: 5 },
  { label: "分野参考様式第６", group: 'construction', document: 6 },
  { label: "分野参考様式第７", group: 'shipping', document: 7 },
  { label: "分野参考様式第８", group: 'car', document: 8 },
  { label: "分野参考様式第９", group: 'aviation', document: 9 },
  { label: "分野参考様式第１０", group: 'lodging', document: 10 },
  { label: "分野参考様式第１１", group: 'agriculture', document: 11},
  { label: "分野参考様式第１２", group: 'fishery', document: 12 },
  { label: "分野参考様式第１３", group: 'grocery', document: 13 },
  { label: "分野参考様式第１４", group: 'restaurant', document: 14 },
  { label: "別記", group: 'separate', document: 1 }
]

// 技能実習分野リスト
export const skillFields = [
  { label: "省令様式第１", title: '実習計画認定申請書' },
  { label: "参考様式第１", title: '介護分野'},
  { label: "参考様式第２", title: 'ビルクリーニング分野' },
  { label: "参考様式第３", title: '素形材産業分野' },
  { label: "参考様式第４", title: '産業機械製造業分野' },
  { label: "参考様式第５", title: '電気・電子情報関連産業分野' },
  { label: "参考様式第６", title: '建設分野' },
  { label: "参考様式第７", title: '造船・舶用用工業分野' },
  { label: "参考様式第８", title: '自動車整備分野' },
  { label: "参考様式第９", title: '航空分野' },
  { label: "参考様式第１０", title: '宿泊分野' },
  { label: "参考様式第１１", title: '農業分野' },
  { label: "参考様式第１２", title: '漁業分野' },
  { label: "参考様式第１３", title: '飲食料品製造業分野' },
  { label: "参考様式第１４", title: '外食業分野' },
  { label: "別記", group: 'ministerial', document: 1 }
]

//
export const visaList = [
  { label: 'specificSkill', title: '特定技能' },
  { label: 'skillTraining', title: '技能実習' }
]


// *************** DocTemplate *************** //
export const docFormList = [
  {
    name: "mimeType",
    label: "Word or Excel",
    placeholder: "ファイルのタイプ",
    tag: "select",
    options: [
      { text: "word", value: "convertTo" },
      { text: "excel", value: "spreadsheets" }
    ],
    validation: ""
  },
  {
    name: "visaType",
    label: "VISAの種類",
    placeholder: "ファイルがどの就労VISA用か",
    tag: "select",
    options: [
      { text: "特定技能", value: "specificSkill" },
      { text: "技能実習", value: "skillTraining" }
    ],
    validation: ""
  },
  {
    name: "documentId",
    label: "申請書ID（申請書名　英語）",
    placeholder: "DB上の申請書名（英語）",
    tag: "input",
    type: "text",
    validation: ""
  },
  {
    name: "sheetId",
    label: "ファイルID",
    placeholder: "URLに記述されているファイルID",
    tag: "input",
    type: "text",
    validation: ""
  },
  {
    name: "polo",
    label: "POLO or Not POLO",
    placeholder: "POLO申請書かどうか",
    tag: "select",
    options: [
      { text: "POLO", value: "polo" },
      { text: "Not POLO", value: "sss" }
    ],
    validation: ""
  },
  {
    name: "language",
    label: "申請書言語",
    placeholder: "申請書テキストの言語",
    tag: "select",
    options: [
      { text: "標準語", value: "default" },
      { text: "フィリピン（英語）", value: "ph" },
      { text: "ベトナム", value: "vn" },
      { text: "ミャンマー", value: "mm" }
    ],
  },
  {
    name: "number",
    label: "申請書順番",
    placeholder: "号数",
    tag: "input",
    type: "number",
    validation: ""
  }
]