// Modules
import React from "react"
// Styles
import '../../stylesheets/components/Atoms/Logo.scss'

export const Title = (
  props
) => (
  // *************** JSX *************** //
  <svg
    className={`title ${props.className}`}
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 578.6 98.7"
    xmlSpace="preserve"
    style={{ enableBackground: "new 0 0 578.6 98.7" }}
  >
    <g id="テキスト">
      <circle
        className="st0"
        cx="46.8"
        cy="49.4"
        r="45.9"
      />
      <path
        className="st1"
        d="M94.4,49.4c-2.6-0.5-5,1.4-5.3,4C88.2,63,84,71.6,77.7,78.1C70.2,85.6,60,89.8,49.3,89.8
        c-22.2,0-40.4-18.2-40.4-40.4c0-22.3,18.2-40.5,40.4-40.5c11.7,0,22.8,5,30.4,13.8c1.7,2,4.5,2.1,6.3,0.4c1.8-1.5,2.1-4.3,0.4-6.3
        C77.1,6.3,63.5,0.1,49.3,0.1C22.2,0.1,0,22.2,0,49.4s22.2,49.3,49.3,49.3c14.2,0,27.8-6.1,37.2-16.9c0,0,0.1-0.1,0.1-0.1
        c0,0,0,0,0,0c6.3-7.5,10.5-16.9,11.5-27.2C98.3,52.1,96.8,49.8,94.4,49.4z"
      />
      <circle className="st2" cx="138.4" cy="49.4" r="49.4"/>
      <path className="st1" d="M270.5,98.7c-1.3,0-2.7-0.6-3.5-1.7l-62.7-79.6v76.8c0,2.5-2,4.5-4.5,4.5s-4.5-2-4.5-4.5V4.6
        c0-2,1.3-3.6,3.1-4.3c1.7-0.6,3.8,0,4.9,1.5l62.7,79.6V4.6c0-2.5,2.1-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5v89.7c0,2-1.1,3.6-2.9,4.2
        C271.5,98.6,271.1,98.7,270.5,98.7z"
      />
      <path className="st1" d="M358.5,98.7c-1.3,0-2.7-0.6-3.5-1.7l-62.7-79.6v76.8c0,2.5-2,4.5-4.5,4.5c-2.5,0-4.5-2-4.5-4.5V4.6
        c0-2,1.3-3.6,3.1-4.3c1.7-0.6,3.8,0,4.9,1.5l62.7,79.6V4.6c0-2.5,2.1-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5v89.7c0,2-1.1,3.6-2.9,4.2
        C359.5,98.6,359.1,98.7,358.5,98.7z"
      />
      <path className="st1" d="M421.5,94.2c0,2.5-2.1,4.5-4.5,4.5h-58.5c-2.5,0-4.5-2-4.5-4.5V4.6c0-2.5,2-4.5,4.5-4.5H417
        c2.4,0,4.5,2,4.5,4.5c0,2.4-2.1,4.3-4.5,4.3h-54v33.9H414c2.4,0,4.5,2,4.5,4.3c0,2.5-2.1,4.5-4.5,4.5H363v38.1h54
        C419.4,89.8,421.5,91.9,421.5,94.2z"
      />
      <path className="st1" d="M460.3,98.7c-27.1,0-49.3-22.1-49.3-49.3s22.2-49.3,49.3-49.3c14.2,0,27.8,6.1,37.2,16.8
        c1.7,2,1.4,4.7-0.4,6.3c-1.8,1.7-4.6,1.5-6.3-0.4c-7.7-8.8-18.7-13.8-30.4-13.8c-22.2,0-40.4,18.2-40.4,40.5
        c0,22.2,18.2,40.4,40.4,40.4c11.7,0,22.8-5,30.4-13.8c1.7-1.8,4.5-2,6.3-0.4c1.8,1.7,2.1,4.5,0.4,6.3
        C488.1,92.6,474.5,98.7,460.3,98.7z"
      />
      <path className="st1" d="M578.6,4.6c0,2.4-2,4.3-4.5,4.3h-28.9v85.3c0,2.5-2,4.5-4.3,4.5c-2.5,0-4.5-2-4.5-4.5V8.9h-28.9
        c-2.5,0-4.5-2-4.5-4.3c0-2.5,2-4.5,4.5-4.5h66.6C576.6,0.1,578.6,2.1,578.6,4.6z"
      />
    </g>
  </svg>
)