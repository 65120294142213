// Modules
import React, { ReactNode } from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  href: string
  children?: ReactNode
  text?: string
}

export const OpenButton: React.VFC<Props> = (
 props: Props
) => (
  // *************** JSX *************** //
  <a
    className={props.className}
    target='_blank'
    rel="noreferrer"
    href={props.href}
  >
    { props.children ? props.children : props.text}
  </a>
)