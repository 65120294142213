// Modules
import React, {
  useState
} from 'react'
// Components
import { Label } from '../../Atoms/Label'
import { Input } from "../../Atoms/Input"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"

// *************** Type *************** //
type Props = {
  index: number
  data?: any
  form?: any
  setForm: Function
  errorMessages?: any
  setErrorMessages: any
}

export const MasterFormList: React.VFC<Props> = (
  props: Props
) => {
  // *************** Functions *************** //
  const { commons } = CommonFunctions()
  const [check, setCheck] = useState<any>({})

  // *************** JSX *************** //
  return (
    <li
      className="masterForm"
      key={props.index}
    >
      <div className="formSet">
        <Label
          className="pb-1"
          htmlFor={props.data.name}
          text={props.data.label}
        />
        { props.data.tag === 'select' ? (
          <select
            className="mt-2"
            id={props.data.name}
            name={props.data.name}
            value={props.form[props.data.name] ? props.form[props.data.name] : ''}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              commons.checkValidation(
                props.data.required,
                e.target.name,
                props.data.label,
                props.data.validation.regex,
                e.target.value,
                props.data.validation.errorMessage,
                props.setErrorMessages,
                false
              )
              commons.setValue(
                e.target.name,
                e.target.value,
                props.setForm
              )
            }}
          >
            <optgroup label={props.data.label}>
              <option
                hidden
                value=""
              >
                選択してください
              </option>
              { props.data.options.map((option: any, o: number) => (
                <option
                  key={o}
                  value={option.value}
                >
                  {option.name}
                </option>
              ))}
            </optgroup>
          </select>
        ) : (
          <Input
            className="masterInput mt2-per"
            name={props.data.name}
            type={props.data.type}
            value={props.form[props.data.name] ? props.form[props.data.name] : ''}
            function={(e: React.ChangeEvent<HTMLInputElement>) => {
              commons.checkValidation(
                props.data.required,
                e.target.name,
                props.data.label,
                /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                e.target.value,
                props.data.validation.errorMessage,
                props.setErrorMessages,
                false
              )
              commons.setValue(
                e.target.name,
                e.target.value,
                props.setForm
              )
            }}
          />
        )}
      </div>
      <p>例）{props.data.placeholder}</p>
      { props.errorMessages && (
        <p className="errorMessage font-bold fs14-px">
          { props.errorMessages[props.data.name] }
        </p>
      )}
      {/* 確認用入力が必要な場合 */}
      { props.data.check && (
        <div className="formSet">
          <Label
            className="pb-1"
            htmlFor={`${props.data.name}_check`}
            text={`${props.data.label}（確認用）`}
          />
          <Input
            className="masterInput mt-2"
            name={`${props.data.name}_check`}
            type={props.data.type}
            value={check[`${props.data.name}_check`] ? check[`${props.data.name}_check`] : ''}
            function={(e: React.ChangeEvent<HTMLInputElement>) => {
              commons.checkValidation(
                true,
                e.target.name,
                `${props.data.label}（確認用）`,
                props.form[props.data.name],
                e.target.value,
                `${props.data.label}と一致しません`,
                props.setErrorMessages,
                true
              )
              commons.setValue(
                e.target.name,
                e.target.value,
                setCheck
              )
            }}
          />
          { props.errorMessages && (
            <p className="errorMessage mt-3 font-bold fs14-px">
              { props.errorMessages[`${props.data.name}_check`] }
            </p>
          )}
        </div>
      )}
    </li>
  )
}