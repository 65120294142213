// Modules
import axios from "axios"
// Recoil
import { useRecoilState } from 'recoil'
import { modalState } from '../../config/Common/RecoilState'
import { splitRegex } from "../../config/Document/DocumentConfig"
// Functions
import { CommonFunctions } from "../CommonFunction"

export const DocumentFunctions = () => {
  // *************** RecoilState *************** //
  const [modal, setModal] = useRecoilState(modalState)

  // *************** Const *************** //
  const { commons } = CommonFunctions()

  const functions = {
    /**
     *
     */
    openModal: () => setModal(true),
    /**
     * 申請書カテゴリーを開く
     */
    openCategory: (
      i: number,
      isCategory: number | undefined,
      setIsGroup: Function,
      setIsCategory: Function
    ) => {
      isCategory !== i && setIsGroup(99)
      setIsCategory(i)
    },
    /**
     * 申請書グループを開く
     */
    openGroup: (
      i: number,
      isGroup: number | undefined,
      setIsField: Function,
      setIsGroup: Function
    ) => {
      isGroup !== i && setIsField(99)
      setIsGroup(i)
    },
    openField: (
      i: number,
      isField: number | undefined,
      setIsDocument: Function,
      setIsField: Function
    ) => {
      isField !== i && setIsDocument(99)
      setIsField(i)
    },
    /**
     * 申請書分野を開く
     */
    openDocument: (
      j: number,
      setIsDocument: Function
    ) => setIsDocument(j),
    /**
     *
     * @param index
     * @param setState
     */
    openFirst: (
      index: number,
      setState: Function
    ) => {
      setState(index)
    },
    /**
     * ウィンドウを閉じる
     */
    closeTab: () => {
      window.open("about:blank", "_self")
      window.close()
    },
    /**
     * HTMLを表示する
     * @param data
     * @param setState
     * @returns {Promise<void>}
     */
    loadHtml: async (
      data: any,
      setState: Function
    ) => {
      // fireStoreにあるhtmlを定義
      const docHtml = data.html
      // DomツリーをHTMLソースに変換
      const parser = new DOMParser()
      // htmlにparseする
      const newHtml: any = parser.parseFromString(docHtml, "text/html")
      // フォームをHTMLとして一度だけ表示
      if (newHtml.querySelector('body').innerHTML !== "true") {
        setState(newHtml.querySelector('body').innerHTML)
      }
    },
    transFormData: (
      data: any,
      setState: Function
    ) => {
      // フォーム用
      const {
        dId,
        documentName,
        fileId,
        visa,
        mimeType,
        sysKeys,
        pngUrl,
        polo,
        document,
        group,
        documentCategory,
        categoryNumber,
        ...excelFormData
      } = data
      // label, bodyのオブジェクトに変換する
      const newDoc = Object.entries(excelFormData)
      .map(([title, body]) => (
        { title, body }
      ))
      // stateに格納する
      setState(newDoc)
    },
    /**
     * sysKeyを判別し、入力内容としてオブジェクトに入れる
     * @param keyArrays
     * @param sysData
     * @returns {Promise<void>}
     */
    addSysWord: async (
      docData: any,
      sysData: any
    ) => {
      // sysKeyのinputタグだけ取ってくる
      const sysClass = document.getElementsByClassName("readonly")
      // 申請書マスターDBのsysKeyとすり合わせるfieldを取得
      const keyArrays = Object.keys(docData)
      // 配列として取得したdocumentMasterDBのフィールド名を個々で取得
      for (let i = 0; i < sysClass.length; i=(i+1)|0) {
        const sysRegister = sysClass[i].getAttribute("register")
        // documentMasterのフィールド名をまわす
        for (const key of keyArrays) {
          // documentMasterDBにあるデータのフィールド名を『 _ 』以降にする
          const splitKey = key.split(/(?<=^[^_]+?)_/)
          // split後の左辺(それぞれの機関のsysKey名[key(基本情報)])
          const collectionId = sysData[splitKey[0]]
          // split後の右辺
          const docFileId = splitKey[1]
          console.log(docFileId)
          if (docFileId === sysRegister) {
            // sysKeyがなかったら関数の処理を中断する
            if (!collectionId) return
            // keyなり得る値が同じでなければスキップする
            if (!collectionId[sysRegister]) continue
            // sysKeyをセット
            sysClass[i].setAttribute('value',collectionId[sysRegister])
          }
        }
      }
    },
    /**
     *
     * @param docData
     * @param sysData
     * @param setState
     */
    addSysExcel: (
      docData: any,
      sysData: any,
      setState: Function
    ) => {
      const obj: any = {}
      // sysKeyのinputタグ
      const docSysClass: any = document.getElementsByClassName("sysKey")
      // 配列として取得したdocumentMasterDBのフィールド名を個々で取得
      for (let i = 0; i < docSysClass.length; i=(i+1)|0) {
        // inputのname属性
        const inputName = docSysClass[i].name
        const splitKey = inputName.split(splitRegex)
        // ユーザーデータ
        const userData = sysData[splitKey[0]] ? sysData[splitKey[0]] : ''
        // オブジェクトにsysKeyのvalueを格納
        obj[inputName] = userData && userData[splitKey[1]]? userData[splitKey[1]] : ''
      }
      setState(obj)
    },
    /**
     * sysKeyクラスを付与する
     * @param setDocInput
     * @param data
     * @param docInput
     * @returns {Promise<void>}
     */
    inputAttribute: async (
      data: any,
      setDocInput: Function
    ) => {
      const input: any = document.getElementsByClassName('documentInput')
      // documentInputクラスの分だけ処理を回す
      for (let i = 0; i < input.length; i=(i+1)|0) {
        // onChangeを付与
        input[i].onchange = function (e: any) {
          commons.setValue(
            e.target.name,
            e.target.value,
            setDocInput
          )
        }
      }
    },
    // sysKeyのinputにクラスを付与する
    setClass: async () => {
      // inputタグを全て取得
      const inputTag = document.getElementsByTagName("input")
      for (let i = 0; i < inputTag.length; i=(i+1)|0) {
        // // すべてのinputにから文字を付与
        // inputTag[i].setAttribute('value', '')
        // name属性を取得
        const inputName = inputTag[i].name
        // sysKeyか否か判断
        const splitLeft = inputName.split('_')[0]
        if (splitLeft !== 'documents') {
          // それ以外はsysKeyというclassを追加
          inputTag[i].classList.add('sysKey')
        }
      }
    },
    /**
     * 全inputタグのvalueをオブジェクト化
     */
    setValue: async (setState: Function) => {
      const sysNameValues: any = {}
      // inputタグを全て取得
      const inputTag: any = document.getElementsByClassName('documentInput')
      // 個々にのオブジェクトにする
      for (let i = 0; i < inputTag.length; i=(i+1)|0) {
        // name属性を取得
        const inputName = inputTag[i].name
        // 新しいオブジェクトとして格納
        sysNameValues[inputName] = inputTag[i].getAttribute('value') ? inputTag[i].getAttribute('value') : ''
      }
      // POST用のオブジェクトに格納
      setState(sysNameValues)
    },
    /**
     * 入力した値を送信する関数
     * @param mergeData
     * @param inputData
     * @param postURL
     * @param setModalOn
     * @param setFileUrl
     * @param setCanDownload
     * @param setErrorMessage
     * @returns {Promise<boolean>}
     */
    submitData: async (
      mergeData: any,
      inputData: string,
      postURL: string,
      setModalOn: Function,
      setCanDownload: Function,
      setFileUrl: Function,
      setErrorMessage: Function
    ) => {
      // 入力データとsysKeyデータをまとめる
      const postData = (Object.assign(mergeData, inputData))
      // モーダルを表示
      setModalOn(true)
      try {
        console.log (postData)
        // fireStoreに送信
        const res = await axios.post(postURL, postData)
        // 申請書のURLをセットする
        setFileUrl(res.data)
        //ダウンロード可能にする
        setCanDownload(true)
        setModalOn(false)
      } catch (err) {
        console.log(err)
        // エラーメッセージ表示
        setErrorMessage(true)
        //ダウンロード禁止にする
        setCanDownload(false)
        return false
      }
    }
  }

  return { functions }
}