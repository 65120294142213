// 情報登録フォーム
export const formToBStep = [
  {
    title: '基本情報',
    step: 'basic'
  },
  {
    title: '手続き情報',
    step: 'other'
  }
]
export const formToCStep = [
  {
    title: '基本情報',
    step: 'basic'
  },
  {
    title: '履歴書情報',
    step: 'jobOffer'
  },
  {
    title: '手続き情報',
    step: 'other'
  }
]