// Modules
import React from "react"

// *************** Type *************** //
type Props = {
  data? : any
}

export const CountUsers: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const countList = [
    {
      count: props.data ? props.data.staffs : 0,
      name: '現在の候補者数'
    },
    {
      count: props.data ? props.data.companies : 0,
      name: '現在の企業数'
    },
    {
      count: props.data ? props.data.rsos : 0,
      name: '現在の登録支援機関数'
    },
    {
      count: props.data ? props.data.agencies : 0,
      name: '現在の送り出し機関数' },
    // {
    //   count: props.data ? props.data.kumiai : 0,
    //   name: '現在の監理団体数'
    // },
  ]

  // *************** JSX *************** //
  return (
    <section className="countNumberWrap shadow">
      <h2 className="font-bold fs25-px">
        合計数
      </h2>
      <ul className="countNumber flex flex-wrap">
        { countList.map((list: any, i: number) => (
          <li key={i} >
            <div className="flex">
              <h3 className="font-bold fs25-px">
                {list.count ? list.count : "0"}
              </h3>
            </div>
            <p className="font-bold font_12 ">
              {list.name}
            </p>
          </li>
        ))}
      </ul>
    </section>
  )
}