// Modules
import React, {
  useState
} from 'react'
import { useMutation } from "react-query"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { Button } from "../../components/Atoms/Button"
import { TemplateForm } from "../../components/Molecules/Documents/TemplateForm"
import { FileInput } from "../../components/Molecules/Common/FileInput"
import { DocDeleteForm } from "../../components/Molecules/Documents/DocDeleteForm"
// Functions
import { postAPI } from "../../functions/Api"
import { CommonFunctions } from "../../functions/CommonFunction"
// Config
import { commonURL } from "../../config/Common/apiURL"

export const DocTemplateform: React.VFC = () => {
  // *************** State *************** //
  const [formInput, setFormInput] = useState<any>({})
  const [formFile, setFormFile] = useState<any>()
  const [storageFile, setStorageFile] = useState<any>()
  const { commons } = CommonFunctions()

  console.log(formInput)
  console.log(formFile)
  // *************** useQuery *************** //
  const mutation = useMutation (data => postAPI(
      `${commonURL}/${formInput.mimeType}`,
      data
    ),
    {
      onError: async (error) => console.log (error),
      onSuccess: async (
        data,
        variables
      ) => console.log(variables)
    }
  )

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="flex justify-between">
        <div>
          <h2 className="font-bold text-2xl">
            申請書テンプレ作成
          </h2>
          <TemplateForm
            state={formInput}
            setState={setFormInput}
          />
          <FileInput
            formFile={formFile}
            setFormFile={setFormFile}
            storageFile={storageFile}
            setStorageFile={setStorageFile}
          />
          <Button
            classProps={`updateButton mt-3 py-2 px-5
            ${mutation.isLoading && 'disable'}
            ${mutation.isSuccess && 'success'}
            ${mutation.isError && 'error'}
          `}
            text={ mutation.isLoading
              ? '作成中・・・' : mutation.isSuccess
              ? '作成しました' : mutation.isError
              ? '送信エラー' : '作成'
            }
            function={() => {
              commons.postMutation(
                mutation,
                { pngUrl: formFile },
                formInput
              )
              if (!mutation.isError && storageFile) {
                commons.saveStorage(
                  `DocumentsTemplate/${formInput.documentId}`,
                  storageFile
                )
              }
            }}
          />
        </div>
        <DocDeleteForm　/>
      </main>
    </Layouts>
  )
}

export default DocTemplateform