// Modules
import React, {
  useState
} from "react"
// Components
import { Button } from "../../Atoms/Button"
import { DownloadButton } from "../../Atoms/DownloadButton"
// Functions
import { DocumentFunctions } from "../../../functions/Document/DocumentFunctions"
// Config
import { docPostURL } from "../../../config/Common/apiURL"

// *************** Type *************** //
type Props = {
  uId: string
  dId: string
  language: string | undefined
  setModalOn: Function
  setErrorMessage: Function
  docInput?: any
  mergeObj?: any
}

export const DocButtonWrap: React.VFC<Props> = (
  props: Props
) => {
  // *************** Functions *************** //
  const { functions } = DocumentFunctions()
  
  // *************** State *************** //
  const [canDownload, setCanDownload] = useState<boolean>(false)
  // 作成した申請書のURL
  const [fileUrl, setFileUrl] = useState<string>()
  
  // *************** JSX *************** //
  return (
    <div className="buttonWrap mt2-per w40-per mh-auto">
      <Button
        classProps="cancelButton pv10-px ph10-per"
        text="閉じる"
        function={functions.closeTab}
      />
      <Button
        classProps="updateButton pv10-px ph10-per"
        text="作成"
        function={() => functions.submitData(
          props.mergeObj,
          props.docInput,
          `${docPostURL}/${props.dId}/${props.uId}/${props.language}`,
          props.setModalOn,
          setCanDownload,
          setFileUrl,
          props.setErrorMessage
        )}
      />
      <DownloadButton
        className={`downloadButton pv12-px ph10-px
          ${canDownload ? "can" : "" }
        `}
        href={canDownload ? fileUrl : ""}
        text="ダウンロード"
      />
    </div>
  )
}