// Modules
import React, {
  ReactNode
} from 'react'

// *************** Type *************** //
type Props = {
  className?: any
  overlayClass?: string
  children?: ReactNode
  modalFunction?: any
}

export const Modal: React.VFC<Props> = (
  props: Props
) => {
  // *************** JSX *************** //
  return (
    <>
      <div className={`modal ${props.className}`}>
        { props.children }
      </div>
      <div
        className={`overlaySet 
          ${props.overlayClass}
        `}
        onClick={() => {props.modalFunction.closeModal()}}
      />
    </>
  )
}