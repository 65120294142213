// Components
import DocTemplateForm from "../pages/Document/DocTemplateForm"
import DetailsMaster from "../pages/Master/DetailsMaster"
import MasterFormList from "../pages/Master/MasterFormList"
import { StaffStatus } from "../pages/Status/StaffStatus"
import { ApplyCompany } from "../pages/client/ApplyCompany"
import POLODocuments from "../pages/Document/POLODocuments"
import { ApprovalUser } from "../pages/Master/ApprovalUser"
// Config
import {
  enterHelp,
  enteredHelp,
  enteredTbody,
  enteredThead,
  enteredTheadFixed,
  enterTbody,
  enterTheadFixed,
  specifiedEnterThead,
  traineeEnterThead, enterColumns
} from "./Status/StatusConfig"
import {
  agenciesURL,
  companiesURL,
  getEnteredURL,
  getEnterURL,
  kumiaiURL,
  postEnteredURL,
  postEnterURL,
  rsosURL,
  staffsURL,
  specifiedEnterURL,
  traineeEnterURL,
  specifiedEnteredURL,
  traineeEnteredURL,
  companiesWhereRsos,
  companiesWhereAgencies,
  getWhereURL,
  applicantURL,
  whereStaffURL,
  changeApplyURL,
  masterFormURL,
  arrayDynamicURL, getAdminClient, getRsoClient
} from "./Common/apiURL"
import {
  agenciesColumns,
  companiesColumns,
  kumiaiTbody,
  kumiaiTbodyFixed,
  kumiaiThead,
  kumiaiTheadFixed,
  rsosColumns,
  staffsColumns,
  staffsTbody,
  staffsTbodyFixed,
  staffsThead,
  staffsTheadFixed,
  tabMenu
} from "./Master/MasterConfig"
import {
  applicantColumns,
  applicantFixTbody,
  applicantFixThead,
  applicantTbody,
  applicantThead,
  interviewTab,
  recruitTab,
  rsosInterviewTab
} from "./Interview/InterviewConfig"

// *************** Routes *************** //
export const routePath = [
  {
    path: '/CompanyInfo',
    component: ApplyCompany,
    exact: true,
    breadcrumb: 'example'
  },
  {
    path: '/POLODocuments',
    component: POLODocuments,
    exact: true,
    breadcrumb: 'example'
  },
  {
    path: '/POLODocuments/id',
    component: POLODocuments,
    exact: true,
    breadcrumb: 'example'
  },
  {
    path: '/ApprovalUser/:uId/:cId',
    component: ApprovalUser,
    exact: true
  },
  {
    path: '/DocTemplateForm',
    component: DocTemplateForm,
    exact: true,
    breadcrumb: '申請書テンプレート作成'
  },
  {
    path: '/:userType/Detail/:uId',
    component: DetailsMaster,
    exact: true
  },
  {
    path: '/MasterFormList',
    component: MasterFormList,
    exact: true,
    breadcrumb: 'example'
  },
  {
    path: '/StaffStatus/:uId',
    component: StaffStatus,
    exact: true,
  },
  {
    path: '/pages/client/ApplyCompany',
    component: ApplyCompany,
    exact: true,
  },
  // {
  //   path: "*",
  //   component: Error,
  //   exact: false
  // }
]

// 課金対象者一覧
export const clientRoute = [
  {
    path: `/ClientList`,
    queryKey: 'hiredRsos',
    getUrl: getAdminClient,
  },
  {
    path: `/ClientList/rsos`,
    queryKey: 'hiredCompanies',
    getUrl: getRsoClient,
  }
]

// ユーザー選択ページ
export const userSelectRoute = [
  {
    can: 'agencies',
    path: '/interviewStaffs/agencies',
    queryKey: 'interviewCompanies',
    getUrl: companiesWhereAgencies,
    companies: true,
    rsos: true,
    apply: true
  },
  {
    can: 'agencies',
    path: '/applyStaffs/agencies',
    queryKey: 'applyCompanies',
    getUrl: companiesWhereAgencies,
    companies: true,
    rsos: true,
    apply: true
  },
  {
    can: 'agencies',
    path: '/SpecifiedSkill/enterStatus/agencies',
    queryKey: 'statusCompanies',
    getUrl: companiesWhereAgencies,
    companies: true,
    rsos: true,
    apply: false
  },
  {
    can: 'agencies',
    path: '/career/agencies',
    queryKey: 'careerCompanies',
    getUrl: companiesWhereAgencies,
    companies: true,
    rsos: true,
    apply: false
  },
  {
    can: 'agencies',
    path: '/staffs/agencies',
    queryKey: 'masterStaffs',
    getUrl: companiesWhereAgencies,
    companies: true,
    rsos: true,
    apply: false
  },
  {
    can: 'rsos',
    path: '/document/rsos',
    queryKey: 'DocumentCompanies',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: false
  },
  {
    can: 'rsos',
    path: '/staffs/rsos',
    queryKey: 'masterStaffs',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: false
  },
  {
    can: 'rsos',
    path: '/interviewStaffs/rsos',
    queryKey: 'interviewCompanies',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: true
  },
  {
    can: 'rsos',
    path: '/applyStaffs/rsos',
    queryKey: 'applyCompanies',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: true
  },
  {
    can: 'rsos',
    path: '/recruitStaffs/rsos',
    queryKey: 'recruitUsers',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: true
  },
  {
    can: 'rsos',
    path: '/SpecifiedSkill/enterStatus/rsos',
    queryKey: 'statusCompanies',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: false
  },
  {
    can: 'rsos',
    path: '/career/rsos',
    queryKey: 'careerCompanies',
    getUrl: companiesWhereRsos,
    companies: true,
    agencies: true,
    apply: false
  },
]

// 申請書テーブル
export const docTableRoute = [
  {
    can: 'admin',
    path: '/document',
    queryKey: 'document',
    url: staffsURL,
    link: '/document'
  },
  {
    can: 'rsos',
    path: '/document/rsos/companies/:cId',
    queryKey: 'documentCompanies',
    url: `${whereStaffURL}/companies/==`,
    link: '/document/rsos/companies'
  },
  {
    can: 'rsos',
    path: '/document/rsos/agencies/:cId',
    queryKey: 'documentAgencies',
    url: `${whereStaffURL}/agencies/==`,
    link: '/document/rsos/agencies'
  }
]

export const personalDocRoute = [
  {
    can: 'admin',
    path: '/document/:visa/:id/:language',
  },
  {
    can: 'rsos',
    path: '/document/rsos/companies/:cId/:visa/:id/:language',
  },
  {
    can: 'rsos',
    path: '/document/rsos/agencies/:cId/:visa/:id/:language',
  },
]

// マスターページ
export const masterRoute = [
  {
    can: 'staffsMaster',
    path: '/staffs',
    queryKey: 'staffs',
    roleData: 'staffs',
    url: staffsURL,
    postUrl: `${arrayDynamicURL}/staffs`,
    columns: staffsColumns,
    headerTab: tabMenu,
    tabAbility: 'admin',
    apply: false,
    status: true,
    memo: false,
    jobOffer: false,
  },
  {
    can: 'companiesMaster',
    path: '/companies',
    queryKey: 'companies',
    roleData: 'companies',
    url: companiesURL,
    postUrl: `${arrayDynamicURL}/companies`,
    columns: companiesColumns,
    headerTab: tabMenu,
    tabAbility: 'admin',
    apply: false,
    status: false,
    memo: false,
    jobOffer: false
  },
  {
    can: 'kumiaiMaster',
    user: 'admin',
    path: '/kumiai',
    postUrl: `${arrayDynamicURL}/kumiai`,
    queryKey: 'kumiai',
    roleData: 'kumiai',
    url: kumiaiURL,
    theadFixed: kumiaiTheadFixed,
    thead: kumiaiThead,
    tbodyFixed: kumiaiTbodyFixed,
    tbody: kumiaiTbody,
    headerTab: tabMenu,
    tabAbility: 'admin',
    apply: false,
    status: false,
    memo: false,
    jobOffer: false
  },
  {
    can: 'agenciesMaster',
    path: '/agencies',
    postUrl: `${arrayDynamicURL}/agencies`,
    columns: agenciesColumns,
    queryKey: 'agencies',
    roleData: 'agencies',
    url: agenciesURL,
    headerTab: tabMenu,
    tabAbility: 'admin',
    apply: false,
    status: false,
    memo: false,
    jobOffer: false
  },
  {
    can: 'rsosMaster',
    path: '/rsos',
    queryKey: 'rsos',
    roleData: 'rsos',
    url: rsosURL,
    postUrl: `${arrayDynamicURL}/rsos`,
    columns: rsosColumns,
    headerTab: tabMenu,
    tabAbility: 'admin',
    apply: false,
    status: false,
    memo: false,
    jobOffer: false
  },
  {
    can: 'admin',
    path: '/interviewStaffs',
    queryKey: 'interviewStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/interview`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'interview',
    buttonValue: 'recruit',
    buttonText: '内定',
    headerTab: rsosInterviewTab,
    selectUser: '',
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'admin',
    path: '/applyStaffs',
    queryKey: 'applyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/apply`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'apply',
    buttonValue: 'interview',
    buttonText: '面接許可',
    selectUser: '',
    headerTab: rsosInterviewTab,
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'admin',
    path: '/recruitStaffs',
    queryKey: 'recruitStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/recruit`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'recruit',
    buttonValue: 'hired',
    buttonText: '採用',
    headerTab: rsosInterviewTab,
    tabAbility: 'rsos',
    selectUser: '',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'companies',
    path: '/staffs/:cId',
    queryKey: 'hiredStaffs',
    roleData: 'staffs',
    url: `${whereStaffURL}/hired/==/${true}/companies/==`,
    postUrl: `${arrayDynamicURL}/staffs`,
    columns: staffsColumns,
    buttonDefault: 'hired',
    buttonValue: 'notHired',
    buttonText: '採用取り消し',
    apply: false,
    status: true,
    memo: false,
    jobOffer: false
  },
  {
    can: 'companies',
    path: '/interviewStaffs/:cId',
    queryKey: 'notRecruitStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/interview/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'interview',
    buttonValue: 'recruit',
    buttonText: '内定',
    headerTab: recruitTab,
    tabAbility: 'companies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'companies',
    path: '/recruitStaffs/:cId',
    queryKey: 'recruitStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/recruit/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'recruit',
    buttonValue: 'hired',
    buttonText: '採用',
    headerTab: recruitTab,
    tabAbility: 'companies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'agencies',
    path: '/staffs/agencies/companies/:userId',
    queryKey: 'staffs',
    roleData: 'staffs',
    url: `${whereStaffURL}/hired/==/${true}/companies/==`,
    postUrl: changeApplyURL,
    columns: staffsColumns,
    theadFixed: staffsTheadFixed,
    thead: staffsThead,
    tbodyFixed: staffsTbodyFixed,
    tbody: staffsTbody,
    apply: false,
    status: true,
    memo: false,
    jobOffer: false
  },
  {
    can: 'agencies',
    path: '/staffs/agencies/rsos/:userId',
    queryKey: 'staffs',
    roleData: 'staffs',
    url: `${whereStaffURL}/hired/==/${true}/rsos/==`,
    postUrl: changeApplyURL,
    columns: staffsColumns,
    theadFixed: staffsTheadFixed,
    thead: staffsThead,
    tbodyFixed: staffsTbodyFixed,
    tbody: staffsTbody,
    apply: false,
    status: true,
    memo: false,
    jobOffer: false
  },
  {
    can: 'agencies',
    path: '/applyStaffs/agencies/companies/:userId',
    queryKey: 'applyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/apply/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'apply',
    buttonValue: 'interview',
    buttonText: '面接許可',
    selectUser: 'companies',
    headerTab: interviewTab,
    tabAbility: 'agencies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'agencies',
    path: '/applyStaffs/agencies/rsos/:userId',
    queryKey: 'applyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/apply/rsos/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'apply',
    buttonValue: 'interview',
    buttonText: '面接許可',
    selectUser: 'rsos',
    headerTab: interviewTab,
    tabAbility: 'agencies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'agencies',
    path: '/interviewStaffs/agencies/companies/:userId',
    queryKey: 'interviewStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/interview/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'interview',
    buttonValue: 'apply',
    buttonText: '面接許可取り消し',
    headerTab: interviewTab,
    selectUser: 'companies',
    tabAbility: 'agencies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'agencies',
    path: '/interviewStaffs/agencies/rsos/:userId',
    queryKey: 'interviewStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/interview/rsos/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'interview',
    buttonValue: 'apply',
    buttonText: '面接許可取り消し',
    headerTab: interviewTab,
    selectUser: 'rsos',
    tabAbility: 'agencies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/applyStaffs/rsos/agencies/:userId',
    queryKey: 'applyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/apply/agencies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'apply',
    buttonValue: 'interview',
    buttonText: '面接許可',
    headerTab: rsosInterviewTab,
    selectUser: 'agencies',
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/interviewStaffs/rsos/agencies/:userId',
    queryKey: 'interviewAgencyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/interview/agencies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'interview',
    buttonValue: 'recruit',
    buttonText: '内定',
    headerTab: rsosInterviewTab,
    selectUser: 'agencies',
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/applyStaffs/rsos/companies/:userId',
    queryKey: 'applyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/apply/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'apply',
    buttonValue: 'interview',
    buttonText: '面接許可',
    headerTab: rsosInterviewTab,
    selectUser: 'companies',
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/interviewStaffs/rsos/companies/:userId',
    queryKey: 'interviewAgencyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/interview/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'interview',
    buttonValue: 'recruit',
    buttonText: '内定',
    headerTab: rsosInterviewTab,
    selectUser: 'companies',
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/recruitStaffs/rsos/companies/:userId',
    queryKey: 'recruitStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/recruit/companies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'recruit',
    buttonValue: 'hired',
    buttonText: '採用',
    headerTab: rsosInterviewTab,
    tabAbility: 'rsos',
    selectUser: 'companies',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/recruitStaffs/rsos/agencies/:userId',
    queryKey: 'interviewAgencyStaffs',
    roleData: 'staffs',
    url: `${applicantURL}/nowStatus/==/recruit/agencies/==`,
    postUrl: changeApplyURL,
    columns: applicantColumns,
    theadFixed: applicantFixThead,
    thead: applicantThead,
    tbodyFixed: applicantFixTbody,
    tbody: applicantTbody,
    buttonDefault: 'recruit',
    buttonValue: 'hired',
    buttonText: '採用',
    headerTab: rsosInterviewTab,
    selectUser: 'agencies',
    tabAbility: 'rsos',
    apply: true,
    status: false,
    memo: true,
    jobOffer: true
  },
  {
    can: 'rsos',
    path: '/staffs/rsos/companies/:userId',
    queryKey: 'hiredCompanyStaffs',
    roleData: 'staffs',
    url: `${whereStaffURL}/hired/==/${true}/companies/==`,
    postUrl: `${arrayDynamicURL}/staffs`,
    columns: staffsColumns,
    theadFixed: staffsTheadFixed,
    thead: staffsThead,
    tbodyFixed: staffsTbodyFixed,
    tbody: staffsTbody,
    buttonDefault: 'hired',
    buttonValue: 'notHired',
    buttonText: '採用取り消し',
    apply: false,
    status: true,
    memo: false,
    jobOffer: false
  },
  {
    can: 'rsos',
    path: '/staffs/rsos/agencies/:userId',
    queryKey: 'hiredAgenciesStaffs',
    roleData: 'staffs',
    url: `${whereStaffURL}/hired/==/${true}/agencies/==`,
    postUrl: `${arrayDynamicURL}/staffs`,
    columns: staffsColumns,
    theadFixed: staffsTheadFixed,
    thead: staffsThead,
    tbodyFixed: staffsTbodyFixed,
    tbody: staffsTbody,
    buttonDefault: 'hired',
    buttonValue: 'notHired',
    buttonText: '採用取り消し',
    apply: false,
    status: true,
    memo: false,
    jobOffer: false
  }
]

// 情報登録フォーム
export const masterFormRoute = [
  {
    path: '/:userType/:id/basic',
    questionUrl: `${masterFormURL}/step/==/basic/userType/==`,
    step: 'basic',
    can: 'basicForm',
    cautionText: '基本情報を入力してください。'
  },
  {
    path: '/:userType/:id/jobOffer',
    questionUrl: `${masterFormURL}/step/==/jobOffer/userType/==`,
    step: 'jobOffer',
    can: 'jobOfferForm',
    cautionText: '履歴書に必須な情報を入力してください。'
  },
  {
    path: '/:userType/:id/other',
    questionUrl: `${masterFormURL}/step/==/other/userType/==`,
    step: 'other',
    can: 'otherForm',
    cautionText: '仕事を始めるために必要な情報を入力してください。'
  },
]

// 申請書作成フォームページ
export const docFormRoute = [
  {
    path: '/document/:visa/:id/:language/:dId'
  },
  {
    path: '/POLODocuments/:visa/:id/:language/:dId'
  },
  {
    path: '/QuarterlyReport/:visa/:id/:language/:dId'
  }
]

// ステータスページ
export const statusRoute = [
  {
    can: 'admin',
    path: '/SpecifiedSkill/enterStatus',
    userUrl: `${specifiedEnterURL}/immigration/==/before`,
    getUrl: `${getEnterURL}/visa/==/specificSkill/immigration/==/before`,
    postUrl: postEnterURL,
    queryKey: 'specifiedEnter',
    theadFixed: enterTheadFixed,
    thead: specifiedEnterThead,
    tbody: enterTbody,
    title: '特定技能就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/SpecifiedSkill/enteredStatus',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'admin',
    path: '/Trainee/enterStatus',
    userUrl: `${traineeEnterURL}/immigration/==/before`,
    getUrl: `${getEnterURL}/visa/==/skillTraining/immigration/==/before`,
    postUrl: postEnterURL,
    queryKey: 'traineeEnter',
    theadFixed: enterTheadFixed,
    thead: traineeEnterThead,
    tbody: enterTbody,
    title: '技能実習就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/Trainee/enteredStatus',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'admin',
    path: '/SpecifiedSkill/enteredStatus',
    userUrl: `${specifiedEnteredURL}/immigration/==/after`,
    getUrl: `${getEnteredURL}/visa/==/specificSkill/immigration/==/after`,
    postUrl: postEnteredURL,
    queryKey: 'specifiedEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '特定技能就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/SpecifiedSkill/enterStatus',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'admin',
    path: '/Trainee/enteredStatus',
    userUrl: `${traineeEnteredURL}/immigration/==/after`,
    getUrl: `${getEnteredURL}/visa/==/skillTraining/immigration/==/after`,
    postUrl: postEnteredURL,
    queryKey: 'traineeEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '技能実習就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/Trainee/enterStatus',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enterStatus',
    path: '/SpecifiedSkill/enterStatus/:cId',
    userUrl: `${specifiedEnterURL}/immigration/==/before/companies/==`,
    getUrl: `${getEnterURL}/visa/==/specificSkill/immigration/==/before/companies/==`,
    postUrl: postEnterURL,
    queryKey: 'specifiedEnter',
    theadFixed: enterTheadFixed,
    thead: specifiedEnterThead,
    tbody: enterTbody,
    title: '特定技能就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/SpecifiedSkill/enteredStatus',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enterStatus',
    path: '/Trainee/enterStatus/:cId',
    userUrl: `${traineeEnterURL}/immigration/==/before/companies/==`,
    getUrl: `${getEnterURL}/visa/==/skillTraining/immigration/==/before/companies/==`,
    postUrl: postEnterURL,
    queryKey: 'traineeEnter',
    theadFixed: enterTheadFixed,
    thead: traineeEnterThead,
    tbody: enterTbody,
    title: '技能実習就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/Trainee/enteredStatus',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enteredStatus',
    path: '/SpecifiedSkill/enteredStatus/:cId',
    userUrl: `${specifiedEnteredURL}/immigration/==/after/companies/==`,
    getUrl: `${getEnteredURL}/visa/==/specificSkill/immigration/==/after/companies/==`,
    postUrl: postEnteredURL,
    queryKey: 'specifiedEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '特定技能就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/SpecifiedSkill/enterStatus',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enteredStatus',
    path: '/Trainee/enteredStatus/:cId',
    userUrl: `${traineeEnteredURL}/immigration/==/after/companies/==`,
    getUrl: `${getEnteredURL}/visa/==/skillTraining/immigration/==/after/companies/==`,
    postUrl: postEnteredURL,
    queryKey: 'traineeEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '技能実習就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/Trainee/enterStatus',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enterStatus',
    path: '/SpecifiedSkill/enterStatus/rsos/companies/:userId',
    userUrl: `${specifiedEnterURL}/immigration/==/before/companies/==`,
    getUrl: `${getEnterURL}/visa/==/specificSkill/immigration/==/before/companies/==`,
    postUrl: postEnterURL,
    queryKey: 'specifiedEnter',
    theadFixed: enterTheadFixed,
    thead: specifiedEnterThead,
    tbody: enterTbody,
    title: '特定技能就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/SpecifiedSkill/enteredStatus/rsos/companies',
    linkText: '就労後',
    helpContent: enterHelp,
    columns: enterColumns
  },
  {
    can: 'enterStatus',
    path: '/Trainee/enterStatus/rsos/companies/:userId',
    userUrl: `${traineeEnterURL}/immigration/==/before/companies/==`,
    getUrl: `${getEnterURL}/visa/==/skillTraining/immigration/==/before/companies/==`,
    postUrl: postEnterURL,
    queryKey: 'traineeEnter',
    theadFixed: enterTheadFixed,
    thead: traineeEnterThead,
    tbody: enterTbody,
    title: '技能実習就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/Trainee/enteredStatus/rsos/companies',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enteredStatus',
    path: '/SpecifiedSkill/enteredStatus/rsos/companies/:userId',
    userUrl: `${specifiedEnteredURL}/immigration/==/after/companies/==`,
    getUrl: `${getEnteredURL}/visa/==/specificSkill/immigration/==/after/companies/==`,
    postUrl: postEnteredURL,
    queryKey: 'specifiedEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '特定技能就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/SpecifiedSkill/enterStatus/rsos/companies',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enteredStatus',
    path: '/Trainee/enteredStatus/rsos/companies/:userId',
    userUrl: `${traineeEnteredURL}/immigration/==/after/companies/==`,
    getUrl: `${getEnteredURL}/visa/==/skillTraining/immigration/==/after/companies/==`,
    postUrl: postEnteredURL,
    queryKey: 'traineeEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '技能実習就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/Trainee/enterStatus/rsos/agencies',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enterStatus',
    path: '/SpecifiedSkill/enterStatus/rsos/agencies/:userId',
    userUrl: `${specifiedEnterURL}/immigration/==/before/agencies/==`,
    getUrl: `${getEnterURL}/visa/==/specificSkill/immigration/==/before/agencies/==`,
    postUrl: postEnterURL,
    queryKey: 'specifiedEnter',
    theadFixed: enterTheadFixed,
    thead: specifiedEnterThead,
    tbody: enterTbody,
    title: '特定技能就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/SpecifiedSkill/enteredStatus/rsos/agencies',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enterStatus',
    path: '/Trainee/enterStatus/rsos/agencies/:userId',
    userUrl: `${traineeEnterURL}/immigration/==/before/agencies/==`,
    getUrl: `${getEnterURL}/visa/==/skillTraining/immigration/==/before/agencies/==`,
    postUrl: postEnterURL,
    queryKey: 'traineeEnter',
    theadFixed: enterTheadFixed,
    thead: traineeEnterThead,
    tbody: enterTbody,
    title: '技能実習就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/Trainee/enteredStatus/rsos/agencies',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enteredStatus',
    path: '/SpecifiedSkill/enteredStatus/rsos/agencies/:userId',
    userUrl: `${specifiedEnteredURL}/immigration/==/after/agencies/==`,
    getUrl: `${getEnteredURL}/visa/==/specificSkill/immigration/==/after/agencies/==`,
    postUrl: postEnteredURL,
    queryKey: 'specifiedEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '特定技能就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/SpecifiedSkill/enterStatus/rsos/agencies',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enteredStatus',
    path: '/Trainee/enteredStatus/rsos/agencies/:userId',
    userUrl: `${traineeEnteredURL}/immigration/==/after/agencies/==`,
    getUrl: `${getEnteredURL}/visa/==/skillTraining/immigration/==/after/agencies/==`,
    postUrl: postEnteredURL,
    queryKey: 'traineeEntered',
    theadFixed: enteredTheadFixed,
    thead: enteredThead,
    tbody: enteredTbody,
    title: '技能実習就労後',
    buttonText: '就労前へ移す',
    buttonValue: 'before',
    linkPath: '/Trainee/enterStatus/rsos/agencies',
    linkText: '就労前',
    helpContent: enteredHelp
  },
  {
    can: 'enterStatus',
    path: '/SpecifiedSkill/enterStatus/agencies/companies/:userId',
    userUrl: `${specifiedEnterURL}/immigration/==/before/companies/==`,
    getUrl: `${getEnterURL}/visa/==/specificSkill/immigration/==/before/companies/==`,
    postUrl: postEnterURL,
    queryKey: 'specifiedEnter',
    theadFixed: enterTheadFixed,
    thead: specifiedEnterThead,
    tbody: enterTbody,
    title: '特定技能就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/SpecifiedSkill/enteredStatus/agencies/companies',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enterStatus',
    path: '/Trainee/enterStatus/agencies/companies/:userId',
    userUrl: `${traineeEnterURL}/immigration/==/before/companies/==`,
    getUrl: `${getEnterURL}/visa/==/skillTraining/immigration/==/before/companies/==`,
    postUrl: postEnterURL,
    queryKey: 'traineeEnter',
    theadFixed: enterTheadFixed,
    thead: traineeEnterThead,
    tbody: enterTbody,
    title: '技能実習就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/Trainee/enteredStatus/agencies/companies',
    linkText: '就労後',
    helpContent: enterHelp
  },

  {
    can: 'enterStatus',
    path: '/SpecifiedSkill/enterStatus/agencies/rsos/:userId',
    userUrl: `${specifiedEnterURL}/immigration/==/before/rsos/==`,
    getUrl: `${getEnterURL}/visa/==/specificSkill/immigration/==/before/rsos/==`,
    postUrl: postEnterURL,
    queryKey: 'specifiedEnter',
    theadFixed: enterTheadFixed,
    thead: specifiedEnterThead,
    tbody: enterTbody,
    title: '特定技能就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/SpecifiedSkill/enteredStatus/agencies/rsos',
    linkText: '就労後',
    helpContent: enterHelp
  },
  {
    can: 'enterStatus',
    path: '/Trainee/enterStatus/agencies/rsos/:userId',
    userUrl: `${traineeEnterURL}/immigration/==/before/rsos/==`,
    getUrl: `${getEnterURL}/visa/==/skillTraining/immigration/==/before/rsos/==`,
    postUrl: postEnterURL,
    queryKey: 'traineeEnter',
    theadFixed: enterTheadFixed,
    thead: traineeEnterThead,
    tbody: enterTbody,
    title: '技能実習就労前',
    buttonText: '就労後へ移す',
    buttonValue: 'after',
    linkPath: '/Trainee/enteredStatus/agencies/rsos',
    linkText: '就労後',
    helpContent: enterHelp
  }
]

// 職歴管理ページ
export const careerRoute = [
  {
    can: 'admin',
    path: '/career',
    getUrl: `${whereStaffURL}/hired/==/true`,
    queryKey: 'careerStaffs'
  },
  {
    can: 'companies',
    path: '/career/:cId',
    getUrl: `${whereStaffURL}/hired/==/${true}/companies/==`,
    queryKey: 'careerCompanyStaffs',
  },
  {
    can: 'agencies',
    path: '/career/agencies/companies/:cId',
    getUrl: `${whereStaffURL}/hired/==/${true}/companies/==`,
    queryKey: 'careerCompanyStaffs',
  },
  {
    can: 'agencies',
    path: '/career/agencies/rsos/:cId',
    getUrl: `${whereStaffURL}/hired/==/${true}/rsos/==`,
    queryKey: 'careerCompanyStaffs',
  },
  {
    can: 'rsos',
    path: '/career/rsos/companies/:cId',
    getUrl: `${whereStaffURL}/hired/==/${true}/companies/==`,
    queryKey: 'careerCompanyStaffs'
  },
  {
    can: 'rsos',
    path: '/career/rsos/agencies/:cId',
    getUrl: `${whereStaffURL}/hired/==/${true}/agencies/==`,
    queryKey: 'careerAgencyStaffs'
  },
]

// 承認ページ
export const approvalRoute = [
  {
    can: 'admin',
    path: '/ApprovalUser/:uId/:cId'
  },
  {
    can: 'approvalUser',
    path: '/ApprovalAdmin/:userType/:uId',
  },
]