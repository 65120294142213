// Modules
import { useRecoilValue } from "recoil"
// Images
import askLogo from '../../Images/ASK logo.jpeg'
import sbiLogo from '../../Images/SBIR_logo_200dpi.jpg'
// Config
import { storageState } from "../../config/Common/RecoilState"
// FontAwesome
import {
  faCalendarAlt,
  faUserPlus,
  faSitemap
} from '@fortawesome/free-solid-svg-icons'

export const ClientsFunctions = () => {
  // *************** Const *************** //
  const storage = useRecoilValue(storageState)
  // path
  const sysKeyPath = storage && `/staffs/${storage.uId}/${storage.step ? storage.step : 'basic'}`
  const statusPath = storage && `/StaffStatus/${storage.uId}`

  // *************** Array *************** //
  const navigation = [
    // { text: 'About', link: '' },
    // { text: 'Contact', link: '' },
    { text: 'Login / Sign Up', link: '/Authentication' },
    // { text: 'Sign up', link: '' },
  ]
  // ページナビゲーション
  const pageNav = [
    { tag: 'a', text: 'Register CV', link: sysKeyPath, icon: faUserPlus },
    // { tag: 'a', text: 'Apply', link: applyPath, icon: faIdCard },
    { tag: 'a', text: 'Status', link: statusPath, icon: faCalendarAlt },
    { tag: 'anchor', text: 'Our services', link: '#service', icon: faSitemap },
  ]

  const sections = [
    {
      title: '母国への送金',
      enTitle: 'Remittance',
      img: sbiLogo,
      detail: '大切なお金を母国に送るために、安くて簡単で便利な「SBIレミット」に登録しましょう！',
      enDetail: 'Register “SBI remit”! Make your transaction easier and more affordable!',
      text: '送金へ',
      link: 'https://www.remit.co.jp/en_landingpage/sion/'
    },
    {
      title: '日本語学習',
      enTitle: 'Japanese E-learning!',
      img: askLogo,
      detail: '日本で働く・日本で暮らすために、「eTRY! JLPTコース」を使って、日本語を勉強しましょう！',
      enDetail: 'Enhance your Japanese skills with eTRY! JLPT course!',
      text: '日本語学習を始める',
      link: 'https://ask-online.net/student/login'
    },
    // {
    //   title: 'タイトル名',
    //   img: image,
    //   detail: '説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明説明(100文字)',
    //   text: 'リンク名',
    //   link: ''
    // },
  ]

  // const usage = [
  //   {
  //     title: 'Create Your Account.',
  //     img: connectimg0,
  //     detail: '使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明（75文字）'
  //   },
  //   {
  //     title: 'Register your information.',
  //     img: connectimg1,
  //     detail: 'Text texttext text texttext text text texttext text texttext text text texttext text texttext Text text texttext text Texttext text.（20語1文）'
  //   },
  //   {
  //     title: 'Check own status.',
  //     img: connectimg2,
  //     detail: '使い方説明使い方説明使い方説明使い方説明使い方説明使い方説明（30文字）'
  //   },
  //   {
  //     title: 'Work Together !!',
  //     img: image2,
  //     detail: 'Text texttext text texttext text text texttext text texttext text text texttext text texttext Text text texttext text Texttext text.  Text texttext text texttext text text texttext text texttext text text texttext text texttext Text text texttext text Texttext text.（40語2文）'
  //   },
  // ]

  const connectLists = [
    {
      title: 'データ登録',
      enTitle: 'Register CV!',
      text: 'あなたの情報を登録して日本で働く準備をしよう！',
      enText: 'Register info here and Get excited to work in Japan!',
      icon: faUserPlus,
      button: '登録する / Register',
      href: sysKeyPath
    },
    // {
    //   title: '求人へ応募',
    //   enTitle: 'Apply',
    //   text: '求人へ応募しよう！',
    //   enText: "Let's apply a job!",
    //   icon: faIdCard,
    //   button: '応募 / Apply',
    //   href: applyPath
    // },
    {
      title: 'ステータス',
      enTitle: 'STATUS',
      text: '日本で働くためのステータスを確認しよう！',
      enText: 'Check your progress to work in Japan!',
      icon: faCalendarAlt,
      button: '確認する / Confirm',
      href: statusPath

    }
  ]

  // *************** Functions *************** //
  const staffs = {
    /**
     * 表示を切り替える
     * @param {*} k indexの値
     * @param {*} setState
     */
    switchIndex: (
      k: number,
      setState: Function
    ) => setState(k),
    // 前へボタン
    // preIndex: () => list > 0 && setList(list-1),
    // 次へボタン
    // foreIndex: () => list < usage.length-1 && setList(list+1)
  }

  return {
    pageNav,
    sections,
    // usage,
    connectLists,
    staffs
  }
}