// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  src?: string
  alt?: string
}

export const Image: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <img
    className={props.className}
    src={props.src}
    alt={props.alt}
  />
)