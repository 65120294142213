// Modules
import React from "react"
// Components
import { Title } from "../../Atoms/Title"
// Stylesheets
import '../../../stylesheets/components/Molecules/Error.scss'

// *************** Type *************** //
type Props = {
  error?: any
}

export const Error: React.VFC<Props> = (
  { error }: Props
) => {
  // *************** Const *************** //
  // let navigation = useNavigate()
  const errorMessage = error ? error.message : "Not Found"

  // *************** JSX *************** //
  return (
    <main
      role="alert"
      className="errorComponent justify-center h-screen py-32"
    >
      <div className="w-3/4 mx-auto text-center">
        <div className="errorTitle">
          <Title
            className="w-52 mx-auto"
          />
        </div>
        <div className="errorContent mx-auto">
          <h1 className="mt-5 text-5xl">404</h1>
          <pre className="mt-5 font-bold">{errorMessage}</pre>
          <div className="mt-5">
            <h4 className="font-bold">ページが見つかりません</h4>
            {/*<p className="mt-3">*/}
            {/*  お探しのページは一時的にアクセスできないか、移動または削除された可能性があります。<br/>*/}
            {/*  正しいURLかご確認の上、<a href="/Help" className="font-bold">ヘルプ</a>をご確認いただくか、<a href="/" className="font-bold">ホーム</a>から再度お探しください。*/}
            {/*</p>*/}
            <p className="mt-3">
              お探しのページは一時的にアクセスできないか、移動または削除された可能性があります。<br/>
              正しいURLかご確認の上、<a href="/" className="font-bold">HOME</a>から再度お探しください。
            </p>
          </div>
          <div className="errorButtons flex center wrap">
            {/* <button
              className="errorButton updateButton"
              // onClick={() => navigation(-1)}
            >
              戻る
            </button> */}
            <a href="/" className="errorButton cancelButton mt-5 mx-auto py-3 px-16">
              HOMEへ
            </a>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Error