// Modules
import React from "react"

export const Logo = () => (
  // *************** JSX *************** //
  <svg
    className="logo"
    version="1.1"
    id="レイヤー_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="50 30 130 160.8"
    style={{enableBackground: "new 0 0 221 220.8"}}
    xmlSpace="preserve"
  >
    <g id="_x31__5_">
      <path
        className="st0"
        d="M89.2,131.5c2.5,2.5,6.5,2.5,8.9,0l14-14c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.7,1.1l11.3,11.3
        c0.7,0.7,1.1,1.6,1.1,2.6c0,1-0.4,1.9-1.1,2.6l-37,37c-0.7,0.7-1.6,1.1-2.6,1.1c-1,0-1.9-0.4-2.6-1.1l-37-37
        c-0.7-0.7-1.1-1.6-1.1-2.6c0-1,0.4-1.9,1.1-2.6l37-37c0.7-0.7,1.6-1.1,2.6-1.1c1,0,1.9,0.4,2.6,1.1l11.4,11.4
        c0.7,0.7,1.1,1.6,1.1,2.7c0,1-0.4,1.9-1.1,2.6l-14,14C86.7,125.1,86.7,129.1,89.2,131.5L89.2,131.5z"
      />
      <path
        className="st1"
        d="M134,46c-1,0-1.9,0.4-2.6,1.1l-37,37c-0.7,0.7-1.1,1.6-1.1,2.6c0,1,0.4,1.9,1.1,2.6l13.9,13.9
        c1.4,1.4,1.4,3.8,0,5.2L91.8,125c-1.1,1.1-1.2,2.8-0.2,4c1.1,1.2,2.9,1.2,4.1,0.1l14-14c0,0,0,0,0,0l2.7-2.7c1.4-1.4,3.8-1.4,5.2,0
        l13.9,13.9c0.7,0.7,1.6,1.1,2.6,1.1c1,0,1.9-0.4,2.6-1.1l37-37c0.7-0.7,1.1-1.6,1.1-2.6c0-1-0.4-1.9-1.1-2.6l-37-37
        C135.9,46.3,135.1,46,134,46z M138.1,86.6l-4,4l-4-4l4-4L138.1,86.6z"
      />
    </g>
  </svg>
)