// Modules
import React from "react"
import { useQuery } from 'react-query'
import { Link } from "react-router-dom"
// Components
import { LoadingTable } from '../../Atoms/LoadingTable'
// API
import { getAPI } from "../../../functions/Api"

// *************** Type *************** //
type Props = {
  queryKey: string
  url: string
}

export const HiredStaffs: React.VFC<Props> = ({
  queryKey,
  url
}: Props) => {
  // *************** ReactQuery *************** //
  const {
    isLoading,
    isError,
    data
  } = useQuery(
    queryKey,
    () => getAPI(url),
    { onSuccess: () => console.log("ALL DATAS WERE FETCHED CORRECTLY")}
  )

  // *************** JSX *************** //
  return (
    <div className="hiredDetails p-3 rounded-md">
      { isLoading ? (
        <LoadingTable />
      ) : isError ? (
        <p>データ取得に失敗しました。。。</p>
      ) : (
        <div className="p-3 rounded-md bg-white">
          <h2 className="font-bold text-3xl">
            採用情報
          </h2>
          <h3 className="mt-2 font-bold text-xl">
            採用人数：{data.length}
          </h3>
          <ul>
            { data.map((d: any, i: number) => (
              <li key={i}>
                <div className="v_line_fix">
                  <p className="flex mt-2 font-bold text-xl">
                    <span>氏名：</span>
                    <Link to={`/staffs/detail/${d.id}`}>
                      {`${d["staff-family"]} ${d["staff-first"]}`}
                    </Link>
                  </p>
                </div>
                <p className="mt-2 font-bold text-xl">
                  出身国：{d["staff-country"]}
                </p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}