import React from 'react'
import StaticHeader from "../../Layouts/StaticHeader"

const Policy = () => {
  return (
    <div>
      <StaticHeader />
      <div className="pageContents">
        <h3>プライバシーポリシー</h3>
        <div className="container">
          <p>
            株式会社シオン（以下、「当社」といいます）は、個人情報が個人の重要な財産であることを認識し、個人情報を適切に取得・利用し、個人情報に関する法令等を遵守し、個人情報の漏えい等を防止・是正するため、以下のとおり個人情報の取扱方針を定め、細心の注意を払って管理運用します。 なお、リンク先など他事業者等による個人情報収集は、当プライバシーポリシーの適用範囲ではございません。また、当社が提供するサービス「CONNECT」については、サービス毎に定めたプライバシーポリシーが適用されますのでご留意下さい。
          </p>

          <h4>第1条 (適切な取得と利用目的)</h4>
          <p>
            当社は適法な手段により、以下の利用目的のため、個人情報を取得します。なお、以下にかかわらず、取得時に利用目的を別途明示した場合には、明示された目的においても利用することがあります。
          </p>

          <h4>第2条（適正な管理と保護）</h4>
          <p>
            個人情報の管理は、厳重に行うこととし、個人情報に関する不正アクセス、紛失、破壊、改ざん、漏洩を防ぐための適切な予防および是正処置を行います。
          </p>

          <h4>第3条（第三者提供）</h4>
          <p>
            当社は、以下の場合を除き、第三者に対しデータを開示・提供することはいたしません。
            <br />
            ご本人が事前に同意した場合
            <br />
            法律に基づく場合、当社サービスの利用規約の違反があり、当社の権利、財産やサービスなどを保護するために、個人情報を公開せざるをえないと判断するに足る十分な根拠がある場合
            人の生命、身体または財産の保護のために必要がある場合であって、ご本人の同意を得ることが困難である場合
          </p>
        </div>
      </div>
    </div>
  )
}

export default Policy