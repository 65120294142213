// Modules
import React, {
  useState
} from 'react'
import { useRecoilValue } from "recoil"
import { useMutation } from 'react-query'
import Cookie from "js-cookie"
// API
import { postAPI } from "../../../functions/Api"
// Components
import { Input } from "../../Atoms/Input"
import { Button } from "../../Atoms/Button"
// Function
import { CommonFunctions } from "../../../functions/CommonFunction"
// Config
import { storageState } from "../../../config/Common/RecoilState"
import { sendEmailURL } from "../../../config/Common/apiURL"

export const UserSearch: React.VFC = () => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()
  // ストレージのデータを取得
  const { uId } = useRecoilValue(storageState)
  // const local = JSON.parse(Cookie.get("__session") as string)

  // *************** useQuery *************** //
  const mutation = useMutation(data => postAPI(
    sendEmailURL,
    data
  ), {
    onError: async () => { console.log('更新に失敗しました。') },
    onSuccess: async () => { console.log('更新成功！！') }
  })

  // *************** State *************** //
  const [idInput, setIdInput] = useState<any>({})

  // *************** JSX *************** //
  return (
    <div className="searchWrap
      dis-inline-flex w-full p1-per br5-px"
    >
      <Input
        className="f1 p1-per"
        name="clientId"
        type="text"
        placeholder="ユーザーID"
        value={ idInput && idInput.clientId ? idInput.clientId : ''}
        function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setValue(
          e.target.name,
          e.target.value,
          setIdInput
        )}
      />
      <Button
        classProps={`updateButton ph1-per br2-px
          ${ mutation.isLoading && 'disable'} 
          ${ mutation.isSuccess && 'success'} 
          ${ mutation.isError && 'error'}
        `}
        text={ mutation.isLoading ? '送信中...'
          : mutation.isSuccess ? '成功'
          : mutation.isError ? 'エラー'
          : '送信'
        }
        function={() => commons.postMutation(
          mutation,
          { userId: uId },
          idInput
        )}
      />
    </div>
  )
}