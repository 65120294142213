// Modules
import React from 'react'
import {
  Switch,
  Route
} from "react-router-dom"
import { Can } from "../config/Common/Can"
// Components
import { Error } from '../components/Molecules/Error/Error'
import { ClientList } from "../pages/Top/ClientList"
import { StaffTop } from "../pages/Top/StaffTop"
import Top from "../pages/Top/Top"
import { DocumentCatalog } from "../pages/Document/DocumentCatalog"
import { MasterCatalog } from '../pages/Master/MasterCatalog'
import { ChangeSysKey } from '../pages/Master/ChangeSysKey'
import { DocumentForm } from "../pages/Document/DocumentForm"
import { StatusCatalog } from '../pages/Status/StatusCatalog'
import { UserSelect } from '../pages/Master/UserSelect'
import { CareerCatalog } from '../pages/Career/CareeCatalog'
import { ApprovalUser } from '../pages/Master/ApprovalUser'
// Config
import {
  routePath,
  clientRoute,
  masterRoute,
  masterFormRoute,
  docFormRoute,
  personalDocRoute,
  docTableRoute,
  statusRoute,
  userSelectRoute,
  careerRoute,
  approvalRoute
} from "../config/Routes"
import PersonalDocuments from "../pages/Document/PersonalDocuments"

// *************** Type *************** //
type Props = {
  storageData?: any
}

export const Content: React.VFC<Props> = (
  props: Props
) => {
  // *************** JSX *************** //
  return (
    <Switch>
      <Route
        path='/'
        exact
      >
        { props.storageData && props.storageData.userType === 'staffs'
          ? <StaffTop /> : <Top />
        }
      </Route>

      {/* 課金対象者一覧 */}
      { clientRoute.map((client: any, c: number) => (
        <Route
          key={c}
          path={client.path}
          exact
        >
          {/*<Header />*/}
          <ClientList
            queryKey={client.queryKey}
            getUrl={client.getUrl}
          />
        </Route>
      ))}

      {/*<Route component={ Error } />*/}
      { routePath.map((route: any, i: number) => (
        <Route
          key={i}
          path={route.path}
          exact={route.exact}
          component={route.component}
        />
      ))}
      {/* ユーザー選択ページ(企業)*/}
      { userSelectRoute.map((select: any, s: number) => (
        <Route
          key={s}
          path={select.path}
          exact
        >
          <Can
            I="read"
            a={select.can}
          >
            <UserSelect
              queryKey={select.queryKey}
              getUrl={select.getUrl}
              companies={select.companies}
              agencies={select.agencies}
              rsos={select.rsos}
              link={select.path}
              apply={select.apply}
            />
          </Can>
        </Route>
      ))}
      {/* 申請書ページ */}
      { docTableRoute.map((document: any, d: number) => (
        <Route
          key={d}
          path={document.path}
          exact
        >
          <Can
            I="read"
            a={document.can}
          >
            <DocumentCatalog
              queryKey={document.queryKey}
              url={document.url}
              link={document.link}
            />
          </Can>
        </Route>
      ))}

      {/* 申請書一覧 */}
      { personalDocRoute.map((personal: any, p: number) => (
        <Route
          key={p}
          path={personal.path}
          exact
        >
          <Can
            I="read"
            a={personal.can}
          >
            <PersonalDocuments
              url={personal.url}
            />
          </Can>
        </Route>
      ))}

      {/* マスター管理ページ */}
      { masterRoute.map((master: any, h: number) => (
        <Route
          key={h}
          path={master.path}
          exact
        >
          <Can
            I="read"
            a={master.can}
          >
            <MasterCatalog
              queryKey={master.queryKey}
              roleData={master.roleData}
              url={master.url}
              postUrl={master.postUrl}
              theadFixed={master.theadFixed}
              thead={master.thead}
              tbodyFixed={master.tbodyFixed}
              tbody={master.tbody}
              buttonText={master.buttonText}
              buttonDefault={master.buttonDefault}
              buttonValue={master.buttonValue}
              columns={master.columns}
              headerTab={master.headerTab}
              selectUser={master.selectUser}
              tabAbility={master.tabAbility}
              apply={master.apply}
              status={master.status}
              memo={master.memo}
              jobOffer={master.jobOffer}
            />
          </Can>
        </ Route>
      ))}

      {/*　マスターデータ登録ページ　*/}
      { masterFormRoute.map((form: any, f: number) => (
        <Route
          key={f}
          path={form.path}
          exact
        >
          <ChangeSysKey
            questionUrl={form.questionUrl}
            step={form.step}
            cautionText={form.cautionText}
          />
        </Route>
      ))}

      {/* 申請書フォームページ */}
      { docFormRoute.map((doc, j) => (
        <Route
          key={j}
          path={doc.path}
          exact
        >
          <DocumentForm />
        </Route>
      ))}

      { statusRoute.map((status: any, k: number) => (
        <Route
          key={k}
          path={status.path}
          exact
        >
          <StatusCatalog
            can={status.can}
            userUrl={status.userUrl}
            getUrl={status.getUrl}
            postUrl={status.postUrl}
            queryKey={status.queryKey}
            theadFixed={status.theadFixed}
            thead={status.thead}
            tbody={status.tbody}
            title={status.title}
            buttonText={status.buttonText}
            buttonValue={status.buttonValue}
            linkPath={status.linkPath}
            linkText={status.linkText}
            helpContent={status.helpContent}
            columns={status.columns}
          />
        </Route>
      ))}

      {/* 職歴管理 */}
      { careerRoute.map((career: any, l: number) => (
        <Route
          key={l}
          path={career.path}
          exact
        >
          <CareerCatalog
            getUrl={career.getUrl}
            queryKey={career.queryKey}
          />
        </Route>
      ))}
      {/* 承認ページ */}
      { approvalRoute.map((approval: any, a: number) => (
        <Route
          key={a}
          path={approval.path}
        >
          <Can
            I="read"
            a={approval.can}
          >
            <ApprovalUser

            />
          </Can>
        </Route>
      ))}
    </Switch>
  )
}