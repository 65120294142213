// Modules
import React, {
  useState
} from 'react'
// Stylesheets
import '../../../stylesheets/components/Molecules/HelpProvider.scss'

// *************** Type *************** //
type Props = {
  className?: string
  children?: any　// オリジナルのヘルプボタンを使う場合はchildrenに渡す
  isUseChildElement: boolean // オリジナルのヘルプのボタンを使う場合はTRUE
  title: String // ヘルプのタイトル
  content: JSX.Element | String // ヘルプの詳細コンテンツ
}

export const HelpProvider: React.VFC<Props> = ({
  className,
  isUseChildElement,
  children,
  title,
  content
}) => {
  const [_isHelpClicked, set_isHelpClicked] = useState<boolean>(false)

  // func
  let _contents: any = !isUseChildElement
    ? <h1 className="fw-b fs16-px dot-button"> ? </h1>
    : children

  // components
  const helpTop: any = (
    <div className="help-top">
      <div className="textWrapper">
        <div className="v_line_fix">
          <h2 className="fs24-px">{title}</h2>
        </div>
        { typeof content !== 'string' ? content : `${content}` }
      </div>
    </div>
  )

  return (
    <div className={`HelpProvider ${className}`}>
      <div
        onMouseEnter={() => set_isHelpClicked(true)}
        onMouseLeave={() => set_isHelpClicked(false)}
      >
        {_contents}
      </div>
      {_isHelpClicked && helpTop}
    </div>
  )
}