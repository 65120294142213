// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  name: string
  label: any
  value?: any
  options?: Array<any>
  optionKey?: string | number
  optionValue?: string | number
  function?: any
  multiple? : boolean
}

export const Select: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <select
    className={props.className}
    id={props.name}
    name={props.name}
    value={props.value}
    onChange={props.function}
    multiple={props.multiple}
  >
    <optgroup label={props.label}>
      <option
        hidden
        value=""
      />
      { props.options && props.options.map((option, i) => (
        <option
          key={i}
          // className={option.class}
          value={ props.optionValue
            ? option[props.optionValue]
            : option
          }
        >
          { props.optionKey
            ? option[props.optionKey]
            : props.optionKey === 0 ? option
            : "選択肢名がありません"
          }
        </option>
      ))}
    </optgroup>
  </select>
)