// Modules
import React from 'react'
// Styles
import '../../stylesheets/common.scss'

// *************** Type *************** //
type Props = {
  classProps?: string
  function?: any
  type?: string
  name?: string
  value?: any
  text?: any
  disabled?: boolean
}

export const Button: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <button
    className={props.classProps}
    name={props.name}
    value={props.value}
    onClick={props.function}
    disabled={props.disabled}
  >
      {props.text}
  </button>
)