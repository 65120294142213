// Modules
import React, {
  useState
} from 'react'
import { useMutation } from "react-query"
// Components
import { DownloadButton } from "../../Atoms/DownloadButton"
import { Button } from "../../Atoms/Button"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { postAPI } from "../../../functions/Api"
// Config
import { exportJobOffer } from "../../../config/Common/apiURL"

// *************** Type *************** //
type Props = {
  id?: string | undefined
  cId?: string | undefined
}

export const JobOfferList: React.VFC<Props> = ({
  id,
  cId
}: Props) => {
  // *************** Const *************** //
  const [fileUrl, setFileUrl] = useState<string>('')
  const { commons } = CommonFunctions()
  const mutation = useMutation (data => postAPI(
    exportJobOffer,
    data
  ),
  {
    onError: async (error) => console.log (error),
    onSuccess: async (url) => setFileUrl(url.data)
  })

  // *************** JSX *************** //
  return (
    <li>
      { mutation.isSuccess && fileUrl ? (
        <DownloadButton
          className="switchButton w-full py-1 px-2 success"
          href={fileUrl}
          text="ダウンロード"
        />
      ) : (
        <Button
          classProps={`switchButton w-full py-1 px-2
            ${mutation.isLoading && 'disable'}
            ${mutation.isError && 'error'}
          `}
          text={ mutation.isLoading ? '作成中'
            : mutation.isError ? 'エラー'
            : '履歴書作成'
          }
          function={() => commons.postMutation(
            mutation,
            {},
            {
              id: id,
              companies: cId,
            }
          )}
        />
      )}
    </li>
  )
}