// Modules
import React from 'react'
import { useQuery } from 'react-query'
import { useRecoilValue } from "recoil"
// API
import { getAPI } from "../../functions/Api"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from '../../components/Atoms/LoadingTable'
import { Error } from '../../components/Molecules/Error/Error'
import { AdminCount } from "../../components/Molecules/Top/AdminCount"
import { RsoCount } from "../../components/Molecules/Top/RsoCount"
// Config
import { storageState } from "../../config/Common/RecoilState"
import { getClient, getWhereURL } from "../../config/Common/apiURL"
// Stylesheets
import '../../stylesheets/pages/Top/HiredInfo.scss'

// *************** Type *************** //
type Props = {
  queryKey: string
  getUrl?: string | undefined
}

export const ClientList: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { uId, admin } = useRecoilValue(storageState)

  // *************** ReactQuery *************** //
  const promises = async () => {
    // 登録支援機関一覧
    const userData = await getAPI(admin ? `${props.getUrl}` : `${props.getUrl}/${uId}` )
    // 外部で使用可能にする
    return {
      userData
    }
  }

  const {
    isLoading,
    isError,
    data
  } = useQuery(
    props.queryKey,
    () => promises(),
    {
      onSuccess: () => console.log("ALL DATAS WERE FETCHED CORRECTLY")
    }
  )

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="HiredInfo">
        <h2 className="mt-2 font-bold text-3xl text-center">
          顧客リスト一覧
        </h2>
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : admin ? (
          <AdminCount rsos={data?.userData} />
        ) : (
          <RsoCount data={data?.userData} />
        )}
      </main>
    </Layouts>
  )
}