// Modules
import React from 'react'
// Components
import { Layouts } from "../../Layouts/Layouts"
import { Title } from "../../components/Atoms/Title"

export const NotApproval: React.VFC = () => {
  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="notApproval">
        <div
          className="messageWrap mt-5 mx-auto text-center"
        >
          <h2 className="messageTitle fw-b fs2-rem">
            アカウント承認待ちです。
          </h2>
          <div className="mt-3">
            <p className="mt-5 lh2-rem font-bold">
              システム管理者からアカウントを承認されると<br/>
              <Title
                className="w-80 mt-5 mx-auto"

              /><br/>
              を利用できるようになります。
            </p>
          </div>
        </div>
      </main>
    </Layouts>
  )
}

export default NotApproval