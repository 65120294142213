// Modules
import React, {
  useState,
  useEffect
} from 'react'
// Components
import { Label } from "../../Atoms/Label"
import { Input } from "../../Atoms/Input"
import { DocImages } from "./DocImages"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { DocumentFunctions } from "../../../functions/Document/DocumentFunctions"

// *************** Type *************** //
type Props = {
  id: string
  setSysInput: Function
  docData?: any
  imageData?: any
  sysData?: any
  setDocInput: Function
  docInput?: any
}

export const ExcelFormComponent: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()
  const { functions } = DocumentFunctions()

  // *************** UseState *************** //
  const [excelForm, setExcelForm] = useState<Array<any>>([])
  const [docPng, setDocPng] = useState<Array<any>>([])

  // *************** Functions *************** //
  // 非同期通信をまとめる
  const asyncFunctions = async () => {
    // 「title, body」のデータ形にする
    await functions.transFormData(
      props.docData,
      setExcelForm
    )
    // sysKeyクラスを付与する
    await functions.setClass()
    // 全inputタグのvalueをオブジェクト化
    await functions.setValue(props.setSysInput)
    // sysKeyを入れる
    await functions.addSysExcel(
      props.docData,
      props.sysData,
      props.setDocInput
    )
  }
  // 申請書のタイトルが変化したら発火する
  useEffect(() => {
    // 非同期通信を発火
    asyncFunctions()
    // pngファイルをstateに格納する
    props.imageData.length && (
      commons.getStorageFile(
        `DocumentsTemplate/${props.docData.dId}`,
        props.imageData,
        setDocPng
      )
    )
  }, [])

  // *************** JSX *************** //
  return (
    <div className="excelFormComponent">
      <form className="excelForm">
        <div className="formContent">
          { docPng.length ? (
            <DocImages
              images={docPng.length && docPng}
              docData={props.docData}
            />
          ) : (
            <p className="swiperImage font-bold">
              画像ファイルがありません。
            </p>
          )}
          <div className="splitLine" />
          <ul>
            { excelForm.sort((a, b) => a.body.number - b.body.number)
              .map((n, i) => (
              <li key={i}>
                <div className="inputHead">
                  <p>{i+1}.</p>
                  <Label
                    htmlFor={n.title}
                    text={n.body.label}
                  />
                </div>
                <Input
                  className="documentInput"
                  name={n.title}
                  type="text"
                  value={
                    props.docInput && props.docInput[n.title]
                    ? props.docInput[n.title] : ''
                  }
                  function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setValue(
                    e.target.name,
                    e.target.value,
                    props.setDocInput
                  )}
                />
                {/* <p>例）{n.body.placeholder}</p> */}
              </li>
            ))}
          </ul>
        </div>
      </form>
    </div>
  )
}