// Modules
import React from 'react'
import DatePicker, {
  registerLocale
} from "react-datepicker"
// Styles
import "react-datepicker/dist/react-datepicker.css"

// *************** Type *************** //
type Props = {
  className?: string
  dataFormat: string
  name: string
  localeData: any
  localeName: string
  placeholder?: string
  disabled?: boolean
  value?: any
  function?: any
  yearOnly?: boolean
  monthOnly?: boolean
}

export const DateInput: React.VFC<Props> = (
  props: Props
) => {
  // Locale 登録
  registerLocale(
    props.localeName,
    props.localeData
  )

  // *************** JSX *************** //
  return (
    <DatePicker
      className={props.className}
      dateFormat={props.dataFormat}
      name={props.name}
      locale={props.localeName}
      placeholderText={props.placeholder}
      selected={props.value}
      onChange={props.function}
      showYearPicker={props.yearOnly && props.yearOnly}
      showMonthYearPicker={props.monthOnly && props.monthOnly}
      disabled={props.disabled}
    />
  )
}
