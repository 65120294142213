// Modules
import React from 'react'
import { useRecoilValue } from "recoil"
import { Link } from "react-router-dom"
// Components
import { Title } from "../../Atoms/Title"
import { Button } from "../../Atoms/Button"
import { Modal } from "../../Atoms/Modal"
import { UserSearch } from "./UserSearch"
// Functions
import { LayoutsFunctions } from "../../../functions/Layouts/LayoutsFunctions"
// Config
import { storageState } from "../../../config/Common/RecoilState"
import { Can } from "../../../config/Common/Can"
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTimes } from "@fortawesome/free-solid-svg-icons"

// *************** Type *************** //
type Props = {
  userType?: string
  uId?: string
}

export const AccountModal: React.VFC<Props> = (
  props: Props
) => {
// *************** Const *************** //
  const { layouts } = LayoutsFunctions()
  const {
    uId,
    userType,
    adminApproval
  } = useRecoilValue(storageState)

  // *************** JSX *************** //
  return (
    <Modal
      className="accountMenu absolute z-50 top-16 right-4 p-4 rounded-md"
      modalFunction={layouts}
    >
      <Button
        classProps="accountMenu__delete block ml-auto"
        text={ <FontAwesomeIcon icon={faTimes} /> }
        function={layouts.closeModal}
      />
      <div className="accountMenu__box">
        <div>
          <p className="text-center">ユーザー ID</p>
          <p className="accountMenu__userName font-bold text-center">
            {uId}
          </p>
        </div>
      </div>
      <Link
        className="updateButton mt-3 py-2"
        to={`/${props.userType}/detail/${props.uId}`}
      >
        登録情報
      </Link>
      <Can
        I="read"
        a="connectUser"
      >
        <div className="mt-5">
          <p className="text-center font-bold">
            ユーザーと連携する
          </p>
          { adminApproval || userType === 'staffs' ? (
            <UserSearch />
          ) : (
            <p className="fw-b">
              アカウント承認完了までお待ちください。
            </p>
          )}
        </div>
      </Can>
      <Button
        classProps="cancelButton block mt-5 ml-auto py-1 px-2"
        text="ログアウト"
        function={layouts.handleLogout}
      />
    </Modal>
  )
}