// Modules
import React, {
  useState
} from "react"
// Components
import { AuthForm } from "./AuthForm"
import { Label } from "../../Atoms/Label"
import { RadioInput } from "../../Atoms/RadioInput"
import { Button } from "../../Atoms/Button"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { AuthFunctions } from "../../../functions/Authentication/AuthFunctions"
// Config
import { roles } from "../../../config/Common/CommonConfig"

export const SignUp: React.VFC = () => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()
  const { auth } = AuthFunctions()
  const [errorMessage, setErrorMessage] = useState<any>({})
  const [value, setValue] = useState<string>('')
  const [postMessage, setPostMessage] = useState<string>('')

  // *************** JSX *************** //
  return (
    <div className="ta-c">
      <h3 className="mt3-per fw-b fs30-px ta-c">
        アカウント登録
      </h3>
      { postMessage &&
        <p className="errorMessage mt2-per">
          { postMessage }
        </p>
      }
      <AuthForm
        component="signUp"
        passwordLabel="パスワード"
        value={value}
        setValue={setValue}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <div className="userTypeWrap w35-rem mt2-per mh-auto ta-c">
        <p>ユーザータイプ</p>
        <ul className="userTypeList dis-f fjc-sb mt1-per">
          { roles.map((role, i) => (
            <li key={i}>
              <Label
                className="dis-inline-flex fai-center p10-px br3-px"
                htmlFor={role.collection}
              >
                <p>{role.label}</p>
                <RadioInput
                  className="cur-p"
                  id={role.collection}
                  name="userType"
                  value={role.collection}
                  function={(e: React.ChangeEvent<HTMLInputElement>) => {
                    commons.checkValidation(
                      true,
                      'userType',
                      'ユーザータイプ',
                      '',
                      e.target.value,
                      '',
                      setErrorMessage,
                      false
                    )
                    commons.setValue(
                      'userType',
                      e.target.value,
                      setValue
                    )
                  }}
                />
              </Label>
            </li>
          ))}
        </ul>
      </div>
      <Button
        classProps={`updateButton
          ${!!Object.values(errorMessage).filter((error: any) => error).length && 'disable'}
          w10-rem mt-5 pv15-px
        `}
        text="登録"
        disabled={!!Object.values(errorMessage).filter((error: any) => error).length}
        function={() => auth.handleSignUp(
          value,
          setPostMessage
        )}
      />
    </div>
  )
}
