// Modules
import React from 'react'
// Styles
import "../../stylesheets/components/Atoms/LoadingTable.scss"

export const LoadingTable: React.VFC = () => (
  // *************** JSX *************** //
  <>
    {/* <div className="flex">
      <div className="movingShade loading-search"></div>
      <div className="movingShade loading-filter"></div>
    </div> */}
    <div className="movingShade loading-table" />
  </>
)
