// Module
import React from 'react'
// Components
import { Label } from '../../components/Atoms/Label'
import { Input } from "../../components/Atoms/Input"
import { Select } from "../../components/Atoms/Select"
// Config
import {
  masterFormList,
  typesOptions
} from "../../config/Master/MasterConfig"
import {
  Controller,
  useFieldArray,
  useForm
} from "react-hook-form"
import {
  postSysKeyForm,
} from "../../functions/Api"
// Stylesheets
import '../../stylesheets/pages/Master/Master.scss'
import { Layouts } from "../../Layouts/Layouts"

// データ移行
// const jsonData = require("../../config/SysKeyForm/SysStaffsForm.json");

export const MasterFormList = (

) => {

  // useForm
  const {
    register,
    handleSubmit,
    errors,
    control
  } = useForm({
    defaultValues: {
      option: ['']
    }
  })


  const {
    fields,
    prepend,
    swap,
    move,
    append,
    remove,
    insert
  } = useFieldArray({
    control,
    name: "option"
  })

  const formSubmit = (data: any) => {
    // postJsonFunc(data)
  }

  const methods = {
    // JSONデータを入れると、postSysKeyFormにデータを転送
    postJsonFunc: (json: any) => {
      let willingToSend = json.map((d: any, i: number) => {
        d.number = i + 1
        d.required = false
        d.validation = { errorMessage: '', regex: '' }
        return d
      }
      )
      let promise: Array<any> = []
      willingToSend.forEach((willing: any, i: number) => {
        promise.push(postSysKeyForm([willingToSend[i]]))
      }
      )
      Promise.all(promise)
    },
  }

  // *************** JSX *************** //

  // return (
  //   <button
  //     onClick={() => {
  //       methods.postJsonFunc(jsonData)
  //     }}> send </button>
  // )

  return (
    <Layouts>
      <main>
        <h2>マスターフォーム作成</h2>
        <form onSubmit={handleSubmit(formSubmit)}>
          <ul>
            { masterFormList.map((list: any, i: number) => (
              <>
                <li key={i}>
                  <Label
                    htmlFor={list.name}
                    text={list.label}
                  />
                  { list.tag === 'select' ? (
                    <Select
                      name="name"
                      label={list.label}
                    />
                  ) : (
                    <Input

                    />
                  )}
                </li>
                {/*{list.name === "tag" && (*/}
                {/*  <>*/}
                {/*    <Label*/}
                {/*      htmlFor="option"*/}
                {/*      text="option値"*/}
                {/*    />*/}
                {/*    { fields.map((f, j) => (*/}
                {/*      <li key={f.id}>*/}
                {/*        <input*/}
                {/*          name={`option${j}`}*/}
                {/*          type="text"*/}
                {/*          ref={register()}*/}
                {/*        />*/}
                {/*        <Controller*/}
                {/*          as={<input />}*/}
                {/*          name={`option[${j}]`}*/}
                {/*          control={control}*/}
                {/*        />*/}
                {/*      </li>*/}
                {/*    ))}*/}
                {/*    <li>*/}
                {/*      <Label*/}
                {/*        htmlFor="type"*/}
                {/*        text="inputタイプ"*/}
                {/*      />*/}
                {/*      {tags.select(*/}
                {/*        "inputタイプ",*/}
                {/*        "type",*/}
                {/*        typesOptions.option*/}
                {/*      )}*/}
                {/*    </li>*/}
                {/*  </>*/}
                {/*)}*/}
              </>
            ))}
          </ul>
          <button type="submit">
            送信
          </button>
        </form>
      </main>
    </Layouts>
  )
}

export default MasterFormList