// Config
import { storage } from "../../config/Common/firebaseConfig"
// Functions
import { postData } from "../Api"
import { CommonFunctions } from "../CommonFunction"

export const SysKeyFunctions = () => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** Functions *************** //
  const functions = {
    /**
     *
     * @param id userID
     * @param data imagesData<Array>
     * @param setState useState
     * @returns {Promise<void>}
     */
    getFileLink: async (
      id: string,
      data: any,
      setState: Function
    ) => {
      const fileLinks = []
      for ( const d of data ) {
        if (d.path) {
          const pathRef = storage.ref()
          const url = await pathRef.child(`User/${id}/${d.name}`).getDownloadURL()
          .catch(err => {
            console.log(err)
            return false
          })
          fileLinks.push(url)
        }
      }
      setState(fileLinks)
    },
    // ファイルが被っているか判断
    checkExist: (
      name: string,
      images: Array<any>
    ) => {
      let flg = false
      for (const img of images) {
        if (img.name === name) {
          flg = true
          break
        }
      }
      return flg
    },
    /**
     *
     * @param e
     * @param fileImage
     * @param setFileImage
     * @param uploadImages
     * @param setUploadImages
     */
    previewImages: async (
      e: any,
      userImages: any,
      fileImage: any,
      setFileImage: Function,
      storageImg: any,
      setStorageImg: Function
    ) => {
      // ファイルデータ格納用配列
      let imagesList = Object.assign([], fileImage)
      let storageImages = Object.assign([], storageImg)
      // 選択した画像一覧
      const { files } = e.target
      let isExist
      //
        // 画像数分処理を回す
        for (const file of files) {
          if (userImages) {
            // ダブりに引っ掛かったらtrueにする
            isExist = functions.checkExist(
              file.name,
              userImages
            )
          }
          if (!isExist) {
            // Storage用画像一覧
            storageImages.push(file)
            // 画像を読み込む
            await commons.insertImages(
              imagesList,
              file
            )
          }
        }
      // setState
      setStorageImg(storageImages)
      setFileImage(imagesList)
    },
    changeLabel: (
      value: string,
      fileImage: any,
      setFileImage: Function,
      i: number
    ) => {
      const newFileImage = Object.assign([], fileImage)
      newFileImage[i].label = value
      setFileImage(newFileImage)
    },
    /**
     *
     * @param i
     * @param type
     * @param state
     * @param setState
     * @param fileImage
     * @param setFileImage
     */
    deleteImage: (
      i: number,
      type: string,
      state: any,
      setState: Function,
      fileImage: any,
      setFileImage: Function
    ) => {
      // if (type === 'imageBtr') {
      //   let newImageBtr = Object.assign(
      //     [],
      //     state.filter((image, idx) => {
      //       if (idx !== i) return image
      //     })
      //   )
      //   setState(newImageBtr)
      //
      //   // inputのアップロードデータ削除
      //   let newFileImage = Object.assign({}, fileImage)
      //   if (newFileImage[i]) delete newFileImage[i]
      //   setFileImage(newFileImage)
      //
      // } else if (type === 'usersDataImages') {
      //   let newUsersDataImages = Object.assign(
      //     [],
      //     state.filter((image, idx) => {
      //       if (idx !== i) return image
      //     })
      //   )
      //   setState(newUsersDataImages)
      // }
    },
    // Validation
    checkValidation: (
      e: any,
      data: any,
      index: number,
      errorMessages: any,
      setErrorMessages: Function
    ) => {
      const v = e.target.value
      const rgx = new RegExp(data.validation.regex)

      let newErrorMessages = Object.assign([], errorMessages)

      if (data.required && (!v || v === '')) {
        newErrorMessages[index] = `${data.label}を入力してください`
      } else if (data.validation && v && !v.match(rgx)) {
        newErrorMessages[index] = data.validation.errorMessage
      } else {
        newErrorMessages[index] = null
      }

      setErrorMessages(newErrorMessages)
    },
    /**
     * GoogleStorageにファイルを保存する
     * @param uId
     * @param storageImg
     * @param images
     * @param array
     */
    saveStorage: async (
      uId: string,
      storageImg: any,
      images: Array<any>,
      array: Array<any>
    ) => {
      const storageRef = storage.ref(`User/${uId}/`)
      // 選択した画像の分だけ処理を回す
      for (const img of storageImg) {
        // storageに保存する
        const uploadTask = await storageRef.child(img.name).put(img)
        // 選択した画像分だけ処理を回す
        for (const image of images) {
          // ファイル名がマッチしなければスキップ
          if (img.name !== image.name) continue
          // ファイルのURLを取得する
          image.url = await uploadTask.ref.getDownloadURL()
          array.push(image)
        }
      }
    },
    /**
     *
     * @param uId
     * @param inputData
     * @param storageImg
     * @param fileImage
     * @param postUrl
     * @param moveUrl
     */
    masterSubmit: async (
      uId: any,
      inputData: any,
      storageImg: any,
      fileImage: any,
      postUrl: string,
      moveUrl: string
    ) => {
      // 画像アップロード
      let newImagesList: Array<any> = []
      // POST用オブジェクト
      let newObj = Object.assign({}, inputData)
      // 画像があったらデータに追加する
      if (storageImg && storageImg.length) {
        // storageにファイルを保存する
        await functions.saveStorage(
          uId,
          storageImg,
          fileImage,
          newImagesList
        )
        // DBにある画像と選択した画像をmergeする
        // オブジェクトを結合する
        newObj.images =　inputData.images && inputData.images.length ?
          inputData.images.concat(newImagesList)
        : newImagesList
      }
      // DBにPOSTする
      await postData(
        {},
        newObj,
        postUrl
      )
      // 詳細ページへ遷移する
      commons.movePage(moveUrl)
    },
    /**
     * 複数選択の値を格納する
     * @param value 複数選択の値
     */
    handleMulti: (
      value: any,
      setState: Function
    ) => {
      // 送り出し機関を格納する
      const obj = {
        agenciesList: value
      }
      console.log(obj)
      // stateに格納する
      setState(obj)
    },
    setStorage: async (
      id: string,
      userType: string,
      admin: boolean,
      step: string,
      data: any,
    ) => {
      // empty string
      let name
      // userName Data
      const {
        [`staff-family`]: family,
        [`staff-first`]: first,
        [`staff-middle`]: middle,
        [`company-name`]: companyName,
        [`agency-name`]: agencyName,
        [`rso-name`]: rsoName,
        [`kumiai-name`]: kumiaiName,
      } = data
      // userName
      userType === 'companies' ?
        name = companyName
      : userType === 'agencies' ?
        name = agencyName
      : userType === 'rsos' ?
        name = rsoName
      : userType === 'kumiai' ?
        name = kumiaiName
      : name = `${family ? family : ''} ${first ? first : ''} ${middle ? middle : ''}`

      // storageにデータを格納する
      await commons.setStorage(
        id,
        userType,
        name,
        admin
      )
    }
  }

  // 外部で使用可能にする
  return {
    functions
  }
}