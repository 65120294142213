// 職歴管理
export const careerHeadFixed = [
  { title: '詳細', sticky: false, isSort: false },
  { title: '名前', sticky: false, isSort: false },
  { title: '現在の職業', sticky: false, isSort: false }
]

export const careerHead = [
  { title: '年齢', sticky: false, isSort: false },
  { title: 'メールアドレス', sticky: false, isSort: false },
]

// テーブルボディ項目
export const careerTbodyFixed = [
  { label: ['staff-family', 'staff-first', 'staff-middle'] },
  { label: 'staff-workNow' }
]

// テーブルボディ項目
export const careerTbody = [
  { label: 'staff-age' },
  { label: 'staff-email' },
]

// 職歴日付
export const careerStart = [
  {
    name: 'startYear',
    placeholder: '開始年',
    dataFormat: 'yyyy',
    yearOnly: true
  },
  {
    name: 'startMonth',
    placeholder: '開始月',
    dataFormat: 'MM',
    monthOnly: true
  }
]

export const careerEnd = [
  {
    name: 'endYear',
    placeholder: '終了年',
    dataFormat: 'yyyy',
    yearOnly: true
  },
  {
    name: 'endMonth',
    placeholder: '終了月',
    dataFormat: 'MM',
    monthOnly: true
  }
]