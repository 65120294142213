// Modules
import firebase from "firebase/app"
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

const firebaseConfig = {
    apiKey: "AIzaSyBguolpiM47o8NkZ7bHa82QfY6g-UbYSBs",
    authDomain: "connect-01-322502.firebaseapp.com",
    projectId: "connect-01-322502",
    storageBucket: "connect-01-322502.appspot.com",
    messagingSenderId: "1092931390406",
    appId: "1:1092931390406:web:83898b09935e05d4bb40c7",
    measurementId: "G-93ZN7G5GB5"
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
export const storage = firebase.storage()
export default firebase