// Modules
import React, {
  useState
} from 'react'
import {
  useMutation,
  useQuery
} from 'react-query'
import Cookie from "js-cookie"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from '../../components/Atoms/LoadingTable'
import { Error } from '../../components/Molecules/Error/Error'
import { List } from "../../components/Molecules/Common/List"
import { OpenButton } from "../../components/Atoms/OpenButton"
import { DownloadButton } from "../../components/Atoms/DownloadButton"
import { SetSyskeyPartner } from "../../components/Molecules/Documents/SetSyskeyPartner"
import { useRecoilValue } from "recoil"
// API
import { getAPI, postAPI } from '../../functions/Api'
// Functions
import { DocumentFunctions } from "../../functions/Document/DocumentFunctions"
// Config
import {
  companiesWhereRsos,
  POLODocURL,
  postUserURL
} from '../../config/Common/apiURL'
import { visaList } from "../../config/Document/DocumentConfig"
import { storageState } from "../../config/Common/RecoilState"
// Styles
import '../../stylesheets/pages/Documents/PoloDocument.scss'

export const POLODocuments: React.VFC = () => {
  // *************** Const *************** //
  const { functions } = DocumentFunctions()
  const { uId, userType } = useRecoilValue(storageState)
  // const local = JSON.parse(Cookie.get("__session") as string)
  const [isVisa, setIsVisa] = useState<number>()

  // *************** React Query *************** //
  const apis = async () => {
    const companies = await getAPI(`${companiesWhereRsos}/${uId}/companiesList`)
    const agencies = await getAPI(`${companiesWhereRsos}/${uId}/agenciesList`)
    const poloDoc = await getAPI(POLODocURL)
    return {
      companies,
      agencies,
      poloDoc
    }
  }
  // 全てのAPIを叩く
  const {
    isLoading,
    isError,
    data
  } = useQuery(
    "POLODocuments",
    async () => apis()
  )

  // useMutation
  const mutation = useMutation(data => postAPI(
    `${postUserURL}/${userType}/${uId}`,
    data
  ), {
    onError: async () => { console.log('更新に失敗しました。') },
    onSuccess: async () => { console.log('更新成功！！') }
  })

  // *************** List *************** //
  const types = {
    /**
     * 就労ビザ一覧
     * @returns {JSX.Element}
     */
    visa: () => (
      <ul className="visaCategory mt2-per ml3-per br5-px">
        { visaList.map((visa: any, i: number) => (
          <List
            index={i}
            className="visaList p3-per br5-px"
            function={() => functions.openFirst(
              i,
              setIsVisa
            )}
          >
            <h3 className="fw-b fs20-px">
              {visa.title}
            </h3>
            { types.documents(
              visa,
              i
            )}
          </List>
        ))}
      </ul>
    ),
    /**
     * 申請書一覧
     * @param visa
     * @param i
     * @returns {JSX.Element}
     */
    documents: (
      visa: any,
      i: number
    ) => {
      return (
        <ul
          className={`allDocuments ml2-per
            ${isVisa === i ? "open" : ""}
          `}
        >
          { data && data.poloDoc.sort((a: any, b: any) => (
            a.categoryNumber - b.categoryNumber
          )).map((doc: any, j: number) => visa.label === doc.visa && (
            <List
              index={j}
              className="docList mt2-per br3-px"
            >
              { doc.sysKeys.length ? (
                <OpenButton
                  className="p2-per br3-px fw-b wb-ba"
                  href={`POLODocuments/${doc.visa}/${uId}/ph/${doc.dId}`}
                  text={doc.documentName}
                />
              ) : (
                <DownloadButton
                  className="p2-per br3-px fw-b wb-ba"
                  href={doc.url}
                  text={doc.documentName}
                />
              )}
            </List>
          ))}
        </ul>
      )
    }
  }

  // *************** JSX *************** //
  return (
    <Layouts>
      <main>
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <div className="poloDocContent flex justify-between h-full">
            <div className="poloDocContent-main flex flex-col flex-1">
              {/* VISA一覧 */}
              <div className="documentWrap flex flex-col mt-3 p-3">
                <h2 className="font-bold text-lg">
                  POLO申請書
                </h2>
                { types.visa() }
              </div>
            </div>
            {/* パートナー選択 */}
            <SetSyskeyPartner
              companies={data?.companies}
              agencies={data?.agencies}
              mutation={mutation}
            />
          </div>
        )}
      </main>
    </Layouts>
  )
}

export default POLODocuments