//  Modules
import React, {
  useEffect,
  useState
} from "react"
// Functions
import { DocumentFunctions } from "../../../functions/Document/DocumentFunctions"
// Stylesheets
import '../../../stylesheets/pages/Documents/Document.scss'

// *************** Type *************** //
type Props = {
  docHtml: any
  setSysInput: Function
  docData?: any
  sysData?: any
  setDocInput: Function
}

export const WordFormComponent: React.VFC<Props> = (
  props: Props
) => {

  // *************** Const *************** //
  // 外部の関数用ファイル
  const { functions } = DocumentFunctions()
  
  // *************** State *************** //
  const [blushHtml, setBlushHtml] = useState<string>("")

  // 関数発火に順番づける
  const asyncFunctions = async () => {
    // 表示用HTMLを格納する
    await functions.loadHtml(
      props.docHtml,
      setBlushHtml
    )
    // sysKeyクラスを付与する
    await functions.inputAttribute(
      props.sysData,
      props.setDocInput,
    )
    // sysKeyを入れる
    await functions.addSysWord(
      props.docData,
      props.sysData
    )
    // 全inputタグのvalueをオブジェクト化
    await functions.setValue(props.setSysInput)
  }
  
  // *************** UseEffect *************** //
  // 申請書の書類名が変化したら発火させる挙動
  useEffect(() => {
    asyncFunctions()
  }, [])

  // *************** JSX *************** //
  return (
    <div className="wordFormComponent">
      <div className="form">
        <div
          className="htmlForm"
          dangerouslySetInnerHTML={{ __html: blushHtml }}
        />
      </div>
    </div>
  )
}