// パンくずリスト英語 - 日本語
export const TabMenuOptions: {
  [key in string]: string
} = {
  "admin": "管理者",
  "staffs": "候補者",
  "companies": "企業",
  "agencies": "送り出し機関",
  "rsos": "登録支援機関",
  "kumiai": "組合",
  "Document": "申請書",
  "DocumentCatalog": "申請書",
  "POLODocuments": "POLO申請書",
  "ChangeSysKey": "マスターデータ登録",
  "other": "その他",
  "PersonalDocuments": "個別申請書一覧",
  "DocTemplateForm": "申請書テンプレ作成",
  "DocumentForm": "申請書フォーム",
  "ChangeUserMaster": "hoge",
  "Detail": "詳細",
  "MasterForm": "hoge",
  "CareerCatalog": "hoge",
  "DetailsStatus": "hoge",
  "apply": "応募",
  "interview": "面接",
  "recruit": "内定",
  "before": "就労前",
  "after": "就労後",
  "return": "帰国",
  "external": "外部就労",
  "EnterStatus": "就労前ステータス",
  "EnteredStatus": "就労後ステータス",
  "ApprovalCompany": "企業承認",
  "Interview": "面接",
  "Apply": "応募",
  "Immigration": "就労ステータス",
  "Post": "投稿",
  "Career": "職歴",
  "QuarterlyReport": "四半期報告書",
  "specificSkill": "特定技能",
  "SpecifiedSkill": "特定技能",
  "skillTraining": "技能実習",
  "Trainee": "技能実習",
  "HiredInfo": "利用企業一覧",
  "mm": "ミャンマー",
  "vn": "ベトナム",
  "ph": "フィリピン"
}

// ユーザータイプ
export const roles = [
  { collection: 'staffs', label: '候補者' },
  { collection: 'companies', label: '企業' },
  { collection: 'agencies', label: '送り出し機関' },
  { collection: 'rsos', label: '登録支援機関' },
  { collection: 'kumiai', label: '監理団体' }
]