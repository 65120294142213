// Modules
import React, {
  useEffect,
  useMemo,
  useState
} from "react"
import {
  useMutation,
  useQuery,
  useQueryClient
} from "react-query"
import { useRecoilValue } from "recoil"
import { useParams } from "react-router-dom"
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useTable
} from "react-table"
import { useSticky } from "react-table-sticky"
// Functions
import {
  getAPI,
  postAPI
} from "../../functions/Api"
import { CommonFunctions } from "../../functions/CommonFunction"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { Table } from "../../components/Molecules/Common/Table"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import Error from "../../components/Molecules/Error/Error"
import { CareerModal } from "../../components/Molecules/Career/CareerModal"
// Config
import {
  dynamicArrayURL,
  dynamicGet
} from "../../config/Common/apiURL"
import {
  modalState,
  storageState
} from "../../config/Common/RecoilState"
// Stylesheets
import '../../stylesheets/pages/Career.scss'

// *************** Type *************** //
type Props = {
  getUrl: string
  queryKey: string
  page?: string
  userType?: string
}

export const CareerCatalog: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { cId } = useParams<Record<any, any>>()
  const isModal = useRecoilValue(modalState)
  const { admin, uId, userType } = useRecoilValue(storageState)
  const [dataState, setDataState] = useState<Array<any>>([])
  const [careerList, setCareerList] = useState<Array<any>>([])
  const [modalData, setModalData] = useState<any>({})
  const [modalPosY, setModalPosY] = useState<number>(0)
  const { commons } = CommonFunctions()
  // *************** React Query *************** //
  const promises = async () => {
    //
    const staffData = await getAPI(
      cId ? `${props.getUrl}/${cId}`
      : userType === 'companies' ? `${props.getUrl}/${uId}`
      : props.getUrl
    )
    //
    const careerData = await getAPI(`${dynamicGet}/career`)
    // 外部で使用可能にする
    return {
      staffData,
      careerData
    }
  }

  const {
    isFetching,
    isLoading,
    isError,
    data: getData
  } = useQuery(
    "careerStaffs",
    async () => promises()
  )

  const queryClient: any = useQueryClient()
  const mutation = useMutation(data => postAPI(
    `${dynamicArrayURL}/staffs/career/careerList`,
    data
  ), {
    onError: async () => console.log('更新に失敗しました。'),
    onSuccess: async () => {
      // キャッシュデータを再度取得して更新
      await queryClient.fetchQuery("careerStaffs")
      console.log('更新に成功しました。')
    }
  })

  useEffect(() => {
    if (isLoading) return
    // else setDataState([])
    else setDataState(getData?.staffData)
  }, [isFetching])

  const [columns, data] = useMemo(
    () => {
      const columns = [
        {
          width: 90,
          Header: '職歴',
          Cell: (cell: any) => (
            <p
              className="rounded-full bg-skyBlue text-white font-bold text-sm cursor-pointer"
              onClick={() => {
                commons.openModal()
                setModalPosY && setModalPosY(64 * (cell.row.index + 1))
                commons.filterArray(
                  dataState[cell.row.index].id,
                  queryClient.getQueryData("careerStaffs").careerData,
                  setCareerList
                )
                setModalData({ ...dataState[cell.row.index] })
              }}
            >
              記入
            </p>
          ),
          sticky: 'left'
        },
        {
          width: 200,
          Header: '名前',
          accessor: (data: any) =>
            data['staff-middle'] ? `${data['staff-first']} ${data['staff-family']} ${data['staff-middle']}`
            : data['staff-first'] ? `${data['staff-first']} ${data['staff-family']}`
            : '',
          sticky: 'left',
        },
        {
          width: 200,
          Header: '現在の職業',
          accessor: 'staff-workNow',
        },
        {
          width: 200,
          Header: '年齢',
          accessor: 'staff-age',
        }
      ]
      return [columns, dataState]
    },
    [dataState]
  )
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSticky,
    useBlockLayout,
    useFilters,
    useGlobalFilter
  )

  // *************** JSX *************** //
  return (
    <Layouts>
      <main>
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <Table
            {...tableInstance}
            className="h-full"
            sticky={true}
          />
        )}
        { isModal && (
          <CareerModal
            modalData={modalData}
            setModalData={setModalData}
            mutation={mutation}
            careerList={careerList}
            setCareerList={setCareerList}
          />
        )}
      </main>
    </Layouts>
  )
}

export default CareerCatalog