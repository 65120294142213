// Modules
import { useRecoilState } from "recoil"
// Config
import { accountModal } from "../../config/Common/RecoilState"
import firebase from "../../config/Common/firebaseConfig"

export const LayoutsFunctions = () => {
  // *************** Const *************** //
  const [isAccount, setIsAccount] = useRecoilState(accountModal)

  // *************** Functions *************** //
  const layouts = {
    /**
     * ログアウト
     */
    handleLogout: async () => {
      // firebaseからlogoutする
      await firebase.auth().signOut()
      console.log('Bye!!!')
      // ローカルストレージのデータを削除する
      localStorage.removeItem("user")
      // Cookies.remove("__session")
      // 自動でリロードする
      window.location.replace('/')
    },
    /**
     * モーダルを表示する
     */
    openModal: () => setIsAccount(true),
    /**
     * モーダルを表示する
     */
    closeModal: () => setIsAccount(false),
  }
  return { layouts }
}