// Modules
import React from "react"
import { Link } from "react-router-dom"
// Components
import { Button } from "../../Atoms/Button"
import { Modal } from "../../Atoms/Modal"
import { Label } from "../../Atoms/Label"
import { Select } from "../../Atoms/Select"
import { Checkbox } from "../../Atoms/Checkbox"
import { JobOfferList } from "./JobOfferList"
// Functions
import {
  CommonFunctions,
  translateFunc
} from "../../../functions/CommonFunction"
// Config
import { Can } from "../../../config/Common/Can"
import { immigrationOptions } from "../../../config/Master/MasterConfig"

type Props = {
  modalPosY?: any
  modalData?: any
  setModalType: Function
  roleData?: any
  buttonDefault?: any
  buttonText?: string
  buttonValue?: any
  dataState?: any
  setDataState: Function
  apply: boolean
  status: boolean
  memo: boolean
  jobOffer: boolean
}

export const DetailModal = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** JSX *************** //
  return (
    <Modal modalFunction={commons}>
      <ul className="modalList absolute left-28 p-8" style={{ top: `${props.modalPosY}px` }}>
        { props.status && (
          <li>
            <p className="font-bold text-center">
              { props.dataState[props.modalData.index].immigration ?
                translateFunc(props.dataState[props.modalData.index].immigration)['TabMenu']
                : '応募なし'
              }
            </p>
            { props.dataState[props.modalData.index].immigration && (
              <Can
                I="read"
                a="immigration"
              >
                <div className="inline-flex flex-col">
                  <Label
                    className="inline-block font-bold"
                    htmlFor="immigration"
                    text="就労ステータス"
                  />
                  <Select
                    name="immigration"
                    label="就労ステータス"
                    value={props.dataState[props.modalData.index].immigration}
                    options={immigrationOptions}
                    optionKey="label"
                    optionValue="value"
                    function={(e: React.ChangeEvent<HTMLSelectElement>) => commons.arraySetValue(
                      props.modalData.index,
                      e.target.name,
                      e.target.value,
                      props.dataState,
                      props.setDataState
                    )}
                  />
                </div>
              </Can>
            )}
          </li>
        )}
        { props.jobOffer && (
          <JobOfferList
            id={props.modalData.id}
            cId={props.modalData.companies}
          />
        )}
        { props.memo && (
          <li className="mt-2">
            <Button
              classProps="careerUpdateButton py-1 px-2"
              text="ユーザーメモ"
              function={() => props.setModalType("resume")}
            />
          </li>
        )}
        <li className="mt-2">
          <Link
            className="cancelButton py-1 px-2 text-white visited:text-white"
            to={`/${props.roleData}/detail/${props.dataState[props.modalData.index].id}`}
            onClick={() => {
              commons.closeModal()
              props.setModalType('detail')
            }}
          >
            個別ページへ
          </Link>
        </li>
        { props.apply && (
          <Can
            I="read"
            a={props.buttonValue}
          >
            <li>
              <Label
                className="font-bold fs1-rem"
                htmlFor="nowStatus"
                text={props.buttonText}
              />
              <div className="toggleButton">
                <Checkbox
                  name="nowStatus"
                  checked={props.dataState[props.modalData.index].nowStatus === props.buttonValue}
                  value={props.dataState[props.modalData.index][props.buttonValue]}
                  function={(e: React.ChangeEvent<HTMLInputElement>) => commons.arraySetValue(
                    props.modalData.index,
                    e.target.name,
                    e.target.checked ? props.buttonValue : props.buttonDefault,
                    props.dataState,
                    props.setDataState
                  )}
                />
                <div className="squareWrap">
                  <span className="square">OFF</span>
                </div>
                <div className="layer"/>
              </div>
            </li>
          </Can>
        )}
        <Can
          I="read"
          a="delete_flg"
        >
          <li className="flex flex-col">
            <Label
              className="font-bold text-md"
              htmlFor="delete_flg"
              text="削除"
            />
            <div className="toggleButton">
              <Checkbox
                name="delete_flg"
                checked={!!props.dataState[props.modalData.index].delete_flg}
                value={props.dataState[props.modalData.index].delete_flg}
                function={(e: React.ChangeEvent<HTMLInputElement>) => commons.arraySetValue(
                  props.modalData.index,
                  e.target.name,
                  e.target.checked,
                  props.dataState,
                  props.setDataState
                )}
              />
              <div className="squareWrap">
                <span className="square">OFF</span>
              </div>
              <div className="layer"/>
            </div>
          </li>
        </Can>
      </ul>
    </Modal>
  )
}