// Modules
import React from "react"
import { Link } from "react-router-dom"
// FontAwesome
import {
  faPassport,
  faUniversity,
  faComment,
  faPlane,
  faClipboard
} from "@fortawesome/free-solid-svg-icons"
import { Can } from "../../../config/Common/Can"
import { adminMenu } from "../../../config/Layouts/LayoutsConfig"

// *************** Type *************** //
type Props = {
  admin: boolean
  user: string
  uId: string
  adminApproval: boolean
}

export const PageMenu: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const menuList = props.admin ? adminMenu : !props.adminApproval ? [
    {
      icon: faUniversity,
      list: "人材管理",
      link: props.user === 'companies' ? `/staffs/${props.uId}` : `/staffs/${props.user}`,
      can: 'masterTop'
    }
  ] : [
    {
      icon: faPassport,
      list: "申請書",
      link: props.user === 'companies' ? `/document/${props.uId}` : `/document/${props.user}`,
      can: 'documentTop'
    },
    {
      icon: faUniversity,
      list: "人材管理",
      link: props.user === 'companies' ? `/staffs/${props.uId}` : `/staffs/${props.user}`,
      can: 'masterTop'
    },
    {
      icon: faPlane,
      list: "ステータス",
      link: props.user === 'companies' ? `/SpecifiedSkill/enterStatus/${props.uId}`
      : `/SpecifiedSkill/enterStatus/${props.user}`,
      can: 'statusTop'
    },
    {
      icon: faComment,
      list: "面接",
      link: props.user === 'companies' ? `/interviewStaffs/${props.uId}`
      : `/interviewStaffs/${props.user}`,
      can: 'interviewTop'
    },
    // {
    //   icon: faCommentSmile,
    //   list: "投稿",
    //   link: `/post/${props.user}`,
    //   can: 'postTop'
    // },
    {
      icon: faClipboard,
      list: "職歴",
      link: props.user === 'companies' ? `/career/${props.uId}` : `/career/${props.user}`,
      can: 'careerTop'
    }
  ]

  // *************** JSX *************** //
  return (
    <section className="menuWrap">
      <h2 className="font-bold text-2xl">
        メニュー
      </h2>
      <ul className="menuLists flex wrap mt-2">
        { menuList.map((menu: any, i: number) => (
          <Can
            I="read"
            a={menu.can}
            key={i}
          >
            <li
              className={`menuList menuList-${i}
                text-white
              `}
            >
              <Link
                className="font-bold"
                to={menu.link}
              >
                <p className="menuList_link bold">
                  {menu.list}
                </p>
              </Link>
            </li>
          </Can>
        ))}
      </ul>
    </section>
  )
}