// Modules
import React from 'react'
import { NavLink } from 'react-router-dom'
// Config
import { Can } from "../../../config/Common/Can"

// *************** Type *************** //
type Props = {
  admin?: boolean
  tab: Array<any>
  selectUser?: any
  tabAbility?: string | undefined
  cId?: any
  uId?: any
  userType: string
  userId?: string
  history?: string
  params?: string
}

export const TabMenu: React.VFC<Props> = (
  props: Props
) => {
  // *************** JSX *************** //
  return (
    <nav className="tabMenu">
      { props.tab.map((tab: any, i: number) => tab.can ? (
        <Can
          I="read"
          a={tab.can}
        >
          <NavLink
            key={i}
            className="tabList p1-rem"
            to={tab.path}
          >
            {tab.text}
          </NavLink>
        </Can>
      ) : props.admin ? (
        <NavLink
          key={i}
          className="tabList p1-rem"
          to={tab.path}
        >
          {tab.text}
        </NavLink>
      ) : props.cId === props.uId ? (
        <NavLink
          key={i}
          className="tabList p1-rem"
          to={`${tab.path}/${props.cId}`}
        >
          {tab.text}
        </NavLink>
      ) : props.userId ? (
        <NavLink
          key={i}
          className="tabList p1-rem"
          to={`${tab.path}/${props.userType}/${props.selectUser}/${props.userId}`}
        >
          {tab.text}
        </NavLink>
      ) : (
        <NavLink
          key={i}
          className="tabList p1-rem"
          to={`${tab.path}/${props.userType}/companies/${props.cId}`}
        >
          {tab.text}
        </NavLink>
      ))}
    </nav>
  )
}