// Modules
import React from 'react'
import { Link } from "react-router-dom"
// Components
import { HelpProvider } from "../Common/HelpProvider"
// Config
import { Can } from "../../../config/Common/Can"
import { Subject } from "@casl/ability"

// *************** Type *************** //
type Props = {
  classProps?: string
  title: string
  link: string
  linkText: string
  helpContent?: any
}

export const StatusTop: React.VFC<Props> = (
  props: Props
) => {
  // *************** Components *************** //
  const HelpContent: JSX.Element = (
    <div className="StatusTopHelp">
      { props.helpContent.map((d: any) => (
        <div className="flex m8-px">
          <div className={`${d.className} mr8-px`} />
          <h3 className="fs16-px">{d.text}</h3>
        </div>
      ))}
    </div>
  )

  // *************** JSX *************** //
  return (
    <div className="flex items-center mb1-per StatusTop">
      <div className="flex">
        <h2 className="font-bold fs30-px">
          {props.title}
        </h2>
        <HelpProvider
          className="ml1-rem"
          isUseChildElement={false}
          title={"このページのヘルプ"}
          content={HelpContent} />
      </div>
      <Can
        I="read"
        a="changeStatusButton"
      >
        <Link
          className="updateButton mh-auto pv1-per ph5-per w30-per"
          to={props.link}
        >
          {props.linkText}
        </Link>
      </Can>
    </div>
  )
}