// Modules
import React, {
  useState
} from "react"
import { useQuery } from "react-query"
import { useRecoilValue } from "recoil"
// API
import { getAPI } from "../../functions/Api"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import { PageMenu } from "../../components/Molecules/Top/PageMenu"
import { CountUsers } from "../../components/Molecules/Top/CountUsers"
import { CountClient } from "../../components/Molecules/Top/CountClient"
// Config
import { topURL } from "../../config/Common/apiURL"
import { storageState } from "../../config/Common/RecoilState"
import { Can } from "../../config/Common/Can"
// stylesheets
import "../../stylesheets/pages/Top/Top.scss"

export const Top: React.VFC = () => {
  // *************** UseQuery *************** //
  const {
    admin,
    userType,
    uId,
    adminApproval
  } = useRecoilValue(storageState)

  // *************** UseQuery *************** //
  const {
    isLoading,
    isError,
    data
  } = useQuery (
    "top",
    async () => getAPI(topURL)
  )
  // *************** state *************** //
  const [openNews, setOpenNews] = useState(false);

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="topPage">
        <div className="pageContents">
          <PageMenu
            admin={admin}
            user={userType}
            uId={uId}
            adminApproval={adminApproval}
          />
          { admin && isLoading ? (
            <LoadingTable />
          ) : admin && isError ? (
            <p>データの取得に失敗しました。。。</p>
          ) : admin && (
            <CountUsers
              data={data}
            />
          )}
          { adminApproval && (
            <Can
              I="read"
              a="countClient"
            >
              <CountClient
                link={ admin ? `/ClientList`
                  : '/ClientList/rsos'
                }
              />
            </Can>
          )}

          {/* <section className="pageContents_flex flex wrap">
            <div className="pageContents-wrap contents_apply shadow">
              <div className="pageContents-icon flex">
                <FontAwesomeIcon icon={faIdCard} />
              </div>
              <h5>今日の申請数</h5>
              <div className="wrapContent flex">
                <h2>
                  {data ? data.todayApplicationsNumber : "0"}
                </h2>
                <p className="font_12 bold">件</p>
              </div>
              <p className="font_10 bold">※ キャンセルなども含む</p>
            </div>

            <div className="pageContents-wrap contents_deadline shadow">
              <div className="pageContents-icon flex">
                <FontAwesomeIcon icon={faAlarmClock} />
              </div>
              <h5>締め切り</h5>
              <div className="wrapContent flex">
                <h2>3</h2><p className="font_12 bold">件</p>
              </div>
              <p className="font_10 bold">※ 締め切りは1週間以内</p>
            </div>

            <div className={`${openNews ? "open" : ""} pageContents-wrap contents_news shadow`}>
              <div className="flex space-between">
                <h4>お知らせ</h4>
                { openNews &&
                  <div className="closeButton" onClick={() => setOpenNews(!openNews)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                }
              </div>
              <div className="wrapContent">
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
                <p className="font_14">テキストテキストテキストテキストテキストテキストテキスト</p>
              </div>
              { !openNews &&
                <p
                  className="moreButton font_12 bold"
                  onClick={() => setOpenNews(!openNews)}
                >
                  もっと見る
                </p>
              }
            </div>
          </section> */}
        </div>
      </main>
    </Layouts>
  )
}

export default Top