// Modules
import React from 'react'
// Components
import { Modal } from "../../Atoms/Modal"
import { Button } from "../../Atoms/Button"
import { Textarea } from "../../Atoms/Textarea"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { StatusFunctions } from "../../../functions/Status/StatusFunctions"

// *************** Type *************** //
type Props = {
  modalData?: any
  statusData?: any
  staffData?: any
  setStaffState: Function
  setStatusState: Function
}

export const MemoModal: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { functions } = StatusFunctions()
  
  // *************** JSX *************** //
  return (
    <Modal
      className="memoOverLay fixed top-1/2
        left-1/2 z60 transform -translate-y-1/2 -translate-x-1/2 w-2/5 h-1/2 rounded-md
      "
      modalFunction={functions}
    >
      <div className="memoWrap flex flex-col h-full p8-per br8-px">
        <h3 className="memoTitle pb1-per fw-b fs20-px">
          {props.modalData.label}メモ
        </h3>
        <div className="memoContents flex flex-col justify-between h-full ">
          <div className="memoDetail pv3-per">
            <p> ユーザ名: {props.modalData.userName}</p>
            <p> 項目: {props.modalData.label}</p>
          </div>
          <Textarea
            className="memoInput w-full p-1 rounded-md"
            name="memo"
            value={props.statusData[props.modalData.sIndex].memo}
            function={(e: React.ChangeEvent<HTMLTextAreaElement>) => functions.handleSelectForState(
              props.modalData.title,
              e,
              props.modalData.uIndex,
              props.modalData.sIndex,
              props.staffData,
              props.statusData,
              props.setStaffState,
              props.setStatusState,
            )}
          />
          <Button
            classProps="cancelButton block mh-auto mt5-per
              pv10-px ph5-rem
            "
            text="閉じる"
            function={functions.closeModal}
          />
        </div>
      </div>
    </Modal>
  )
}