// Modules
import { useRecoilState } from "recoil"
// Config
import { memoModal } from "../../config/Common/RecoilState"

export const StatusFunctions = () =>  {
  // *************** useState *************** //
  const [isMemo, setIsMemo] = useRecoilState(memoModal)

  // *************** Functions *************** //
  const functions = {
    /**
     * selectタグ用のonChange関数
     * @param {*} title ステータスデータの項目名
     * @param {*} e インプット情報
     * @param {*} index テーブルの行インデックス
     * @param {*} sIndex テーブルの列インデックス
     * @param {*} staffState 候補者データ
     * @param {*} statusState ステータスデータ
     * @param {*} setStaffState 候補者データ用state
     * @param {*} setStatusState ステータスデータ用state
     */
    handleSelectForState: (
      title: string,
      e: any,
      index: number,　
      sIndex: number,
      staffState: Function,
      statusState: Function,
      setStaffState: Function,
      setStatusState: Function
    ) => {
      const {
        name,
        value
      } = e.target

      if (sIndex || sIndex === 0) {
        let newObj: any = Object.assign([], statusState)
        newObj[sIndex][name] = value
        newObj[sIndex].update_flg = true
        console.log(newObj)
        setStatusState(newObj)
      } else {
        let newObj: any = Object.assign([], staffState)
        newObj[index][name] = value
        newObj[index].update_flg = true
        console.log (newObj)
        setStaffState(newObj)
      }
    },
    /**
     * データ更新
     * @param staffDataState 候補者データ
     * @param statusDataState ステータスデータ
     * @param mutation 更新非同期処理
     */
    save: (
      staffDataState: any,
      statusDataState: any,
      mutation: any
    ) => {
      let uData: Array<any> = []
      // アップデートがあったら、uDataに格納
      staffDataState.forEach((d: any) => {
        if (d["updateObj"]) {
          d["updateObj"].forEach((h: any) => {
            uData.push(
              {
                id: d.id,
                [h]: d[h],
                visa: d.visa
              }
            )
          })
        }
      })
      statusDataState.forEach((d: any) => {
        if (d["updateObj"]) {
          d["updateObj"].forEach((h: any) => {
            uData.push(
              {
                id: d.id,
                title: d.title,
                [h]: d[h],
              }
            )
          })
        }
      })
      console.log(uData)
      // 更新非同期処理を行う
      mutation.mutate(uData)
    },
    /**
     * モーダルを表示する（データ込み）
     * @param uIndex ユーザーのデータインデックス（テーブル行）
     * @param sIndex ステータスのデータインデックス
     * @param staff 全ユーザーデータ
     * @param status 全ユーザーの各ステータス
     * @param setModalData モーダル表示用データ格納state
     */
    launchMemo: (
      uIndex: number,
      sIndex: number,
      staff: any,
      status: any,
      setModalData: Function
    ) => {
      // モーダルを表示
      setIsMemo(true)
      // ユーザー各データ
      const { id } = staff
      // ステータス各データ
      const {
        title,
        label
      } = status
      // フルネーム
      const firstName = staff["staff-first"] ? staff["staff-first"] : ''
      const familyName = staff["staff-family"] ? staff["staff-family"] : ''
      const middleName = staff["staff-middle"] ? staff["staff-middle"] : ''
      const fullName = `${familyName} ${middleName} ${firstName}`
      // モーダル表示用データ
      setModalData({
        uIndex: uIndex,
        sIndex: sIndex,
        id: id,
        userName: fullName,
        title: title,
        label: label
      })
    },
    closeModal: () => setIsMemo(false)
  }

  // 外部で使用可能にする
  return {
    functions
  }
}
