// Modules
import React, {
  useState
} from "react"
import { useMutation } from "react-query"
// Components
import { AuthForm } from "./AuthForm"
import { Button } from "../../Atoms/Button"
// Functions
import { AuthFunctions } from "../../../functions/Authentication/AuthFunctions"
import { postAPI } from "../../../functions/Api"
// Config
import { sendEmailURL } from "../../../config/Common/apiURL"

// *************** Type *************** //
type Props = {
  signOn: any
  setSignOn: Function
  forgetOn: any
  setForgetOn: Function
}

export const Login: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { auth } = AuthFunctions()
  const [errorMessage, setErrorMessage] = useState<any>({})
  const [value, setValue] = useState<string>('')
  const [postMessage, setPostMessage] = useState<string>('')

  // *************** JSX *************** //
  return (
    <div className="p-3 text-center">
      <h3 className="mt-3 font-bold fs30-px text-center">
        ログイン
      </h3>
      { postMessage &&
        <p className="errorMessage mt2-per">
          { postMessage }
        </p>
      }
      <AuthForm
        component="login"
        passwordLabel="パスワード"
        value={value}
        setValue={setValue}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <Button
        classProps={`updateButton
          ${!!Object.values(errorMessage).filter((error: any) => error).length && 'disable'}
          w10-rem mt-5 pv15-px
        `}
        text="ログイン"
        disabled={!!Object.values(errorMessage).filter((error: any) => error).length}
        function={() => auth.handleLogin(
          value,
          setPostMessage
        )}
      />
      <div className="mt2-rem text-center">
        <p
          className="authLink dis-inline-block cur-p"
          onClick={() => auth.changeForget(
            props.setSignOn,
            props.setForgetOn
          )}
        >
          パスワードをお忘れですか？
        </p>
        <p>
          アカウントをお持ちですか？
          <span
            className="authLink cur-p"
            onClick={() => auth.changeSignUp(
              props.signOn,
              props.setSignOn,
              props.forgetOn,
              props.setForgetOn
            )}
          >
            アカウントを作成する
          </span>
        </p>
      </div>
    </div>
  )
}