// Modules
import React, { useState } from 'react'
// Components
import { Label } from '../../Atoms/Label'
import { Input } from '../../Atoms/Input'
import { Select } from "../../Atoms/Select"
import { Button } from "../../Atoms/Button"
// API
import { deleteData }  from "../../../functions/Api"
// Config
import { deleteDocTemplate } from "../../../config/Common/apiURL"
import { languageList } from "../../../config/Document/DocumentConfig"
// Function
import { CommonFunctions } from "../../../functions/CommonFunction"

export const DocDeleteForm: React.VFC = () => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** State *************** //
  const [inputValue, setInputValue] = useState<any>({})

  // *************** JSX *************** //
  return (
    <div>
      <h2 className="font-bold text-2xl">テンプレ削除</h2>
      <div>
        <Label
          className="font-bold text-lg"
          htmlFor="dId"
          text="申請書ID（申請書名　英語）"
        />
        <Input
          className="ml-2 p-1 bg-blue-100"
          name="dId"
          type="text"
          placeholder="申請書ID"
          value={inputValue.dId}
          function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setValue(
            e.target.name,
            e.target.value,
            setInputValue
          )}
        />
      </div>
      <div>
        <Label
          className="font-bold text-lg"
          htmlFor="language"
          text="言語"
        />
        <Select
          className="ml-2 p-1 bg-blue-100"
          name="language"
          label="言語"
          value={inputValue.language}
          options={languageList}
          optionKey="text"
          optionValue="type"
          function={(e: React.ChangeEvent<HTMLSelectElement>) => commons.setValue(
            e.target.name,
            e.target.value,
            setInputValue
          )}
        />
      </div>
      <Button
        classProps="cancelButton mt-3 py-2 px-5"
        text="削除する"
        function={() => deleteData(
          {},
          inputValue,
          deleteDocTemplate
        )}
      />
    </div>
  )
}