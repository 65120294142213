// Functions
import { translateFunc } from "../../functions/CommonFunction"

export const immigrationOptions = [
  {
    label: "就労前",
    value: "before"
  },
  {
    label: "就労後",
    value: "after"
  },
  {
    label: "帰国",
    value: "return"
  },
  {
    label: "外部就労",
    value: "external"
  }
]

export const masterFormList = [
  {
    name: "name",
    label: "name属性名",
    tag: "input",
    option: "text",
    validation: ""
  },
  {
    name: "label",
    label: "ラベル名",
    tag: "input",
    option: "text",
    validation: ""
  },
  {
    name: "tag",
    label: "タグの種類",
    tag: "select",
    option: [
      "select",
      "input"
    ]
  },
  {
    name: "data",
    label: "データタイプ",
    tag: "select",
    option: [
      "string",
      "number",
      "timestamp"
    ]
  },
  {
    name: "error",
    label: "エラーメッセージ",
    tag: "input",
    option: "text"
  },
  {
    name: "validation",
    label: "バリデーション",
    tag: "input",
    option: "text"
  },
  {
    name: "placeholder",
    label: "具体例",
    tag: "input",
    option: "text"
  },
]

export const typesOptions = {
  option: [
    "text",
    "checkbox",
    "number",
    "tel",
    "email"
  ]
}

// 就労ビザのタイプ
export const visaType = [
  { text: '特定技能', value: 'specificSkill' },
  { text: '技能実習', value: 'skillTraining' }
]

export const tabMenu = [
  {
    can: 'staffsTab',
    prefix: 'fab',
    icon: 'users',
    text: '候補者',
    path: 'staffs'
  },
  {
    can: 'companiesTab',
    prefix: 'fab',
    icon: 'building',
    text: '企業',
    path: 'companies'
  },
  {
    can: 'agenciesTab',
    prefix: 'fab',
    icon: 'synagogue',
    text: '送り出し機関',
    path: 'agencies'
  },
  {
    can: 'rsosTab',
    prefix: 'fab',
    icon: 'city',
    text: '登録支援機関',
    path: 'rsos'
  },
  // {
  //   can: 'kumiaiTab',
  //   prefix: 'fab',
  //   icon: 'gopuram',
  //   text: '監理団体',
  //   path: 'kumiai'
  // }
]

// 候補者
export const staffsTheadFixed = [
  { title: "詳細", sticky: true, isSort: false },
  { title: "姓", sticky: true, isSort: false },
  { title: "名", sticky: true, isSort: false },
]

export const staffsThead = [
  { title: "ミドルネーム", sticky: false, isSort: false },
  { title: "登録支援機関名 / 監理団体名", sticky: false, isSort: false },
  { title: "企業名", sticky: false, isSort: false },
  { title: "電話番号", sticky: false, isSort: false },
  { title: "メールアドレス", sticky: false, isSort: false },
  { title: "住所", sticky: false, isSort: false },
  { title: "緊急連絡先名前", sticky: false, isSort: false },
  { title: "緊急連絡先番号", sticky: false, isSort: false },
]
// テーブルボディ項目
export const staffsTbodyFixed = [
  { label: 'staff-family', translate: false },
  { label: 'staff-first', translate: false }
]
export const staffsTbody = [
  { label: 'staff-middle', translate: false },
  { label: 'rso-name', translate: false },
  { label: 'company-name', translate: false },
  { label: ['staff-cp1', 'staff-cp2', 'staff-cp3'], translate: false },
  { label: 'staff-email', translate: false },
  { label: 'staff-address', translate: false },
  { label: 'staff-emgcyName1', translate: false },
  { label: ['staff-emgcyNumber1', 'staff-emgcyNumber2', 'staff-emgcyNumber3'], translate: false }
]

// 企業
export const companiesTheadFixed = [
  { title: '詳細', sticky: true, isSort: false },
  { title: '〇〇株式会社', sticky: true, isSort: false, isFilter: true },
]
export const companiesThead = [
  { title: '代表者名', sticky: false, isSort: false },
  { title: '支援責任者名', sticky: false, isSort: false },
  { title: '電話番号', sticky: false, isSort: false },
  { title: 'メールアドレス', sticky: false, isSort: false },
  { title: '住所', sticky: false, isSort: false },
]

export const companiesTbodyFixed = [
  { label: 'company-name' },
]

export const companiesTbody = [
  { label: 'company-repName' },
  { label: 'company-supManname' },
  { label: 'company-tel' },
  { label: 'company-emailAddress' },
  { label: 'company-officeAdd' },
]

// 組合
export const kumiaiTheadFixed = [
  { title: "詳細", sticky: true, isSort: false },
  { title: "監理団体名", sticky: true, isSort: false, isFilter: true },
]

export const kumiaiThead = [
  { title: "代表者名", sticky: false, isSort: false },
  { title: "担当者名", sticky: false, isSort: false },
  { title: "電話番号", sticky: false, isSort: false },
  { title: "メールアドレス", sticky: false, isSort: false },
  { title: "住所", sticky: false, isSort: false },
]

export const kumiaiTbodyFixed = [
  { label: 'kumiai-name' },
]

// テーブルボディ項目
export const kumiaiTbody = [
  { label: 'kumiai-repName' },
  { label: 'kumiai-staffName' },
  { label: ['kumiai-officeNumber1', 'kumiai-officeNumber2', 'kumiai-officeNumber3'] },
  { label: 'kumiai-officeEmail' },
  { label: 'kumiai-officeAddress' },
]

// 送り出し機関
export const agenciesTheadFixed = [
  { title: "詳細", sticky: true, isSort: false },
  { title: "国", sticky: true, isSort: false },
  { title: "送り出し機関名", sticky: true, isSort: false },
]

export const agenciesThead = [
  { title: "代表者名", sticky: false, isSort: false },
  { title: "担当者名", sticky: false, isSort: false },
  { title: "メールアドレス", sticky: false, isSort: false },
  { title: "住所", sticky: false, isSort: false },
]

// テーブルボディ項目
export const agenciesTbodyFixed = [
  { label: 'agency-country' },
  { label: 'agency-name' },
]
export const agenciesTbody = [
  { label: 'agency-repName' },
  { label: 'agency-staffName' },
  { label: 'agency-officeEmail' },
  { label: 'agency-address' }
]

// 登録支援機関
export const rsosTheadFixed = [
  { title: '詳細', sticky: true, isSort: false },
  { title: '登録支援機関名', sticky: true, isSort: false, isFilter: true },
]
export const rsosThead = [
  // { title: 'No', sticky: true, isSort: true },
  { title: '代表者名', sticky: false, isSort: false },
  { title: '支援責任者氏名', sticky: false, isSort: false },
  { title: '電話番号', sticky: false, isSort: false },
  { title: 'メールアドレス', sticky: false, isSort: false },
  { title: '住所', sticky: false, isSort: false },
]
// テーブルボディ項目
export const rsosTbodyFixed = [
  { label: 'rso-name' },
]
export const rsosTbody = [
  { label: 'rso-repName' },
  { label: 'rso-supmanName' },
  { label: ['rso-Num1', 'rso-Num2', 'rso-Num3'] },
  { label: 'rso-officeEmail' },
  { label: 'rso-offAddress' },
]

// 企業用候補者
export const comStaFixThead = [
  { title: "詳細", sticky: true, isSort: false },
  { title: "国籍", sticky: true, isSort: false },
  { title: "姓", sticky: true, isSort: false },
  { title: "名", sticky: true, isSort: false },
]

export const comstaThead = [
  { title: "年齢", sticky: false, isSort: false },
  { title: "性別", sticky: false, isSort: false },
  { title: "メールアドレス", sticky: false, isSort: false },
  { title: "電話番号", sticky: false, isSort: false },
  { title: "住所", sticky: false, isSort: false }
]
// テーブルボディ項目
export const comstaFixTbody = [
  { label: 'staff-country' },
  { label: 'staff-family' },
  { label: 'staff-first' },
]

// 候補者
export const staffsColumns = [
  {
    width: 125,
    Header: 'ステータス',
    accessor: (data: any) => translateFunc(data.immigration)['TabMenu'],
    sticky: 'left',
  },
  {
    width: 170,
    Header: 'ファミリーネーム',
    accessor: 'staff-family',
    sticky: 'left',
  },
  {
    width: 170,
    Header: 'ファーストネーム',
    accessor: 'staff-first',
    sticky: 'left',
  },
  {
    Header: 'ミドルネーム',
    accessor: 'staff-middle',
  },
  {
    Header: '登録支援機関名',
    accessor: 'rso-name',
  },
  {
    Header: '企業名',
    accessor: 'company-name',
  },
  {
    Header: '送り出し機関名',
    accessor: 'agency-name',
  },
  {
    Header: '電話番号',
    accessor: (data:any) => data['staff-jpMbphone1'] ? `${data['staff-jpMbphone1']} ${data['staff-jpMbphone2']} ${data['staff-jpMbphone3']}`
    : ''
  },
  {
    width: 280,
    Header: 'メールアドレス',
    accessor: 'staff-email',
  },
  {
    width: 280,
    Header: '住所',
    accessor: 'staff-jpAddress',
  },
  {
    Header: '緊急連絡先名前',
    accessor: 'staff-emgcyName1',
  },
  {
    Header: '緊急連絡先番号',
    accessor: (data:any) => data['staff-emgcyNumber1'] ? `${data['staff-emgcyNumber1']} ${data['staff-emgcyNumber2']} ${data['staff-emgcyNumber3']}`
    : ''
  }
]

// 企業
export const companiesColumns = [
  {
    width: 200,
    Header: '企業名',
    accessor: 'company-name',
    sticky: 'left'
  },
  {
    width: 120,
    Header: '代表者名',
    accessor: 'company-repName',
    sticky: 'left',
    filter: 'fuzzyText',
  },
  {
    width: 280,
    Header: 'メールアドレス',
    accessor: 'company-emailAddress',
  },
  {
    width: 300,
    Header: '住所',
    accessor: 'company-officeAdd'
  }
]

// 企業
export const agenciesColumns = [
  {
    Header: '国',
    accessor: 'agency-country',
    sticky: 'left'
  },
  {
    Header: '送り出し機関名',
    accessor: 'agency-name',
    sticky: 'left'
  },
  {
    Header: '代表者名',
    accessor: 'agency-repName',
    sticky: 'left'
  },
  {
    width: 250,
    Header: 'メールアドレス',
    accessor: 'agency-officeEmail'
  },
  {
    width: 300,
    Header: '住所',
    accessor: 'agency-address'
  }
]

// 登録支援機関
export const rsosColumns = [
  {
    width: 180,
    Header: '登録支援機関',
    accessor: 'rso-name',
    sticky: 'left'
  },
  {
    Header: '代表者名',
    accessor: 'rso-repName',
    sticky: 'left'
  },
  {
    Header: '支援責任者名',
    accessor: 'rso-supmanName',
  },
  {
    width: 250,
    Header: '電話番号',
    accessor: (data: any) => data['rso-Num1'] ? `${data['rso-Num1']}-${data['rso-Num2']}-${data['rso-Num3']}` : '',
  },
  {
    width: 320,
    Header: 'メールアドレス',
    accessor: 'rso-officeEmail'
  },
  {
    width: 300,
    Header: '住所',
    accessor: 'rso-offAddress'
  }
]

// export const rsosTbody = [
//   { label: 'rso-repName' },
//   { label: 'rso-supmanName' },
//   { label: ['rso-Num1', 'rso-Num2', 'rso-Num3'] },
//   { label: 'rso-officeEmail' },
//   { label: 'rso-offAddress' },
// ]