// Modules
import React from 'react'
// Components
import { Select } from "../../Atoms/Select"
// Function
import { CommonFunctions } from "../../../functions/CommonFunction"

// *************** Type *************** //
type Props = {
  index: number
  name: string
  label: string
  options: Array<any>
  optionKey: string
  optionValue: string
  state: any
  setState: Function
}

export const PartnerList: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const {
    commons
  } = CommonFunctions()

  // *************** JSX *************** //
  return (
    <li
      key={props.index}
      className="partnerList"
    >
      <div className="selectSet">
        <Select
          className='h-full'
          name={props.name}
          label={props.label}
          // value={props.state &&
          //   props.state[props.name]
          //   ? props.state[props.name] : ""
          // }
          options={props.options}
          optionKey={props.optionKey}
          optionValue={props.optionValue}
          multiple={true}
          function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setValue(
            e.target.name,
            e.target.value,
            props.setState
          )}
        />
      </div>
    </li>
  )
}