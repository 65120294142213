// Modules
import React, {
  ReactChild
} from 'react'
// Components
import { Header } from "./Header"

// *************** Type *************** //
type Props = {
  children: ReactChild
  headerTab?: any
  tabAbility?: string | undefined
  selectUser?: any
}

export const Layouts: React.VFC<Props> = ({
  children,
  headerTab,
  tabAbility,
  selectUser
}) => (
  // *************** JSX *************** //
  <div className="contents">
    <Header
      // uId={data.uId}
      tab={headerTab}
      abilityKey={tabAbility}
      selectUser={selectUser}
    />
    { children }
  </div>
)