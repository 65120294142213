// Modules
import React from "react"
import { Link } from "react-router-dom"

type Props = {
  link: string
}

export const CountClient:React.VFC<Props> = (
  props: Props
) => {
  // *************** JSX *************** //
  return (
    <section className="countClient">
      <h2 className="font-bold text-2xl">顧客リスト</h2>
      <ul className="mt-1">
        <li className="w-40 shadow">
          <Link
            className="p-3"
            to={props.link}
          >
            <p className="p-2 text-center font-bold text-md">
              一覧へ
            </p>
          </Link>
        </li>
      </ul>
    </section>
  )
}