// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  index: number
  children?: any
  function?: any
}

export const List: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <li
    key={props.index}
    className={props.className}
    onClick={props.function}
  >
    {props.children}
  </li>
)