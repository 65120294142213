import React from "react"
import { NavLink } from "react-router-dom"
import '../stylesheets/Layouts/Header.scss'

const StaticHeader = () => {
  const navIcon = [
    { class: "fas fa-search", link: '' },
    { class: "fas fa-user-plus", link: '' },
    { class: "fas fa-sign-in-alt", link: '' }
  ]

  return (
    <div className="staticHeader flex space-between">
      <div className="siteLogo">
        <NavLink exact={true} to={'/'}>
          <svg width="175" height="30" viewBox="0 0 175 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.8691 15.0221C11.8691 16.7894 12.9862 18.2032 14.3826 18.2032H22.2372C22.6212 18.2032 22.9703 18.38 23.2845 18.7334C23.5637 19.0869 23.7034 19.5287 23.7034 20.0589V28.1001C23.7034 28.5861 23.5637 29.028 23.2845 29.3814C23.0052 29.7349 22.6561 29.9116 22.2372 29.9116H1.46619C1.08219 29.9116 0.733094 29.7349 0.418911 29.3814C0.139637 29.028 0 28.5861 0 28.056V1.85567C0 1.36966 0.139637 0.927835 0.418911 0.574374C0.733094 0.17673 1.08219 0 1.46619 0H22.2372C22.6212 0 22.9703 0.17673 23.2845 0.530191C23.5637 0.883652 23.7034 1.32548 23.7034 1.81149V9.8969C23.7034 10.4271 23.5637 10.8689 23.2845 11.2224C23.0052 11.5758 22.6561 11.7526 22.2372 11.7526H14.3826C12.9862 11.8409 11.8691 13.2548 11.8691 15.0221Z" fill="white" />
            <path d="M48.489 0.575113C48.2097 0.221652 47.8606 0.0449219 47.4417 0.0449219H26.6707C26.2867 0.0449219 25.9376 0.221652 25.6234 0.575113C25.3442 0.928574 25.2045 1.3704 25.2045 1.85641V11.7533C25.2045 12.7695 24.5413 13.609 23.7383 13.609H14.4176C13.8241 13.609 13.2656 14.1834 13.2307 14.9345C13.1957 15.7297 13.7194 16.4367 14.3477 16.4367H22.2023H23.7383C24.5413 16.4367 25.2045 17.2761 25.2045 18.2923V28.1451C25.2045 28.6311 25.3442 29.0729 25.6234 29.4705C25.9027 29.824 26.2518 30.0007 26.6707 30.0007H47.4417C47.8257 30.0007 48.1748 29.824 48.489 29.4705C48.7683 29.1171 48.9079 28.6753 48.9079 28.1451V1.85641C48.9079 1.3704 48.7683 0.928574 48.489 0.575113ZM38.1908 16.4367H35.9566V13.609H38.1908V16.4367Z" fill="white" />
            <path d="M73.6934 0.530191C73.4142 0.17673 73.0651 0 72.6461 0H51.8751C51.4911 0 51.142 0.17673 50.8279 0.530191C50.5486 0.883652 50.4089 1.32548 50.4089 1.81149V28.1001C50.4089 28.5862 50.5486 29.028 50.8279 29.4256C51.1071 29.7791 51.4562 29.9558 51.8751 29.9558H60.0439C60.7421 29.9558 61.3006 29.2047 61.2308 28.3211L61.1261 26.9514C61.161 26.1561 61.6846 25.4934 62.313 25.5376C62.9414 25.5817 63.465 26.2445 63.4301 27.0398L63.5348 28.5862C63.6046 29.3373 64.0934 29.9558 64.7217 29.9558H72.6461C73.0301 29.9558 73.3792 29.7791 73.6934 29.4256C73.9727 29.0722 74.1123 28.6303 74.1123 28.1001V1.85567C74.1472 1.36966 74.0076 0.927835 73.6934 0.530191Z" fill="white" />
            <path d="M98.9327 0.530191C98.6534 0.17673 98.3044 0 97.8854 0H77.1144C76.7304 0 76.3813 0.17673 76.0671 0.530191C75.7878 0.883652 75.6482 1.32548 75.6482 1.81149V28.1001C75.6482 28.5862 75.7878 29.028 76.0671 29.4256C76.3464 29.7791 76.6955 29.9558 77.1144 29.9558H85.2832C85.9814 29.9558 86.5399 29.2047 86.4701 28.3211L86.3654 26.9514C86.4003 26.1561 86.9239 25.4934 87.5523 25.5376C88.1806 25.5817 88.7043 26.2445 88.6694 27.0398L88.7741 28.5862C88.8439 29.3373 89.3327 29.9558 89.961 29.9558H97.8854C98.2694 29.9558 98.6185 29.7791 98.9327 29.4256C99.212 29.0722 99.3516 28.6303 99.3516 28.1001V1.85567C99.3516 1.36966 99.212 0.927835 98.9327 0.530191Z" fill="white" />
            <path d="M112.757 20.4573C112.757 22.2246 113.874 23.6384 115.27 23.6384H123.125C123.509 23.6384 123.858 23.8152 124.172 24.1686C124.451 24.5221 124.591 25.0081 124.591 25.4941V28.1009C124.591 28.5869 124.451 29.0287 124.172 29.4264C123.893 29.7798 123.544 29.9565 123.125 29.9565H102.354C101.97 29.9565 101.621 29.7798 101.307 29.4264C101.027 29.0729 100.888 28.6311 100.888 28.1009V1.85641C100.888 1.3704 101.027 0.928574 101.307 0.575113C101.586 0.221652 101.935 0.0449219 102.354 0.0449219H123.125C123.509 0.0449219 123.858 0.221652 124.172 0.575113C124.451 0.928574 124.591 1.3704 124.591 1.85641V4.46318C124.591 4.99337 124.451 5.4352 124.172 5.78866C123.893 6.14212 123.544 6.31885 123.125 6.31885H115.27C113.874 6.31885 112.757 7.73269 112.757 9.5C112.757 11.2673 113.874 12.6811 115.27 12.6811H123.125C123.509 12.6811 123.858 12.8579 124.172 13.2113C124.451 13.5648 124.591 14.0066 124.591 14.5368V15.3321C124.591 15.8181 124.451 16.2599 124.172 16.6134C123.893 16.9669 123.544 17.1436 123.125 17.1436H115.27C113.874 17.2761 112.757 18.69 112.757 20.4573Z" fill="white" />
            <path d="M139.148 13.6082H148.329C148.713 13.6082 149.062 13.4315 149.377 13.0781C149.656 12.7246 149.795 12.2386 149.795 11.7526V1.85567C149.795 1.36966 149.656 0.927835 149.377 0.574374C149.062 0.17673 148.713 0 148.329 0H127.558C127.174 0 126.825 0.17673 126.511 0.530191C126.232 0.883652 126.092 1.32548 126.092 1.81149V6.45066C126.092 7.33431 125.499 8.08542 124.8 8.08542H123.195C123.16 8.08542 123.125 8.08542 123.09 8.08542H115.305C114.712 8.08542 114.188 8.65979 114.118 9.36671C114.083 10.162 114.537 10.8247 115.13 10.8689H124.765C125.464 10.8689 126.057 11.62 126.057 12.5037V17.3196C126.057 18.2032 125.464 18.9543 124.765 18.9543H115.27C114.677 18.9543 114.118 19.5287 114.083 20.2798C114.048 21.0751 114.572 21.782 115.2 21.782H123.055H124.765C125.464 21.782 126.057 22.5331 126.057 23.4168V28.056C126.057 28.542 126.197 28.9838 126.476 29.3814C126.755 29.7349 127.104 29.9116 127.523 29.9116H148.294C148.678 29.9116 149.027 29.7349 149.342 29.3814C149.621 29.028 149.761 28.5861 149.761 28.1001V18.3358C149.761 17.8056 149.621 17.3638 149.342 17.0103C149.062 16.6568 148.713 16.4801 148.294 16.4801H139.043C138.415 16.4801 137.891 15.8174 137.926 14.9779C137.996 14.1826 138.555 13.6082 139.148 13.6082Z" fill="white" />
            <path d="M152.763 13.609C152.379 13.609 152.03 13.4323 151.716 13.0788C151.436 12.7253 151.297 12.2835 151.297 11.7533V1.85641C151.297 1.3704 151.436 0.928574 151.716 0.575113C151.995 0.221652 152.344 0.0449219 152.763 0.0449219H173.534C173.918 0.0449219 174.267 0.221652 174.581 0.575113C174.86 0.928574 175 1.3704 175 1.85641V11.7533C175 12.2393 174.86 12.6811 174.581 13.0788C174.302 13.4323 173.953 13.609 173.534 13.609H170.811C169.868 13.609 169.1 14.581 169.1 15.7739V28.1451C169.1 28.6311 168.961 29.0729 168.681 29.4705C168.402 29.824 168.053 30.0007 167.634 30.0007H158.732C158.348 30.0007 157.999 29.824 157.685 29.4705C157.406 29.1171 157.266 28.6753 157.266 28.1451V15.7739C157.266 14.581 156.498 13.609 155.556 13.609H152.763Z" fill="white" />
          </svg>
        </NavLink>
      </div>

      <div className={"currentNav flex"}>
        <NavLink exact={true} to={'/'}>
          <i className="fas fa-home" />
        </NavLink>
        <div><i className="fas fa-angle-right" /></div>
        <NavLink to={'/AllDocument'}>Q & A</NavLink>
      </div>

      {/* <nav className={"headerNav"}>
        <ul className={"flex"}>
          {
            navIcon.map((nav, i) => (
              <li key={i}>
                <Link>
                  <i className={nav.class}></i>
                  {nav.link}
                </Link>
              </li>
            ))
          }
        </ul>
      </nav> */}
    </div>
  )
}

export default StaticHeader