// Modules
import {
  useLocation
} from "react-router-dom"
import axios from "axios"
// API
import {
  getAPI,
  postUserType
} from "../Api"
// Config
import {
  commonURL,
  getRoleURL
} from "../../config/Common/apiURL"
import firebase from '../../config/Common/firebaseConfig'

export const AuthFunctions = () => {
  // *************** UseState & Const *************** //
  const { pathname } = useLocation()

  // *************** Functions *************** //
  const auth = {
    /**
     * アカウント登録とログインを切り替える
     */
    changeSignUp: (
      signOn: any,
      setSignOn: Function,
      forgetOn: any,
      setForgetOn: Function
    ) => {
      // ログインを表示する
      setSignOn(!signOn)
      // パスワード再設定が表示なら非表示にする
      forgetOn && setForgetOn(false)
    },
    /**
     * ログインとパスワード再設定を切り替える
     */
    changeForget: (
      setSignOn: Function,
      setForgetOn: Function
    ) => {
      // ログインを非表示にする
      setSignOn(false)
      // パスワード再設定を表示
      setForgetOn(true)
    },
    /**
     * パスワードを見える化
     */
    passwordOpen: (
      setPassOpen: Function,
      passOpen: boolean
    ) => setPassOpen(!passOpen),
    /**
     * サインアップ
     * @param value 入力したEmail, password, userType
     * @param setMessage
     */
    handleSignUp: async (
      value: any,
      setMessage: Function
    ) => {
      const {
        userType,
        email,
        password
      } = value
      // firebaseにユーザーを追加する
      try {
        // firebase Authenticationにユーザーを追加
        await firebase.auth ().createUserWithEmailAndPassword (
          email,
          password
        )
        // userのIDを定義
        const { uid }: any = firebase.auth ().currentUser
        // SignUp APIのURL
        const submitURL = `${commonURL}/userSignup/${uid}/${email}/${userType}`
        // DBにユーザー情報を送る
        await axios.post (submitURL)
        // ストラレージに入れるデータ
        const loginData = {
          uId: uid,
          userType: userType,
          admin: email === 'connect@sion-group.com',
          adminApproval: false,
          step: 'basic'
        }
        // localストレージのuIdとadminを格納
        localStorage.setItem ('user', JSON.stringify (loginData))
        // Cookie.set(
        //   "__session",
        //   JSON.stringify(loginData),
        //   { expires: 1 / 24 }
        // )
        console.log ('Welcome to Connect!!')
        // マスター制作ページへ遷移する
        window.location.replace (`/${userType}/${uid}/basic`)
      } catch (err) {
        console.log(err)
        // エラーメッセージを表示 & 処理を終了させる
        setMessage ('ユーザーは既に存在するか、入力内容が間違っています。')
        // 数秒経ったら非表示
        return setTimeout(() => setMessage(''), 5000)
      }
    },
    /**
     * ユーザーの有無をチェックする
     * @param {*} uId ログインするユーザーID
     */
    checkUser: async (uId: any) => {
      // バックエンドから返ってきたデータ
      const response = await postUserType(uId)
      // レスポンスデータ
      const {
        admin,
        userType,
        userName,
        adminApproval,
        step
      } = response.user
      // データが取って来れなかったら拒否する
      if (
        !(await getAPI(`${getRoleURL}/${userType}/${uId}`))
      ) return false
      // ストラレージに入れるデータ
      const loginData = {
        uId: uId,
        userType: userType,
        userName: userName,
        admin: !!admin,
        adminApproval: adminApproval,
        step: step
      }
      // localストレージのuIdとadminを格納
      localStorage.setItem('user', JSON.stringify(loginData))
      // Cookie.set(
      //   "__session",
      //   JSON.stringify(loginData),
      //   {
      //     expires: 1 / 24,
      //   }
      // );
      // マスター制作ページのpath
      const masterPath = `/${userType}/${uId}/basic`
      console.log('Login Success!')
      // 個人のデータを取得する
      const data = await getAPI(`${getRoleURL}/${userType}/${uId}`)
      // データのkeyを配列で取得
      const keys = Object.keys(data)
      // 個人が持つデータの分だけ処理を回す
      for (const k of keys) {
        // 名前がありなら指定したパスへ遷移
        if (pathname !== '/' || (
          pathname === '/' && k.match(/-name|-family/)
        )
        ) return window.location.reload()
      }
      // パス指定なし＆名前がなければマスター登録ページへ遷移
      window.location.replace(masterPath)
    },
    /**
     * ログイン
     * @param value emailとパスワード
     * @param setMessage
     */
    handleLogin: async (
      value: any,
      setMessage: Function
    ) => {
      const { email, password } = value
      try {
        // ログイン処理
        await firebase.auth().signInWithEmailAndPassword(email, password)
        // 現在のログインユーザーデータを取得
        const user: any = firebase.auth().currentUser
        // ログインに成功したuserID
        const uId = user.uid
        // マスターデータがあるかチェックする
        await auth.checkUser(uId)
      } catch (err) {
        console.log(err)
        // メッセージを表示する
        setMessage('ログインに失敗しました。ユーザーが存在しないか、入力内容が間違っています。')
        // 数秒経ったら非表示
        return setTimeout(() => setMessage(''), 5000)
      }
    },
    /**
     * パスワードリセット
     * @param {*} value 入力したデータ
     * @param {*} setMessage エラーメッセージ
     * @param {*} setForgetOn setState
     */
    handleReset: async (
      value: any,
      setMessage: Function,
      setForgetOn: Function
    ) => {
      // 入力したemail
      const { email } = value
      // emailアドレスにメールを送る
      try {
        await firebase.auth().sendPasswordResetEmail(email)
        // ログイン画面に戻す
        setForgetOn(false)
      } catch(err) {
        console.log(err)
        // メッセージを表示する
        setMessage('入力されたアカウントは存在しません。')
        // 数秒経ったら非表示
        setTimeout(() => setMessage(''), 5000)
      }
    }
  }
  // 外部で使用可能にする
  return { auth }
}