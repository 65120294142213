// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  name: string
  value?: string
  function?: any
  cols?: number
  rows?: number
  disabled?: boolean
}

export const Textarea: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <textarea
    className={props.className}
    name={props.name}
    id={props.name}
    value={props.value}
    onChange={props.function}
    cols={props.cols}
    rows={props.rows}
    disabled={props.disabled}
  />
)