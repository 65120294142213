// Modules
import React, {
  useEffect,
  useState,
  useMemo
} from "react"
import { useQuery } from "react-query"
import { useRecoilValue } from "recoil"
import { Link, useParams } from "react-router-dom"
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useTable
} from "react-table"
import { useSticky } from "react-table-sticky"
// Functions
import { getAPI } from "../../functions/Api"
import { translateFunc } from "../../functions/CommonFunction"
// Components
import { Table } from "../../components/Molecules/Common/Table"
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import { Error } from "../../components/Molecules/Error/Error"
// Config
import { storageState } from "../../config/Common/RecoilState"
import { Can } from "../../config/Common/Can"

// *************** Type *************** //
type Props = {
  queryKey: string
  url: string
  link: string
}

export const DocumentCatalog: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { admin } = useRecoilValue(storageState)
  const { cId } = useParams<Record<any, any>>()
  const [tableData, setTableData] = useState<Array<any>>([])

  // *************** React Query *************** //
  const {
    isLoading,
    isError,
    data: documentStaffs
  } = useQuery(
    props.queryKey,
    async () => getAPI(
      admin ? props.url
      : `${props.url}/${cId}`
    )
  )

  useEffect(() => {
    if (isLoading) return
    setTableData(documentStaffs)
  }, [documentStaffs])

  const [columns, data] = useMemo(
    () => {
      const columns = [
        {
          width: 90,
          Header: '申請書',
          Cell: (cell: any) => (
            <Link
              className="rounded-full bg-skyBlue font-bold text-sm text-white cursor-pointer"
              to={ cId ? `${props.link}/${cId}/${data[cell.row.index].visa}/${data[cell.row.index].id}/${data[cell.row.index].language}`
                : `${props.link}/${data[cell.row.index].visa}/${data[cell.row.index].id}/${data[cell.row.index].language}`
              }
            >
              作成
            </Link>
          ),
          sticky: 'left'
        },
        {
          width: 170,
          Header: 'ファミリーネーム',
          accessor: 'staff-family',
          sticky: 'left',
        },
        {
          width: 170,
          Header: 'ファーストネーム',
          accessor: 'staff-first',
          sticky: 'left',
        },
        {
          width: 170,
          Header: 'ミドルネーム',
          accessor: 'staff-middle',
          sticky: 'left',
        },
        {
          Header: '国籍',
          accessor: 'staff-country',
        },
        {
          Header: '就労VISA',
          accessor: (data: any) => translateFunc(data.visa)['TabMenu'],
        }
      ]
      return [columns, tableData]
    },
    [tableData]
  )
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSticky,
    useBlockLayout,
    useFilters,
    useGlobalFilter
  )

  // *************** JSX *************** //
  return (
    <Layouts>
      <main>
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <Table
            {...tableInstance}
            className="h-full"
            sticky={true}
          />
        )}
        <div className="fixed right-5 bottom-5 text-white">
          {/* POLO申請書作成 */}
          <Can
            I="read"
            a="poloButton"
          >
            <Link
              className="poloButton cancelButton px-8 py-4 whitespace-nowrap"
              to="/POLODocuments"
            >
              POLO申請書
            </Link>
          </Can>
          <Can
            I="read"
            a="docTemplateButton"
          >
            <Link
              className="switchButton inline-block mt-3 py-4 px-8 font-bold"
              to="/DocTemplateForm"
            >
              申請書テンプレ作成
            </Link>
          </Can>
        </div>
      </main>
    </Layouts>
  )
}

export default DocumentCatalog