// Modules
import React, {
  useState
} from 'react'
import "firebase/auth"
// Components
import { Title } from "../../components/Atoms/Title"
import { Button } from "../../components/Atoms/Button"
import { Login } from "../../components/Molecules/Authentication/Login"
import { SignUp } from "../../components/Molecules/Authentication/SignUp"
import { ForgetPassword } from '../../components/Molecules/Authentication/ForgetPassword'
// Functions
import { AuthFunctions } from '../../functions/Authentication/AuthFunctions'
// Stylesheets
import '../../stylesheets/pages/Authentication/Login.scss'

export const Authentication: React.VFC = () => {
  // *************** Const *************** //
  const [signOn, setSignOn] = useState(false)
  const [forgetOn, setForgetOn] = useState(false)
  const { auth } = AuthFunctions()

  // *************** JSX *************** //
  return (
    <main className="loginContent flex flex-col minh100-vh py-32">
      <Title
        className="w-64 mx-auto"
      />
      { signOn ? (
        <SignUp />
      ) : forgetOn ? (
        <ForgetPassword
          setForgetOn={setForgetOn}
        />
      ) : (
        <Login
          signOn={signOn}
          setSignOn={setSignOn}
          forgetOn={forgetOn}
          setForgetOn={setForgetOn}
        />
      )}
      <Button
        classProps="switchButton py-4 px-8"
        text={ !signOn ? 'アカウント登録' : 'ログイン' }
        function={() => auth.changeSignUp(
          signOn,
          setSignOn,
          forgetOn,
          setForgetOn
        )}
      />
    </main>
  )
}

export default Authentication