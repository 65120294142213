// *************** Array *************** //
import { translateFunc } from "../../functions/CommonFunction"

export const statusTab = [
  // {
  //   text: '特定技能',
  //   path: 'SpecifiedSkill',
  //   can: 'specifiedStatus'
  // },
  // {
  //   text: '技能実習',
  //   path: 'Trainee',
  //   can: 'traineeStatus'
  // }
]

// 入国前
export const enterTheadFixed = [
  { title: '名前', sticky: true, isSort: false },
  { title: '就労開始予定日', sticky: true, isSort: true },
]
export const specifiedEnterThead =  [
  { title: '健康診断', sticky: false, kind: 'status', isSort: false },
  { title: '日付(受診日）', sticky: false, kind: 'date', isSort: true },
  { title: '候補者書類・海外手続き書類作成状況', sticky: false, kind: 'status', isSort: false },
  { title: '候補者書類発送状況', sticky: false, isSort: false },
  { title: '日付（送付日）', sticky: false, kind: 'date', isSort: true },
  { title: '国内書類準備', sticky: false, kind: 'status', isSort: false },
  { title: '日付（完了日）', sticky: false, kind: 'date', isSort: true },
  { title: '入管提出', sticky: false, kind: 'status', isSort: false },
  { title: '日付（提出日）', sticky: false, kind: 'date', isSort: true },
  { title: 'COE許可', sticky: false, kind: 'status', isSort: false },
  { title: '日付（許可日）', sticky: false, kind: 'date', isSort: true },
  { title: 'COE郵送', sticky: false, kind: 'status', isSort: false },
  { title: '日付（発送日）', sticky: false, kind: 'date', isSort: true },
  { title: 'VISA申請', sticky: false, kind: 'status', isSort: false },
  { title: '申請日', sticky: false, isSort: true },
  { title: '予定日', sticky: false, kind: 'date', isSort: true },
  { title: '出国セミナー', sticky: false, kind: 'status', isSort: false },
  { title: '日付（開催日）', sticky: false, kind: 'date', isSort: true },
  { title: 'POEA提出日', sticky: false, kind: 'status', isSort: true },
  { title: '出国許可予定日', sticky: false, kind: 'date', isSort: true },
  { title: '日本入国・VISA切替', sticky: false, kind: 'status', isSort: false },
  { title: '入国日', sticky: false, kind: 'date', isSort: true },
]
export const traineeEnterThead =  [
  { title: '面接日', sticky: false, kind: 'status', isSort: false },
  { title: '日付', sticky: false, kind: 'date', isSort: true },
  { title: '健康診断', sticky: false, kind: 'status', isSort: false },
  { title: '日付(受診日）', sticky: false, kind: 'date', isSort: true },
  { title: '候補者書類・海外手続き書類作成状況', sticky: false, kind: 'status', isSort: false },
  { title: '候補者書類発送状況', sticky: false, isSort: false },
  { title: '日付（送付日）', sticky: false, kind: 'date', isSort: true },
  { title: '入管 or 技能実習生機構への書類準備', sticky: false, kind: 'status', isSort: false },
  { title: '日付（期限）', sticky: false, kind: 'date', isSort: true },
  { title: '入管提出', sticky: false, kind: 'status', isSort: false },
  { title: '日付（提出日）', sticky: false, kind: 'date', isSort: true },
  { title: 'COE許可', sticky: false, kind: 'status', isSort: false },
  { title: '日付（許可日）', sticky: false, kind: 'date', isSort: true },
  { title: 'COE郵送', sticky: false, kind: 'status', isSort: false },
  { title: '日付（発送日）', sticky: false, kind: 'date', isSort: true },
  { title: 'VISA申請', sticky: false, kind: 'status', isSort: false },
  { title: '申請日', sticky: false, isSort: true },
  { title: '予定日', sticky: false, kind: 'date', isSort: true },
  { title: '出国セミナー', sticky: false, kind: 'status', isSort: false },
  { title: '日付（開催日）', sticky: false, kind: 'date', isSort: true },
  { title: 'POEA提出日', sticky: false, kind: 'status', isSort: true },
  { title: '出国許可予定日', sticky: false, kind: 'date', isSort: true },
  { title: '日本入国・VISA切替', sticky: false, kind: 'status', isSort: false },
  { title: '入国日', sticky: false, kind: 'date', isSort: true },
]
export const enterTbody = [
  { label: ['staff-family', 'staff-first', 'staff-middle'], tag: 'text', className: 'staffName' },
  { label: 'userEntryDate', tag: 'date', className: 'entryDate' },
]

// 入国後
export const enteredTheadFixed = [
  { title: '名前', sticky: true, isSort: false },
  { title: 'VISA取得日', sticky: true, isSort: false },
  { title: 'VISA期限', sticky: true, isSort: false },
]

export const enteredThead = [
  { title: 'VISA更新', sticky: false, isSort: false },
  { title: '日付（更新日）', sticky: false, isSort: false },
  { title: '今期第１四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '今期第２四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '今期第３四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '今期第４四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '来期第１四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '来期第２四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '来期第３四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false },
  { title: '来期第４四半期 定期報告', sticky: false, isSort: false },
  { title: '日付（実施日）', sticky: false, isSort: false }
]
export const enteredTbody = [
  { label: ['staff-family', 'staff-first', 'staff-middle'], tag: 'text', className: 'staffName' },
  { label: 'getVisa', tag: 'date', className: 'getVisa' },
  { label: 'expiredVisa', tag: 'date', className: 'expiredVisa' },
]

// ヘルプの内容 -> HelpProvider.content
export const enterHelp: any = [
  { className: "square-red", text: "期限まで8日以内" },
  { className: "square-yellow", text: "期限まで15日以内" },
  { className: "square-gray", text: "編集不可" },
  { className: "square-white", text: "問題なし" }
]

export const enteredHelp: any = [
  { className: "square-red", text: "期限 or 実施日まで8日以内" },
  { className: "square-yellow", text: "期限 or 実施日まで15日以内" },
  { className: "square-gray", text: "編集不可" },
  { className: "square-white", text: "問題なし" }
]

export const enterColumns: any = [
  {
    width: 125,
    Header: 'ステータス',
    accessor: (data: any) =>
      data['staff-middle'] ? `${data['staff-first']} ${data['staff-family']} ${data['staff-middle']}`
        : data['staff-first'] ? `${data['staff-first']} ${data['staff-family']}`
        : '',
  },
  {
    Header: 'VISA取得日',
    accessor: 'getVisa',
    sticky: 'left'
  },
  {
    Header: 'VISA期限',
    accessor: 'expiredVisa',
  },
]