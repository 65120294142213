// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  className?: string
  id?: string
  name?: string
  value?: any
  function?: any
}

export const RadioInput: React.VFC<Props> = (
  props: Props
) => (
  // *************** JSX *************** //
  <input
    className={props.className}
    id={props.id}
    name={props.name}
    type="radio"
    value={props.value}
    onClick={props.function}
  />
)