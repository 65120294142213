//  Modules
import React, {
  useState
} from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "react-query"
// Apis
import { getAPI } from "../../functions/Api"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import { Error } from "../../components/Molecules/Error/Error"
import { ExcelFormComponent } from "../../components/Molecules/Documents/ExcelFormComponent"
import { WordFormComponent } from "../../components/Molecules/Documents/WordFormComponent"
import { DocButtonWrap } from "../../components/Molecules/Documents/DocButtonWrap"
// Config
import {
  commonURL,
  documentMasterURL
} from "../../config/Common/apiURL"
// Stylesheets
import '../../stylesheets/pages/Documents/Document.scss'

export const DocumentForm: React.VFC = () => {
  // *************** Const *************** //
  const { dId, id, language } = useParams<Record<any, any>>()
  // inputの入力値
  const [docInput, setDocInput] = useState()
  // sysKeyの値
  const [sysInput, setSysInput] = useState()
  const [modalOn, setModalOn] = useState(false)
  // エラーメッセージate(false)
  const [errorMessage, setErrorMessage] = useState<any>()
  
  // *************** React Query *************** //
  const promiseQuery = async () => {
    // documentMasterデータ取得
    const documentData = await getAPI(`${documentMasterURL}/${dId}`)
    // documentMasterの画像データ
    const imagesData = await getAPI(`${commonURL}/subDynamicGet/documentsMaster/${dId}/images`)
    // 各言語のHTMLを取得
    const languageData = await getAPI(`${documentMasterURL}/${dId}/${language}`)
    // sysKeyデータを取得
    const sysData = await getAPI(`${commonURL}/sysKeys/${dId}/${id}`)
    // 外部で使用可能にする
    return {
      documentData,
      imagesData,
      languageData,
      sysData
    }
  }
  
  const {
    isLoading,
    isError,
    data
  } = useQuery(
    'docData',
    async () => promiseQuery()
  )
  
  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="docForm">
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <div className="docFormContent">
            <h2 className="docForm-title font-bold text-xl">
              { data && data.documentData.documentName}
            </h2>
            { data && data.documentData.mimeType === 'excel' ? (
               <ExcelFormComponent
                 id={id}
                 docData={data.documentData}
                 imageData={data?.imagesData}
                 sysData={data.sysData}
                 setDocInput={setDocInput}
                 docInput={docInput}
                 setSysInput={setSysInput}
              />
            ) : (
              <WordFormComponent
                docHtml={data && data.languageData
                  ? data.languageData
                  : data && data.documentData
                }
                docData={ data && data.documentData }
                sysData={ data && data.sysData }
                setDocInput={setDocInput}
                setSysInput={setSysInput}
              />
            )}
            <DocButtonWrap
              uId={id}
              dId={dId}
              language={language}
              setModalOn={setModalOn}
              setErrorMessage={setErrorMessage}
              docInput={docInput}
              mergeObj={sysInput}
            />
            <div>
              <div
                className={`downloadModal pos-f pos-t50-per pos-l50-per
                  z10 ph10-per pv15-per
                  ${ modalOn ? "on" : ""}
                `}
              >
                { errorMessage ?
                  <p>
                    申請書を作成できませんでした。<br/>ページを読み込んでからもう一度<br/>お試しください。
                  </p>
                  : <p>申請書を作成しています。少々お待ちください。</p>
                }
              </div>
            </div>
          </div>
        )}
      </main>
    </Layouts>
  )
}

export default DocumentForm