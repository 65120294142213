// Modules
import React, {
  useState
} from 'react'
import ja from 'date-fns/locale/ja'
import moment from "moment"
// Components
import { Modal } from "../../Atoms/Modal"
import { Label } from "../../Atoms/Label"
import { Textarea } from "../../Atoms/Textarea"
import { DateInput } from "../../Atoms/DateInput"
import { Button } from "../../Atoms/Button"
import { OpenButton } from "../../Atoms/OpenButton"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
// Config
import {
  careerStart,
  careerEnd
} from "../../../config/Career/CareerConfig"
import { Can } from "../../../config/Common/Can"
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons"

// *************** Type *************** //
type Props = {
  modalData?: any
  setModalData: Function
  mutation?: any
  careerList?: Array<any>
  setCareerList?: any
}

export const CareerModal: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  // ユーザーデータ
  const {
    id,
    visa,
    language,
    'staff-family': family,
    'staff-first': first,
    'staff-middle': middle
  } = props.modalData
  const { commons } = CommonFunctions()
  const [deleteList, setDeleteList] = useState<Array<any>>([])

  // *************** JSX *************** //
  return (
    <>
      <Can
        I="read"
        a="quarterButton"
      >
        <OpenButton
          className="updateButton fixed top-5 right-5 z60 px-6 py-3"
          href={`/QuarterlyReport/${visa}/${id}/${language}/foreignerRegularInterview`}
          text="定期面談報告書"
        />
      </Can>
      <Modal
        className="fixed z100 top-1/2 left-1/2 w-2/3 transform -translate-x-1/2 -translate-y-1/2"
        overlayClass="black"
        modalFunction={commons}
      >
        <div className="modalContent p-8 rounded-md bg-white">
          <Button
            classProps="block ml-auto"
            text={ <FontAwesomeIcon icon={faTimes} /> }
            function={commons.closeModal}
          />
          <div className="modalProfile text-center">
            <h3 className="font-bold text-lg">
              <p>
                <span className="text-2xl">{family} {first} {middle}</span>さんが
              </p>
              <p className="flex-1">行った業務を入力してください</p>
            </h3>
          </div>
          { props.careerList && props.careerList.length > 0 && (
            <Can
              I="read"
              a="allDelete"
            >
              <Button
                classProps="cancelButton block mt-2 px-8 py-2"
                text="全削除"
                function={() => {
                  commons.fullDeleteFlg(
                    props.careerList,
                    setDeleteList
                  )
                  commons.clearArray(props.setCareerList)
                }}
              />
            </Can>
          )}
          { props.careerList?.length && props.careerList.length > 0 ? (
            <ul className="data_wrap
              flex flex-col h-60 mt-2 p-2 over-sc rounded-md"
            >
              { props.careerList.sort((a: any, b: any) => b.listNumber - a.listNumber)
              .map((list: any, i: number) => (
                <li
                  key={i}
                  className={`addFormWrap shadow mt-5 p-3`}
                >
                  <Can
                    I="read"
                    a="deleteCareer"
                  >
                    <Button
                      classProps="cancelButton block ml-auto px-2 py-1"
                      text="削除"
                      function={() => {
                        commons.addDeleteFlg(
                          list,
                          deleteList,
                          setDeleteList
                        )
                        commons.removeArray(
                          i,
                          props.careerList,
                          props.setCareerList
                        )
                      }}
                    />
                  </Can>
                  <div
                    className="workYear
                      flex items-center justify-around w-full mt-2
                    "
                  >
                    {/* 職歴開始 */}
                    { careerStart.map((start: any) => (
                      <DateInput
                        className="w-full p-1 bg-navy text-white"
                        name={start.name}
                        dataFormat={start.dataFormat}
                        localeName="ja"
                        localeData={ja}
                        placeholder={start.placeholder}
                        yearOnly={start.yearOnly}
                        monthOnly={start.monthOnly}
                        value={ list[start.name]? new Date(list[start.name]) : "" }
                        function={(date: any) => commons.setArrayValue(
                          i,
                          start.name,
                          moment(date).format(start.dataFormat),
                          props.careerList,
                          props.setCareerList
                        )}
                        disabled={!list.insert_flg}
                      />
                    ))}
                    <span>〜</span>
                    {/* 職歴終了 */}
                    { careerEnd.map((end: any) => (
                      <DateInput
                        className="w-full p-1 bg-navy text-white"
                        name={end.name}
                        dataFormat={end.dataFormat}
                        localeName="ja"
                        localeData={ja}
                        placeholder={end.placeholder}
                        yearOnly={end.yearOnly}
                        monthOnly={end.monthOnly}
                        value={ list[end.name] ? new Date(list[end.name]) : "" }
                        function={(date: any) => commons.setArrayValue(
                          i,
                          end.name,
                          moment(date).format(end.dataFormat),
                          props.careerList,
                          props.setCareerList
                        )}
                        disabled={!list.insert_flg}
                      />
                    ))}
                  </div>
                  <div className="dis-inline-flex w100-per mt2-per mh-auto">
                    <Label
                      htmlFor="detail"
                      text="職歴概要"
                    />
                    <Textarea
                      className="f1 ml2-per minh5-rem"
                      name="detail"
                      value={list.detail}
                      function={(e: React.ChangeEvent<HTMLTextAreaElement>) => commons.setArrayValue(
                        i,
                        e.target.name,
                        e.target.value,
                        props.careerList,
                        props.setCareerList
                      )}
                      disabled={!list.insert_flg}
                    />
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <p className="nonData mt-3 p-4 rounded-md text-center">
              職歴データがありません。
            </p>
          )}
          <Can
            I="read"
            a="addCareer"
          >
            <Button
              classProps="updateButton block mt-3 ml-auto px-1"
              text={ <FontAwesomeIcon icon={faPlus} size="2x" /> }
              function={() => commons.addArray(
                props.careerList && props.careerList.length,
                id,
                {
                  insert_flg: true,
                  careerList: !props.careerList?.length ? 'list1'
                  : `list${props.careerList?.length && props.careerList?.length + 1}`,
                  listNumber: !props.careerList?.length ? 1
                  : props.careerList?.length && props.careerList?.length + 1,
                  detail: '',
                  startYear: '',
                  startMonth: '',
                  endYear: '',
                  endMonth: '',
                },
                props.careerList,
                props.setCareerList
              )}
            />
          </Can>
        </div>
      </Modal>
      <Can
        I="read"
        a="saveCareer"
      >
        <Button
          classProps={`updateButton fixed bottom-5 right-5 z60 pv1-per ph5-per
            ${props.mutation.isLoading && 'disable'}
            ${props.mutation.isSuccess && 'success'}
            ${props.mutation.isError && 'error'}
          `}
          text={ props.mutation.isLoading
            ? '更新中・・・' : props.mutation.isSuccess
            ? '更新完了' : props.mutation.isError
            ? 'エラー' : '更新'
          }
          function={() => commons.postArrayMutation(
            props.mutation,
            deleteList,
            props.careerList
          )}
        />
      </Can>
    </>
  )
}