// Modules
import React from "react"
// Components
import { Label } from "../../Atoms/Label"
import { Input } from "../../Atoms/Input"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
// Config
import { docFormList } from "../../../config/Document/DocumentConfig"
import { Select } from "../../Atoms/Select"

// *************** Type *************** //
type Props = {
  state?: any
  setState: Function
}

export const TemplateForm = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** JSX *************** //
  return (
    <ul className="mt-5">
      { docFormList.map((list, j) => (
        <li
          key={j}
          className="mt-5 first:mt-0"
        >
          <Label
            className="font-bold text-lg"
            htmlFor={list.name}
            text={list.label}
          />
          { list.tag === 'select' ? (
            <Select
              className="ml-2 p-1 bg-blue-100"
              name={list.name}
              label={list.label}
              options={list.options}
              optionKey="text"
              optionValue="value"
              function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setValue(
                e.target.name,
                e.target.value,
                props.setState
              )}
            />
          ) : (
            <Input
              className="ml-2 p-1 bg-blue-100"
              name={list.name}
              type={list.type}
              value={ props.state[list.name] ? props.state[list.name] : ''}
              function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setValue(
                e.target.name,
                e.target.value,
                props.setState
              )}
            />
          )}
          <p className="text-red-500 text-sm font-bold">
            ※ { list.placeholder }
          </p>
        </li>
      ))}
    </ul>
  )
}