// Modules
import React, {
  useState,
  useEffect
} from "react"
import { useMutation } from "react-query"
// Components
import { Label } from "../../Atoms/Label"
import { Input } from "../../Atoms/Input"
import { Button } from "../../Atoms/Button"
import { Modal } from "../../Atoms/Modal"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { postAPI } from "../../../functions/Api"
// Config
import { postApplyURL } from "../../../config/Common/apiURL"
import { applyInput } from "../../../config/Interview/InterviewConfig"

// *************** Type *************** //
type Props = {
  setModalType?: any
}

export const ApplyModal:React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()
  const [errorMessages, setErrorMessages] = useState<any>({
    agencies: "",
    companies: "応募先企業IDを入力してください",
    rsos: "",
    id: "候補者IDを入力してください"
  })
  const [applyForm, setApplyForm] = useState<any>({
    agencies: "",
    companies: "",
    rsos: "",
    id: ""
  })

  // *************** UseMutation *************** //
  // Interviewで応募データを送信するmutation
  const mutation = useMutation((data: any) => postAPI(
    postApplyURL,
    data
  ), {
    onError: async (error) => console.error(error),
    onSuccess: async () => {
      console.log('応募に成功しました。')
    }
  })

  console.log(applyForm)


  // *************** JSX *************** //
  return (
    <Modal
      className="catalogModal absolute z-50 top-0 left-0"
      modalFunction={commons}
    >
      <div
        className="masterCatalogOverLay flex flex-col
          fixed top-28 bottom-28 left-1/2 z-20 transform -translate-x-1/2 p-12 rounded-lg
        "
      >
        <div className="masterCatalogWrap flex flex-col">
          <h3 className="masterCatalogTitle pb2-per font-bold fs2-rem ta-c">
            応募フォーム
          </h3>
          <ul>
            { applyInput.sort((a: any, b: any) => (
              a.number - b.number
            )).map((obj: any, i: number) => (
              <li
                key={i}
                className="pb=1 font-bold p10-px"
              >
                <Label
                  className="fs20-px"
                  htmlFor={obj.name}
                  text={obj.label}
                >
                </Label>
                <Input
                  className="masterCatalogInput w-full p-1 br3-px"
                  name={obj.name}
                  value={applyForm[`${obj.name}`]}
                  function={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                    commons.checkValidation(
                      obj.required,
                      e.target.name,
                      obj.label,
                      "",
                      e.target.value,
                      "",
                      setErrorMessages,
                      false
                    )
                    commons.setValue(
                      e.target.name,
                      e.target.value,
                      setApplyForm
                    )
                  }}
                />
                {errorMessages[obj.name] &&
                  <p className="errorMessage mt-2">{errorMessages[obj.name]}</p>
                }
              </li>
            ))}
          </ul>
          <Button
            classProps={`updateButton block mt-3 py-3
              ${!!Object.values(errorMessages).filter((error: any) => error).length && 'disable'}
              ${mutation.isLoading && 'disable'}
              ${mutation.isSuccess && 'success'}
              ${mutation.isError && 'error'}
            `}
            text={ mutation.isLoading ? '応募中・・・'
              : mutation.isSuccess ? '応募完了'
              : mutation.isError ? 'エラー'
              : '応募'
            }
            disabled={!!Object.values(errorMessages).filter((error: any) => error).length}
            function={() => mutation.mutate(applyForm)}
          />
          <Button
            classProps="cancelButton block mt-5 py-3 masterCatalogButton"
            text="閉じる"
            function={() => {
              commons.closeModal()
              // props.setModalType('detail')
            }}
          />
        </div>
      </div>
    </Modal>
  )
}