// Modules
import React from "react"
// Components
import { Modal } from "../../Atoms/Modal"
import { Label } from "../../Atoms/Label"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { Checkbox } from "../../Atoms/Checkbox"

// *************** Type *************** //
type Props = {
  text?: any
  value?: any
  modalData?: any
  staffState?: any
  setStaffState?: any
  modalPosY?: Number
}

export const UserModal: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()

  // *************** JSX *************** //
  return (
    <Modal
      className="detailModal absolute top-0"
      modalFunction={commons}
    >
      <ul
        className="p-4"
        style={{ top: `${props.modalPosY}px` }}
      >
        <li>
          <Label
            className="font-bold text-md"
            htmlFor="immigration"
            text={props.text}
          />
          <div className="toggleButton">
            <Checkbox
              name="immigration"
              checked={props.staffState[props.modalData.index].immigration === props.value}
              value={props.staffState[props.modalData.index].immigration}
              function={(e: React.ChangeEvent<HTMLInputElement>) => commons.arraySetValue(
                props.modalData.index,
                e.target.name,
                e.target.checked ? props.value : '',
                props.staffState,
                props.setStaffState
              )}
            />
            <div className="squareWrap">
              <span className="square">OFF</span>
            </div>
            <div className="layer"/>
          </div>
        </li>
      </ul>
    </Modal>
  )
}