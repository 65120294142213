// Modules

export const MasterFunctions = () => {
  // *************** Functions *************** //
  const masters = {
    /**
     * 合計採用数を計算する
     * @param array
     * @param parameter
     * @param setState
     */
    calcHiredNumber: (
      array: Array<any>,
      parameter: string,
      setState: Function
    ) => {
      // 合計数字を出す
      const sum = array.reduce((prev: any, current: any) =>
        prev + current[parameter],
        0
      )
      setState(sum)
    },
  }
  // 関数をexportする
  return {
    masters
  }
}