// Modules
import React from "react"
import { useQuery } from "react-query"
import { useRecoilValue } from "recoil"
import { Link, useLocation } from "react-router-dom"
// Functions
import { getAPI } from "../../functions/Api"
import { CommonFunctions } from "../../functions/CommonFunction"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { ApplyModal } from "../../components/Molecules/Interview/ApplyModal"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import Error from "../../components/Molecules/Error/Error"
// Config
import { modalState, storageState } from "../../config/Common/RecoilState"
import { Can } from "../../config/Common/Can"
// Stylesheets
import '../../stylesheets/pages/Master/UserSelect.scss'
import '../../pages/Master/MasterCatalog'
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileAlt } from "@fortawesome/free-solid-svg-icons"

// *************** Type *************** //
type Props = {
  queryKey: string
  getUrl: string
  companies: boolean
  agencies?: boolean | undefined
  rsos?: boolean | undefined
  link: string
  apply: boolean
}

export const UserSelect: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { userType, uId } = useRecoilValue(storageState)
  const { pathname } = useLocation()
  const isModal = useRecoilValue(modalState)
  const { commons } = CommonFunctions()

  // *************** React Query *************** //
  const promises = async () => {
    // 企業一覧データ
    const companiesData = await getAPI(`${props.getUrl}/${uId}/companiesList`)
    // 送り出し機関データ
    const agenciesData = props.agencies
      ? await getAPI(`${props.getUrl}/${uId}/agenciesList`)
      : []
    // 登録支援機関データ
    const rsosData = props.rsos
      ? await getAPI(`${props.getUrl}/${uId}/rsosList`)
      : []
    // 外部で使用可能にする
    return {
      agenciesData,
      companiesData,
      rsosData
    }
  }

  const {
    isLoading,
    isError,
    data
  } = useQuery(
    props.queryKey,
    () => promises()
  )

  // *************** JSX *************** //
  return (
    <Layouts>
      <main>
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <div className="effectwrap">
            <h3
              className="UserSelectTitle flex flex-column
               font-bold text-2xl pb-2 pl-2
              "
            >
              顧客ユーザー選択
            </h3>
            <h3 className="flex flex-column p-3 font-bold text-xl">
              受け入れ企業
            </h3>
            { data?.companiesData.length ? (
              <ul className="UserSelectOverLay">
                { data?.companiesData.map((d: any, i: number) => (
                  <div className="buttonWrapper">
                    <Link to={`${props.link}/companies/${d.id}`}>
                      <li key={i} className="careerUpdateButton ph3-per pv1-per p2-per">
                        {d['company-name']}
                      </li>
                    </Link>
                  </div>
                ))}
              </ul>
            ) : (
              <p className="text-red-500 font-bold">
                連携している受け入れ企業がいません
              </p>
            )}
            <Can
              I="read"
              a={"userSelectAgencyArea"}
            >
              <h3 className="flex flex-column p-2 font-bold text-xl">
                送り出し機関
              </h3>
              { data?.agenciesData.length ? (
                <ul className="UserSelectOverLay">
                  { data?.agenciesData.map((d: any, i: number) => (
                    <div className="buttonWrapper">
                      <Link to={`${props.link}/agencies/${d.id}`}>
                        <li key={i} className="careerUpdateButton ph3-per pv1-per p2-per">
                          {d['agency-name']}
                        </li>
                      </Link>
                    </div>
                  ))}
                </ul>
              ) : (
                <p className="text-red-500 font-bold">
                  連携している送り出し機関がいません
                </p>
              )}
            </Can>
            <Can
              I="read"
              a={"userSelectRsoArea"}
            >
              <h3 className="flex flex-column p-2 font-bold text-xl">
                登録支援機関
              </h3>
              { data?.rsosData.length ? (
                <ul className="UserSelectOverLay">
                  { data?.rsosData.map((d: any, i: number) => (
                    <div className="buttonWrapper">
                      <Link to={`${props.link}/rsos/${d.id}`}>
                        <li key={i} className="careerUpdateButton ph3-per pv1-per p2-per">
                          {d['rso-name']}
                        </li>
                      </Link>
                    </div>
                  ))}
                </ul>
              ) : (
                <p className="text-red-500 font-bold">
                  連携している登録支援機関がいません
                </p>
              )}
            </Can>
          </div>
        )}
        {/* 応募フォーム */}
        { props.apply && (
          <p
            className="poloButton switchButton
              fixed right-5 bottom-5 px-16 py-2
            "
            onClick={commons.openModal}
          >
            応募
          </p>
        )}

        {/* POLO申請書作成 */}
        { pathname.match(/Document/) && (
          <Can
            I="read"
            a="poloButton"
          >
            <Link
              className="poloButton cancelButton
                flex items-center fixed right-5 bottom-5 px-16 py-2 whitespace-nowrap
              "
              to="/POLODocuments"
            >
              <p>POLO申請書</p>
              <div className="ml-3">
                <FontAwesomeIcon
                  icon={faFileAlt}
                />
              </div>
            </Link>
          </Can>
        )}
        { isModal &&
          <ApplyModal
            // setModalType={setModalType}
          />
        }
        {/*<ApplyModal*/}
        {/*  // setModalType={setModalType}*/}
        {/*/>*/}
      </main >
    </Layouts>
  )
}

export default UserSelect
