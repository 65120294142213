// *************** Common API URL *************** //
export const commonURL = `https://us-central1-connect-01-322502.cloudfunctions.net/main/api/v1`
// Get ALL Data URL
export const getRoleURL = `${commonURL}/role`
// dynamic Get sub Collection
export const dynamicGet = `${commonURL}/dynamicGet`
export const dynamicSubGet = `${commonURL}/dynamicSubGet`
// Dynamic where URL
export const getWhereURL = `${commonURL}/getAll`

// *************** GET API URL *************** //
// Top page URL
export const topURL = `${commonURL}/getUserCount`
export const getAdminClient = `${commonURL}/adminClient`
export const getRsoClient = `${commonURL}/getRsoClient`

// Users別GET API
export const staffsURL = `${getRoleURL}/staffs`
export const companiesURL = `${getRoleURL}/companies`
export const agenciesURL = `${getRoleURL}/agencies`
export const rsosURL = `${getRoleURL}/rsos`
export const kumiaiURL = `${getRoleURL}/kumiai`

// マスターデータ登録フォーム
export const sysKeyGetURL = `${commonURL}/getSysForm`
export const masterFormURL = `${getWhereURL}/sysKeyForm`

export const getClient = `${commonURL}/getHireClient`

// 応募者一覧
export const applicantURL = `${getWhereURL}/jobOffer`

// 企業一覧
// export const companiesWhereAgencies = `${getWhereURL}/companies`
export const companiesWhereAgencies = `${commonURL}/subDynamicGet/agencies`
// export const companiesWhereRsos = `${getWhereURL}/agencies`
export const companiesWhereRsos = `${commonURL}/subDynamicGet/rsos`

// 応募者か面接希望者（送り出し機関）
export const whereStaffURL = `${getWhereURL}/staffs`
export const interviewStaffsURL = `${getWhereURL}/staffs/nowStatus/==/interview`

// 履歴書出力
export const exportJobOffer = `${commonURL}/exportJobOffer`

// 候補者の採用データ（受け入れ企業）
export const recruitURL = `${getWhereURL}/staffs/nowStatus/==/recruit`

// VISA別ステータス一覧 入国前 or 入国後
export const specifiedEnterURL = `${getWhereURL}/staffs/visa/==/specificSkill`
export const traineeEnterURL = `${getWhereURL}/staffs/visa/==/skillTraining`
export const specifiedEnteredURL = `${getWhereURL}/staffs/visa/==/specificSkill`
export const traineeEnteredURL = `${getWhereURL}/staffs/visa/==/skillTraining`

// 職歴一覧
export const comStaffsURL = `${getWhereURL}/staffs/companies/==`

// WorkHistory Page URL
export const workHistoryURL = `${commonURL}/history`

// All DocumentMasters URL
export const documentMasterURL = `${commonURL}/documents`

// 特定技能申請書
export const specificSkillURL = `${commonURL}/specificSkill`
// 技能実習申請書
export const skillTrainingURL = `${commonURL}/skillTraining`
// POLO ALL document URL
export const POLODocURL = `${commonURL}/doc/polo`

// Status GET URL
export const getEnterURL = `${commonURL}/getStatus/statuses`
export const getEnteredURL = `${commonURL}/getStatus/enteredStatuses`
// Status personal
export const userStatus = `${commonURL}/staffStatus`

// *************** POST API URL *************** //
// Login
export const loginURL = `${commonURL}/login`
// dynamic object POST URL
export const dynamicPostURL = `${commonURL}/dynamicPost`
export const arrayDynamicURL = `${commonURL}/arrayPost`
// dynamic array POST URL
export const dynamicArrayURL = `${commonURL}/dynamicArray`

// Master
export const changeApplyURL = `${commonURL}/changeApply`
export const postUserURL = `${commonURL}/postRole`

// Status Post URL
export const postEnterURL = `${commonURL}/postStatus/statuses`
export const postEnteredURL = `${commonURL}/postStatus/enteredStatuses`

// Document Post URL
export const docPostURL = `${commonURL}/sendDoc`

// Career GET URL
export const userCareerGet = `${commonURL}/subDynamicGet/staffs`

// DocumentMaster Delete URL
export const deleteDocTemplate = `${commonURL}/docDelete`

// POST SysKey
export const sysKeyPostURL = `${commonURL}/sysKeyForm`

// 承認系API
export const sendEmailURL = `${commonURL}/sendApproval`
export const approvalURL = `${commonURL}/receiveApproval`
export const approvalAdminURL = `${commonURL}/receiveAdmin`

// POST Apply Data
export const postApplyURL = `${commonURL}/postApply`