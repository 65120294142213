// Modules
import React, {
  useEffect,
  useState
} from "react"
import { BrowserRouter } from "react-router-dom"
import {
  QueryClient,
  QueryClientProvider
} from "react-query"
import { ErrorBoundary } from 'react-error-boundary'
import { RecoilRoot } from 'recoil'
// Components
import { App } from './App'
import { Error } from "../components/Molecules/Error/Error"
// Config
import { AbilityContext } from "../config/Common/Can"
// Stylesheets
import "../stylesheets/Layouts/Layout.scss"
import { buildAbilityFor } from "../config/Common/Ability"
// Const
const queryClient = new QueryClient(
  {
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false
    }
  }
})

export const Index: React.VFC = () => {
  // *************** Const *************** //
  const data = JSON.parse(String(localStorage.getItem('user')))
  const [ability, setAbility] = useState(buildAbilityFor(''))

  useEffect(() => {
    if (!data) return
    setAbility(buildAbilityFor(
      data.admin ? 'admin' : data.userType
    ))
  }, [])

  // *************** JSX *************** //
  return (
    <div className="App">
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary FallbackComponent={Error}>
            <AbilityContext.Provider value={ability}>
              <BrowserRouter>
                <App />
              </BrowserRouter>
            </AbilityContext.Provider>
          </ErrorBoundary>
        </QueryClientProvider>
      </RecoilRoot>
    </div>
  )
}