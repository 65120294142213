import React from 'react'
import "../../../stylesheets/Layouts/Notification.scss"

const Notification = ({ openNotification }: any) => {
  const notifications = [{
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }, {
    text: "お知らせお知らせお知らせお知らせお知らせお知らせお知らせお知らせ"
  }]

  return (
    <div className={`notification ${openNotification ? "visible" : "unvisible"}`}>
      <ul>
        {notifications.map((n, i) =>
          <li key={i} className="font_12">{n.text}</li>
        )}
      </ul>
    </div>
  )
}

export default Notification