// Modules
import React, {
  useState
} from 'react'
import { Link } from "react-router-dom"
import { HashLink } from 'react-router-hash-link'
// Components
import { Title } from '../../components/Atoms/Title'
import { TitleSet } from '../../components/Molecules/Clients/TitleSet'
import { LoginInfo } from "../../components/Molecules/Layouts/LoginInfo"
import { OpenButton } from "../../components/Atoms/OpenButton"
// Function
import { ClientsFunctions } from '../../functions/Clients/ClientsFunctions'
// Images
import image1 from '../../Images/undraw_schedule_pnbk.svg'
import image2 from '../../Images/undraw_Hire_re_gn5j.svg'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Stylesheets
import '../../stylesheets/pages/Top/LandingPage.scss'
import '../../stylesheets/common.scss'
// Swiper
import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Mousewheel,
  Scrollbar,
  Autoplay
} from 'swiper'
SwiperCore.use([
  Navigation,
  Mousewheel,
  Scrollbar,
  Autoplay
])

export const StaffTop: React.VFC = () => {
  // *************** Const *************** //
  const {
    pageNav,
    sections,
    connectLists
  } = ClientsFunctions()

  const [list, setList] = useState<number>(0)

  // *************** JSX *************** //
  return (
    <div className="landingPage">
      <header className="lp-header">
        <TitleSet />
        <div className="flex items-center justify-between">
          <nav className="header-nav">
            <ul className="flex">
              { pageNav.map((page, i) => (
                <li key={i}>
                  { page.tag === 'anchor' ? (
                    <div className="linkContent">
                      <HashLink
                        className="flex items-center"
                        to={page.link}
                        smooth
                        scroll={(el: any) => el.scrollIntoView({
                          behavior: 'smooth',
                          block: 'start',
                          inline: 'start'
                        })}
                      >
                        <p>{page.text}</p>
                        <FontAwesomeIcon icon={page.icon} />
                      </HashLink>
                    </div>
                  ) : (
                    <div className="linkContent">
                      <Link
                        className="flex items-center"
                        to={page.link}
                      >
                        <p>{page.text}</p>
                        <FontAwesomeIcon icon={page.icon} />
                      </Link>
                    </div>
                  )}
               </li>
             ))}
           </ul>
          </nav>
          <LoginInfo
            className="ml1-rem"
          />
        </div>
      </header>
      <div className="lp-main">
        <div className="topWrap">
          <div className="topLeft">
            <div className="topTitle">
              <h1 className="inline-flex font-bold text-xl">
                <Title />
                <span>へようこそ！</span>
              </h1>
              <h2 className="inline-flex font-bold text-md">
                <span>Welcome to</span>&nbsp;<Title />!
              </h2>
            </div>
            <div className="topDetail">
              <p>さまざまなサービスで、日本で働く皆さんをサポートします！</p>
              <p className="flex justify-items-center">
                <span>Manage your life in Japan in </span>&nbsp;
                <Title />&nbsp;!
              </p>
            </div>
          </div>
          <p className="topImage">
            <img
              src={image1}
              alt="トップ画像"
            />
          </p>
        </div>
        {/* CONNECT説明 */}
        {/* <section className="aboutConnect">
          <h2>About <Title /></h2>
          <div className="about">
            <ul className="titleLists">
              { usage.map((u, k) => (
                <li key={k}>
                  <div
                    className={`listTitle
                      ${list === k ? 'active' : ''}
                    `}
                    onClick={() => switchIndex(k)}
                    >
                    <p>0{k+1}</p>
                    <p>{u.title}</p>
                  </div>
                </li>
              ))}
            </ul>
            <ul className="imageLists">
              { usage.map((u, k) => (
                <li
                  key={k}
                  className={`${list === k ? 'active' : ''}
                  `}
                >
                  <div className='aboutContents'>
                    <div className="aboutImage">
                      <img
                        src={u.img}
                        alt="使い方の画像"
                      />
                    </div>
                    <p>{u.detail}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="buttonWrap">
            { !data && (
              <div>
                <StartButton
                  text="Use CONNECT"
                  href="/Authentication"
                />
              </div>
            )}
            <div className="arrowWrap">
              <div
                className="circle"
                onClick={preIndex}
              >
                <FontAwesomeIcon
                  icon={faLongArrowAltLeft}
                />
              </div>
              <div
                className="circle"
                onClick={foreIndex}
              >
                <FontAwesomeIcon
                  icon={faLongArrowAltRight}
                />
              </div>
            </div>
          </div>
        </section> */}
         {/* CONNECTに遷移 */}
         <section className="connectWrap">
          <h2 className="fw-b fs25-px">
            ユーザー情報 / Your Information
          </h2>
          <ul className="mt2-per">
            { connectLists.map((list, i) => (
              <li
                className="dis-f ffd-col fai-center fjc-sb"
                key={i}
              >
                <FontAwesomeIcon
                  icon={list.icon}
                  size="5x"
                />
                <h3 className="fw-b fs2-rem">{list.title}</h3>
                <h4 className="fw-b">{list.enTitle}</h4>
                <p>{list.text}</p>
                <p>{list.enText}</p>
                <Link
                  className="mt3-per p1-rem"
                  to={list.href}
                >
                  {list.button}
                </Link>
              </li>
            ))}
          </ul>
        </section>
        {/* 他サイト紹介 */}
        <section
          className="providerWrap"
          id="service">
          <h2 className="fw-b fs25-px">
            サービス紹介 / Our services
          </h2>
          <div className="wrapFlex mt2-per">
            <p>
              <img src={image2} alt="プロバイダーの画像"/>
            </p>
            <div className="providerContents">
              <h3 className="fw-b fs25-px">
                便利なサービスで幸せな日本生活を！
              </h3>
              <h4 className="fw-b fs20-px">
                Colorful life with us!
              </h4>
              <p>日本の便利で安心なサービスを紹介します！生活や仕事にたくさん役に立ちます！</p>
              <p>Useful services: Make your live convenient</p>
            </div>
          </div>
          <ul>
            { sections.map((section, j) => (
              <li
                key={j}
                className="dis-f ffd-col h35-rem fjc-sb p2-per"
              >
                <div className="sectionImage p3-per">
                  <img
                    src={section.img}
                    alt="リンク先の画像"
                  />
                </div>
                <h3 className="dis-f ffd-col fw-b">
                  <span className="fs25-px">
                    { section.title }
                  </span>
                  <span className="mt2-per fs20-px">
                    {section.enTitle}
                  </span>
                </h3>
                <div className="sectionDetail">
                  <p>{section.detail}</p>
                  <p>{section.enDetail}</p>
                </div>
                <OpenButton
                  href={section.link}
                  text={section. text ? section.text : 'Go!'}
                />
              </li>
            ))}
          </ul>
        </section>
        {/* 使用感 */}
        {/* <section className="introduceWrap">
          <h2>伝えたいフレーズ</h2>
          <Swiper
            className="slideImages"
            speed={700}
            spaceBetween={10}
            scrollbar={{ draggable: true, hide: true }}
            autoplay={{ delay: 2500 }}
            navigation={{ clickable: true }}
            mousewheel
          >
            <SwiperSlide>
              <img
                src={image}
                alt="CONNECTを使用している画像（スライドショー）"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={image}
                alt="CONNECTを使用している画像（スライドショー）"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={image}
                alt="CONNECTを使用している画像（スライドショー）"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={image}
                alt="CONNECTを使用している画像（スライドショー）"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={image}
                alt="CONNECTを使用している画像（スライドショー）"
              />
            </SwiperSlide>
          </Swiper>
          <div className="slideImages">
          </div>
          <StartButton
            text="Take a look"
            href="/Authentication"
          />
        </section> */}
      </div>
      <footer className="lp-footer">
        <div className="footerContents">
          <TitleSet />
        </div>
        <div className="companyWrap">
          <strong>CONNECT was created Sion</strong>
        </div>
      </footer>
    </div>
  )
}