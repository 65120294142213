// Modules
import React from 'react'
// Components
import StaticHeader from "../../Layouts/StaticHeader";

export default function Help () {
  // *************** Contents JSX *************** //
  return (
    <div>
      <StaticHeader />
      <div className="pageContents">
        <h4>ヘルプ</h4>
      </div>
    </div>
  )
}