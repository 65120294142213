// Modules
import React from 'react'
import { NavLink } from "react-router-dom"
// Config
import {
  formToBStep,
  formToCStep
} from "../../../config/Master/MasterFormConfig"

// *************** Type *************** //
type Props = {
  baseUrl?: string
  userType?: string
}

export const ChangeForm: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const forms = props.userType === 'staffs' ? formToCStep : formToBStep

  // *************** JSX *************** //
  return (
    <ul className="
      formTab flex justify-between items-center mx-auto
    "
    >
      { forms.map((form: any, i: number) => (
        <li
          key={i}
          className="text-black"
        >
          <NavLink
            className="w-full p-3 rounded-lg shadow-xl bg-lightGray
              sm:p-2
            "
            to={`${props.baseUrl}/${form.step}`}
          >
            {form.title}
          </NavLink>
        </li>
      ))}
    </ul>
  )
}