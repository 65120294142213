// Modules
import axios from "axios"
// Config
import {
  commonURL,
  loginURL,
  sysKeyPostURL
} from '../config/Common/apiURL'

// *************** GET Functions *************** //
// Get FireStore Data
export const getAPI = async (url: string) => {
  const { data } = await axios.get(url)
  return data
}

// *************** POST Functions *************** //
// 連想配列にあるflgによって叩くAPIを変える
export const dynamicArrayAPI = async (
  url: string,
  data: any
) => {
  const {
    insert_flg,
    update_flg,
    delete_flg,
  } = data

  if (delete_flg) {
    const {
      delete_flg,
      ...postData
    } = data
    await axios.delete(url, postData)
  }
  // APIを叩く
  const res = await axios.post(url, postData)
}

export const postAPI = async (
  url: string,
  postData: any
) => {
  // APIを叩く
  const res = await axios.post(url, postData)
  console.log(res)
  return res
}

export const postData = async (
  mergeData: any,
  inputData: any,
  apiUrl: string
) => {
  // 入力データと別データをまとめる
  const postData = (Object.assign(mergeData, inputData))
  console.log (postData)
  // APIを叩く
  const res: any = axios.post(apiUrl, postData)
  // .catch(({response}) => {
  //   console.log(response)
  //   status = response.status
  //   return
  // })

  return await res // 結果を返す
}

export const postUserType = async (uId: string) => {
  const { data } = await axios.post(`${loginURL}/${uId}`)
  return data
}

export const postSysKeyForm = async (ary: any) => {
  const { data } = await axios.post(`${sysKeyPostURL}`, ary)
  return data
}

// *************** DELETE Functions *************** //
export const deleteData = async (
  mergeData: any,
  inputData: any,
  apiUrl: string
) => {
  // 入力データとsysKeyデータをまとめる
  const postData = (Object.assign(mergeData, inputData))
  // APIを叩く
  await axios.delete(apiUrl, { data: postData})
  .catch((err: any) => {
    return console.log(err.response)
  })
  // console.log(res)
  console.log('DELETE Success!!')
}

// *************** Dynamic API *************** //
export const api = axios.create({
  baseURL: commonURL
})

// 成功＆エラーの結果を返すようにする
api.interceptors.response.use(
  response => {
    return Promise.resolve(response)
  },
  error => {
    return Promise.reject({
      error: error.response
    })
  }
)