// Modules
import React, {
  useEffect,
  useState
} from 'react'
import { useQuery } from 'react-query'
import { useRecoilValue } from "recoil"
// API
import { getAPI } from '../../../functions/Api'
// Components
import { LoadingTable } from '../../Atoms/LoadingTable'
import { Error } from '../Error/Error'
// Config
import { storageState } from "../../../config/Common/RecoilState"
import {
  getRoleURL,
  userStatus
} from "../../../config/Common/apiURL"
import { translateFunc } from "../../../functions/CommonFunction"

export const PersonalStatus = () => {
  // *************** Const *************** //
  const { uId } = useRecoilValue(storageState)
  const [userState, setUserState] = useState<any>({})
  const [statusesData, setStatuses] = useState<any>([{}])

  // *************** React Query *************** //
  const promises = async () => {
    // 候補者個別データ
    const staffData = await getAPI(`${getRoleURL}/staffs/${uId}`)
    // 候補者個別ステータスデータ
    const statusData = await getAPI(`${userStatus}/${uId}`)
    return {
      staffData,
      statusData
    }
  }
  
  // *************** ReactQuery *************** //
  const {
    isFetching,
    isLoading,
    isError,
    data
  } = useQuery(
    "userAndStatuses",
    async () => promises(),
    {
      onSuccess: () => console.log("ALL DATAS WERE FETCHED CORRECTLY")
    }
  )

  // *************** useEffect *************** //
  useEffect(() => {
    if (isLoading) return
    // データ取れたらstateに格納
    if (data) {
      setUserState(data.staffData)
      setStatuses(data.statusData)
    }
  }, [isFetching])
  
  // *************** JSX *************** //
  return (
    <>
      { isLoading ? (
        <LoadingTable />
      ) : isError ? (
        <Error />
      ) : (
        <div className="ownStatuses">
          <h2 className="nowImmigration
            w-40 mx-auto mt-5 p-5 rounded-md skyblue text-2xl font-bold text-center
          "
          >
            { userState.immigration ? translateFunc(userState.immigration)['TabMenu']
              : '待機中'
            }
          </h2>
          <ul className="userStatus mt2-rem">
            { statusesData && statusesData
              .sort((a: any, b: any) => a.number - b.number)
              .map((status: any, i: number) => (
                <li key={i}>
                  { i !== 0 && (
                    <p>Next</p>
                  )}
                  <div className="statusContents">
                    <div className="flex">
                      <p>{status.label}</p>
                    </div>
                    <p
                      className={`
                        ${status.tagValue === 'ok' ? 'done' : ''}
                        ${status.tagValue === 'notOk' || !status.tagValue ? 'yet' : ''}
                      `}
                    >
                      { status.tagValue === 'ok' ? '完了'
                        : status.dateValue ? status.dateValue
                        : '未定'
                      }
                    </p>
                  </div>
                </li>
              ))
            }
          </ul>
        </div>
      )}
    </>
  )
}