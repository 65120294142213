// Modules
import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
// Components
import { HiredStaffs } from './HiredStaffs'
import { Button } from "../../Atoms/Button"
// Config
import { getWhereURL } from "../../../config/Common/apiURL"
import { storageState } from "../../../config/Common/RecoilState"

// *************** Type *************** //
type Props = {
  data?: any
}

export const RsoCount: React.VFC<Props> = ({
  data
}: Props) => {
  // *************** Const *************** //
  const { uId } = useRecoilValue(storageState)
  const [selectedCompany, setSelectedCompany] = useState<number>(-1) // 選択している企業
  const [selectedAgency, setSelectedAgency] = useState<number>(-1) // 選択している企業
  const [_selectedCId, set_selectedCId] = useState<string>("") // 選択している企業URL
  const clientList = [
    {
      label: '合計企業数',
      data: data.companies.length
    },
    {
      label: '合計送り出し機関数',
      data: data.agencies.length
    },
    {
      label: '合計採用者数',
      data: data.staffs
    }
  ]

  // *************** JSX *************** //
  return (
    <>
      <ul className="hiredCompanyNumberWrapper
        flex justify-between mt-2 mh-auto p-8 rounded-lg"
      >
        { clientList.map((client: any, c: number) => (
          <li
            key={c}
            className="empInformation"
          >
            <p className="pl-2 font-bold text-3xl">
              {client.label}：{client.data}
            </p>
          </li>
        ))}
      </ul>
      <div className="flex justify-around">
        <ul className="w-1/2 mt-16">
          { data.companies.map((d: any, i: number) => (
            <li
              key={i}
              className="hiredWrapper mt-12 first:mt-0 p-8 rounded-2xl"
            >
              <div className="companyDetails">
                <div className="empInformation">
                  <h3 className="flex mt-2 font-bold fs35-px">
                    <span>企業名：</span>
                    <Link
                      to={`/companies/detail/${d.id}`}
                    >
                      {d["company-name"]}
                    </Link>
                  </h3>
                </div>
                { selectedCompany === i &&
                <HiredStaffs
                  queryKey="companyStaff"
                  url={`${getWhereURL}/staffs/hired/==/${true}/companies/==/${_selectedCId}/rsos/==/${uId}`}
                />
                }
                {/* 選択されている会社のボタン */}
                <Button
                  classProps={`detailsButton w-full mt-5 py-2 px-4 font-bold text-xl 
                    ${selectedCompany === i ? "cancelButton" : "switchButton"}
                  `}
                  text={selectedCompany === i ? "閉じる" : "詳細"}
                  function={() => {
                    selectedCompany === i ? setSelectedCompany(-1)
                      : setSelectedCompany(i)
                    set_selectedCId(d["id"])
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
        <ul className="w-1/2 mt-16 ml-5">
          { data.agencies.map((a: any, i: number) => (
            <li
              key={i}
              className="hiredWrapper mt-12 first:mt-0 p-8 rounded-2xl"
            >
              <div className="companyDetails">
                <div className="empInformation">
                  <h3 className="flex mt-2 font-bold fs35-px">
                    <span>送り出し機関名：</span>
                    <Link to={`/agencies/detail/${a.id}`}>
                      {a["agency-name"]}
                    </Link>
                  </h3>
                </div>
                { selectedAgency === i &&
                <HiredStaffs
                  queryKey="agencyStaff"
                  url={`${getWhereURL}/staffs/hired/==/${true}/agency/==/${_selectedCId}/rsos/==/${uId}`}
                />
                }
                {/* 選択されている会社のボタン */}
                <Button
                  classProps={`detailsButton w-full mt-5 py-2 px-4 font-bold text-xl 
                    ${selectedAgency === i ? "cancelButton" : "switchButton"}
                  `}
                  text={selectedAgency === i ? "閉じる" : "詳細"}
                  function={() => {
                    selectedAgency === i ? setSelectedAgency(-1)
                      : setSelectedAgency(i)
                    set_selectedCId(a["id"])
                  }}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}