// Modules
import React from 'react'
import { Image } from '../../Atoms/Image'
import { OpenButton } from "../../Atoms/OpenButton"
// Config
import pdfImage from '../../../Images/PDF.png'
// Swiper components
import 'swiper/swiper.scss'
import 'swiper/components/pagination/pagination.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, {
  Navigation,
  Pagination,
  Mousewheel
} from 'swiper'
SwiperCore.use([
  Navigation,
  Pagination,
  Mousewheel
])

// *************** Type *************** //
type Props = {
  id: string
  data?: any
}

export const DetailsImages: React.VFC<Props> = (
  props: Props
) => {
  // *************** JSX *************** //
  return (
    <div className="">
      <Swiper
        className="swiperImage w30-per h100-per mh-auto mt5-per"
        speed={700}
        spaceBetween={30}
        pagination={{ type: 'progressbar' }}
        navigation
        mousewheel
        loop
      >
        { props.data.map((data: any, i: number) => (
          <SwiperSlide
            key={i}
            className=" dis-f ffd-col fjc-center"
          >
            <p className="mt5-per fw-b fs20-px">{data.label}</p>
            <div className="dis-f ffd-col fjc-center  h20-rem">
              <Image
                className='h100-per of-con'
                src={ data.name && data.name.substr(-3) === 'pdf' ?
                  pdfImage : data.path
                }
                alt={data.name}
              />
            </div>

            {/*<OpenButton*/}
            {/*  className=""*/}
            {/*  href={fileLinks[i]}*/}
            {/*>*/}
            {/*  <Image*/}
            {/*    src={data.path}*/}
            {/*    alt={data.name}*/}
            {/*  />*/}
            {/*</OpenButton>*/}

            <OpenButton
              className="updateButton mt3-per p2-per"
              href={data.url && data.url}
              text="ダウンロード"
            />
          </SwiperSlide>

          // <MoveButton
          //   className="updateButton mt3-per p2-per"
          //   href={fileLinks[i]}
          //   text="ダウンロード"
          // />

        ))}
      </Swiper>
    </div>
  )
}
