// Modules
import React, {
  useState
} from "react"
import {
  useQuery,
} from 'react-query'
import { Link } from "react-router-dom"
import { useRecoilValue } from "recoil"
// Components
import { LoadingTable } from '../../Atoms/LoadingTable'
import { Button } from "../../Atoms/Button"
import { HiredStaffs } from "./HiredStaffs"
// API
import { getAPI } from "../../../functions/Api"
// Config
import { getWhereURL } from "../../../config/Common/apiURL"
import { storageState } from "../../../config/Common/RecoilState"

// *************** Type *************** //
type Props = {
  rso?: any
  queryKey1: string
  queryKey2: string
  userType: string
  nameData: string
  label: string
  url: string
}

export const HireUsers: React.VFC<Props> = (
  props: Props
) => {
  // *************** React Query *************** //
  const [staffIndex, setStaffIndex] = useState<number>()
  const [selectedCId, setSelectedCId] = useState<string>('')

  // *************** React Query *************** //
  const {
    isLoading,
    isError,
    data
  } = useQuery(
    props.queryKey1,
    () => getAPI(props.url)
  )

  // *************** JSX *************** //
  return (
    <div className="hiredDetails mt-2 p-3 rounded-md bg-white">
      { isLoading ? (
        <LoadingTable />
      ) : isError ? (
        <p className="font-bold">データ取得に失敗しました。。。</p>
      ) : (
        <>
          <h2 className="font-bold fs2-rem">
            {props.label}情報
          </h2>
          <h3 className="mt-3 font-bold text-xl">
            合計{props.label}数：{data.length}
          </h3>
          <ul className="companyList mt-3">
            { data.map((d: any, i: number) => (
              <li key={i}>
                <div className="v_line_fix flex items-center">
                  <div className="flex items-center font-bold fs20-px">
                    <span>{props.label}名：</span>
                    <Link
                      to={`/${props.userType}/detail/${d.id}`}
                    >
                      {d[props.nameData]}
                    </Link>
                  </div>
                  <Button
                    classProps={`ml-3 py-1 px-16 font-bold text-md 
                    ${ staffIndex === i ? "cancelButton" : "switchButton" }
                  `}
                    text={ staffIndex === i ? "閉じる" : "詳細" }
                    function={() => {
                      staffIndex === i ? setStaffIndex(-1)
                        : setStaffIndex(i)
                      setSelectedCId(d["id"])
                    }}
                  />
                </div>
                { staffIndex === i &&
                  <HiredStaffs
                    queryKey={props.queryKey2}
                    url={`${getWhereURL}/staffs/companies/==/${selectedCId}/rsos/==/${props.rso.id}/hired/==/true`}
                  />
                }
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  )
}