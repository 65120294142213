// Modules
import React, {
  useState
} from 'react'
import {
  useAsyncDebounce
} from "react-table"
// Components
import { Input } from "../../Atoms/Input"

// *************** Type *************** //
type Props = {
  className?: string
  sticky: boolean
  preGlobalFilteredRows: any,
  globalFilter: any,
  setGlobalFilter: any
}

export const FilterGlobal: React.VFC<Props> = ({
  sticky,
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  // *************** Const *************** //
  const [value, setValue] = useState<any>(globalFilter)
  const count = preGlobalFilteredRows.length

  const onChange = useAsyncDebounce((value: any) => {
    setGlobalFilter(value || undefined)
  }, 200)

  // *************** JSX *************** //
  return (
    <div
      className={`flex items-center sticky top-0 left-0 p-3 bg-white
        ${sticky ? 'w-96' : ''} 
      `}
    >
      <span>検索（全データ数{count}）:{' '}</span>
      <Input
        className="flex-1 ml-2 p-1 bg-blue-100"
        value={value || ""}
        function={(e: React.ChangeEvent<HTMLInputElement>) => {
          setValue(e.target.value)
          onChange(e.target.value)
        }}
        placeholder="検索..."
      />
    </div>
  )
}