// Modules
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  ReactChild,
} from "react"
import { useParams } from "react-router-dom"
import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query"
import { useRecoilValue } from "recoil"
import {
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  useTable
} from "react-table"
import { useSticky } from "react-table-sticky"
// API
import {
  getAPI,
  postAPI
} from "../../functions/Api"
// Components
import { Layouts } from "../../Layouts/Layouts"
import { LoadingTable } from "../../components/Atoms/LoadingTable"
import Error from "../../components/Molecules/Error/Error"
import { Table } from "../../components/Molecules/Common/Table"
import { Button } from "../../components/Atoms/Button"
import { ApplyModal } from "../../components/Molecules/Interview/ApplyModal"
import { UserMemoModal } from "../../components/Molecules/Master/UserMemoModal"
import { DetailModal } from "../../components/Molecules/Master/DetailModal"
// Function
import { CommonFunctions } from "../../functions/CommonFunction"
// Config
import {
  modalState,
  storageState
} from "../../config/Common/RecoilState"

// *************** Type *************** //
type Props = {
  queryKey: string
  roleData: string
  url: string
  postUrl: string
  columns?: any
  theadFixed: Array<any>
  thead: Array<any>
  tbodyFixed: Array<any>
  tbody: Array<any>
  buttonText?: string
  buttonDefault?: any
  buttonValue?: any
  headerTab?: any
  selectUser?: any
  tabAbility?: any
  apply: boolean
  status: boolean
  memo: boolean
  jobOffer: boolean
}

export const MasterCatalog: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { cId, userId } = useParams<Record<any, any>>()
  const isModal = useRecoilValue(modalState)
  const { userType, uId } = useRecoilValue(storageState)
  const [modalData, setModalData] = useState<any>({})
  const [dataState, setDataState] = useState<any>([{}])
  const [modalType, setModalType] = useState<string>("detail")
  const [modalPosY, setModalPosY] = useState<number>(0)
  const ref: any = useRef()
  const { commons } = CommonFunctions()

  // *************** React Query *************** //
  const {
    isLoading,
    isFetching,
    isError,
    data: getData
  } = useQuery(
    props.queryKey,
    async () => getAPI(
      cId ? `${props.url}/${cId}`
      : userId ? `${props.url}/${userId}/${userType}/==/${uId}`
      : props.url
    )
  )

  const queryClient = useQueryClient()
  const mutation = useMutation(data => postAPI(
    props.postUrl,
    data
  ), {
    onError: async previousValue => {
      // キャッシュに元のデータをセットする
      queryClient.setQueryData(props.queryKey, previousValue)
      console.log('更新に失敗しました。')
    },
    onSuccess: async () => {
      // キャッシュデータを再度取得
      const query: any = await queryClient.fetchQuery(props.queryKey)
      // stateに格納
      setDataState(query)
    }
  })

  useEffect(() => {
    if (isLoading) return
    else setDataState(getData)
  }, [isFetching])

  useEffect(() => {
    !isModal && setModalType('detail')
  }, [isModal])

  const [columns, data] = useMemo(
    () => {
      const columns = [
        {
          width: 75,
          Header: '詳細',
          Cell: (cell: any) => (
            <p
              className="rounded-full bg-skyBlue text-white font-bold text-sm cursor-pointer"
              ref={ref}
              onClick={() => {
                commons.openModal()
                setModalPosY(ref.current.getBoundingClientRect().top / 25 * (cell.row.index + 1))
                setModalData({
                  index: cell.row.index,
                  ...dataState[cell.row.index]
                })
              }}
            >
              詳細
            </p>
          ),
          sticky: 'left'
        },
        ...props.columns
      ]
      return [columns, dataState]
    },
    [dataState]
  )
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useSticky,
    useBlockLayout,
    useFilters,
    useGlobalFilter
  )

  // *************** modals *************** //
  const Modals: { [key: string]: ReactChild } = {
    "ApplyManually":
      <ApplyModal
        setModalType={setModalType}
      />,
    "detail": (
      <DetailModal
        modalPosY={modalPosY}
        modalData={modalData}
        setModalType={setModalType}
        roleData={props.roleData}
        buttonText={props.buttonText}
        buttonDefault={props.buttonDefault}
        buttonValue={props.buttonValue}
        dataState={dataState}
        setDataState={setDataState}
        apply={props.apply}
        status={props.status}
        memo={props.memo}
        jobOffer={props.jobOffer}
      />
    ),
    "resume": (
      <UserMemoModal
        setModalType={setModalType}
        modalData={modalData}
        dataState={dataState}
        setDataState={setDataState}
      />
    )
  }

  // *************** JSX *************** //
  return (
    <Layouts
      headerTab={props.headerTab}
      tabAbility={props.tabAbility}
      selectUser={props.selectUser}
    >
      <main className="masterCatalog">
        { isLoading ? (
          <LoadingTable />
        ) : isError ? (
          <Error />
        ) : (
          <Table
            {...tableInstance}
            className="h-full"
            sticky={true}
          />
        )}
        {/* 詳細＆履歴書モーダル */}
        { isModal && Modals[modalType]}

        <div className="flex flex-col fixed bottom-3 right-3">
          { props.apply &&
            <Button
              classProps="switchButton py-3 px-14"
              text="応募"
              function={() => {
                commons.openModal()
                setModalType("ApplyManually")
              }}
            />
          }
          <Button
            classProps={`updateButton mt-2 py-3 px-14
              ${mutation.isLoading && 'disable'}
              ${mutation.isSuccess && 'success'}
              ${mutation.isError && 'error'}
            `}
            text={mutation.isLoading
              ? '更新中・・・' : mutation.isSuccess
              ? '更新完了' : mutation.isError
              ? 'エラー' : '更新'
            }
            function={() => commons.postArrayMutation(
              mutation,
              [],
              dataState
            )}
          />
        </div>
      </main>
    </Layouts>
  )
}