// Modules
import React, {
  useState
} from 'react'
// Components
import { Button } from "../../Atoms/Button"
import { PartnerList } from "./PartnerLiist"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
// Config
import { Can } from "../../../config/Common/Can"

// *************** Type *************** //
type Props = {
  companies?: any
  agencies?: any
  mutation?: any
}

export const SetSyskeyPartner: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { commons } = CommonFunctions()
  const [formInput, setFormInput] = useState<any>({})
  
  // *************** Array *************** //
  const partnerList = [
    {
      name: 'companies',
      label: '企業',
      options: props.companies,
      optionKey: 'company-name',
      optionValue: 'id',
      can: 'companyList'
    },
    {
      name: 'agencies',
      label: '送り出し機関',
      options: props.agencies,
      optionKey: 'agency-name',
      optionValue: 'id',
      can: 'agencyList'
    }
  ]
  console.log (formInput)

  // *************** JSX *************** //
  return (
    <div className="partnerWrap flex flex-col justify-between">
      <ul className="wrapContent">
        { partnerList.map((list: any, i: number) => (
          <Can
            I="read"
            a={list.can}
            key={i}
          >
            <PartnerList
              index={i}
              name={list.name}
              label={list.label}
              options={list.options}
              optionKey={list.optionKey}
              optionValue={list.optionValue}
              state={formInput}
              setState={setFormInput}
            />
          </Can>
        ))}
      </ul>
      <Button
        classProps={`updateButton w-full mt-5 pv5-per
          ${ props.mutation.isLoading && 'disable'} 
          ${ props.mutation.isSuccess && 'success'} 
          ${ props.mutation.isError && 'error'}
        `}
        text={ props.mutation.isLoading ? 'セット中...'
          : props.mutation.isSuccess ? 'セット完了'
          : props.mutation.isError ? 'エラーが発生しました'
          : 'セット'
        }
        function={() => commons.postMutation(
          props.mutation,
          {},
          formInput,
        )}
      />
    </div>
  )
}