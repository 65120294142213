// Modules
import React from 'react'

// *************** Type *************** //
type Props = {
  data?: any
  label?: Array<any>
}

export const DetailsUser: React.VFC<Props> = (
  props: Props
) => {
  // *************** JSX *************** //
  return (
    <>
      { props.data ? (
        <ul className="detailsContent lg:w-3/4 mh-auto mt5-per">
          { props.label && props.label.sort((a, b) => a.number - b.number)
          .map((l: any, i: number) => (
            <li key={i}>
              <p className="key">{l.label}</p>
              <p className="value">
                { props.data && props.data[l.name] }
              </p>
            </li>
          ))}
        </ul>
      ) : (
        <div className="nonData">
          <p>データがありません</p>
        </div>
      )}
    </>
  )
}