// Modules
import React, {
    useEffect,
    useState
} from 'react'
import {
    useQuery,
    useMutation
} from 'react-query'
import { useRecoilValue } from "recoil"
import Cookie from 'js-cookie'
// API
import {
    getAPI,
    postAPI
} from "../../functions/Api"
// Components
import { LoadingTable } from '../../components/Atoms/LoadingTable'
import { Error } from '../../components/Molecules/Error/Error'
// Config
import { storageState } from "../../config/Common/RecoilState"
import {
    getRoleURL,
    companiesURL,
    commonURL
} from "../../config/Common/apiURL"
// Stylesheets
import '../../stylesheets/pages/ApplyCompany/ApplyCompany.scss'

export const ApplyCompany: React.VFC<any> = () => {
    // *************** Const *************** //
    const { uId } = useRecoilValue(storageState)
    const [userDataState, setUserDataState] = useState<any>([{}]) // ユーザのデータ
    const [conpanyDetails, setConpanyDetails] = useState<any>([{}]) // 会社一覧データ
    // const [statusesData, setStatuses] = useState<any>([{}])

    const mutation = useMutation((data: any) => postAPI(
        `${commonURL}/postData/jobOffer/${uId}${data["cId"]}`,
        data
    ), {
        onError: async () => console.log('更新に失敗しました。'),
        onSuccess: async () => {
            console.log('更新にせいこうしました。')
        }
    })

    const methods = {
        // とってくるデータを選別してpromiseする関数
        getDataFunc: async () => {
            // とってくるべきデータ
            let promises = []
            promises.push(getAPI(`${getRoleURL}/staffs/${uId}`))
            promises.push(getAPI(`${companiesURL}`))
            // promises.push(getAPI(getEnterURL))

            // プロミスオール
            return await Promise.all(promises)
        },
        sendApply: (id: String, agencies: any) => {
            // postデータのタイプ
            type postDataType = {
                aId: String,
                cId: String,
                id: String,
                nowStatus: String // この"apply"によって応募者一覧に追加されます！
                applicantName: String// staffDBのstaff-family, staff-first, staff-middleを取得してくっつける,
                applicantGender: String // 性別
                applicantAge: number, // 年齢
                applicantCountry: String, // 国
                applicantNumber: String, // 電話番号
                applicantEmail: String, // メールアドレス
            }

            let _postData: postDataType = {
                aId: agencies,
                cId: id,
                id: uId,
                nowStatus: "apply",
                applicantName: (userDataState["staff-family"] ? userDataState["staff-family"] : "") + (userDataState["staff-first"] ? userDataState["staff-first"] : "") + (userDataState["staff-middle"] ? userDataState["staff-middle"] : ""), // staffDBのstaff-family, staff-first, staff-middleを取得してくっつける,
                applicantGender: userDataState["staff-gender"],
                applicantAge: userDataState["staff-age"],
                applicantCountry: userDataState["staff-country"],
                applicantNumber: userDataState["staff-number"],
                applicantEmail: userDataState["staff-email"]
            }

            mutation.mutate(_postData)
        }
    }

    // *************** ReactQuery *************** //
    const {
        isFetching,
        isLoading,
        isError,
        data
    } = useQuery(
      "userAndStatuses",
      () => methods.getDataFunc(),
      {
          onSuccess: () => console.log("ALL DATAS WERE FETCHED CORRECTLY")
      }
    )

    // *************** useEffect *************** //
    useEffect(() => {
        if (isLoading) return
        // データ取れたらstateに格納
        if (data) {
            setUserDataState(data[0])
            setConpanyDetails(data[1])
        }
    }, [isFetching])


    // *************** JSX *************** //
    if (isFetching) return (<LoadingTable />)
    else if (isError) return (<Error />)
    return (
        <main className="applyCompany">
            <h2 className="mt2-per fw-b fs35-px ta-c">
                {/* TODO：名前を決める */}
                求人サイトページ(仮)
            </h2>
            <ul>
                { conpanyDetails.map((d: any, i: number) => (
                    <li
                        key={i}
                        className={"applyWrapper"}>

                        <div className="v_line_fix">
                            <p className="mt2-per fw-b fs35-px ta-l">会社名：{d["company-name"]}</p>
                        </div>

                        <p className="mt2-per fw-b fs18-px ta-l">住所：{d["company-officeAdd"]} </p>
                        <p className="mt2-per fw-b fs18-px ta-l">メールアドレス:{d["company-emailAddress"]} </p>

                        <button
                            disabled={mutation.isLoading}
                            className={`switchButton 
                                mt2-per fw-b fs25-px ta-l postButton
                                ${mutation.isLoading && 'disable'}
                                ${mutation.isSuccess && 'success'}
                                ${mutation.isError && 'error'}
                            `}
                            // TODO: 在送信している送り出し期間がnullになっている
                            onClick={() => methods.sendApply(d.id, null)}
                        >
                            { mutation.isLoading ? "送信中..." :
                              mutation.isSuccess ? "受け付け済み" :
                              mutation.isError ? "送信に失敗しました" : "POST"
                            }
<ul></ul>                        </button>

                    </li>
                ))}
            </ul>
        </main>
    )
}