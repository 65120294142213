// Modules
import React from 'react'
import { useParams } from "react-router-dom"
import { useMutation } from "react-query"
import { useRecoilValue } from "recoil"
// Components
import { Button } from "../../components/Atoms/Button"
// Functions
import { CommonFunctions } from "../../functions/CommonFunction"
import { postAPI } from "../../functions/Api"
// Config
import {
  approvalAdminURL,
  approvalURL
} from "../../config/Common/apiURL"
import { storageState } from "../../config/Common/RecoilState"
import { Layouts } from "../../Layouts/Layouts"

export const ApprovalUser: React.VFC = () => {
  // ***************  Const *************** //
  const {
    uId,
    userType,
    cId
  } = useParams<Record<string, string>>()
  const { admin } = useRecoilValue(storageState)
  const { commons } = CommonFunctions()
  // useMutation
  const mutation = useMutation(data => postAPI(
    admin ? `${approvalAdminURL}/${userType}/${uId}` :
    `${approvalURL}/${uId}/${cId}`,
    data
  ), {
    onError: async () => { console.log('更新に失敗しました。') },
    onSuccess: async () => { console.log('更新成功！！') }
  })

  // *************** JSX *************** //
  return (
    <Layouts>
      <main className="mt-20 text-center">
        <h2 className="font-bold fs25-px">
          承認しますか？
        </h2>
        <div>
          <div>
            <div className="inline-flex mt-5">
              <Button
                classProps="cancelButton ph2-rem pv5-px"
                text="トップへ戻る"
                function={() => commons.movePage("/")}
              />
              <Button
                classProps={`updateButton
                  ml2-rem ph2-rem pv5-px
                  ${ mutation.isLoading && 'disable'} 
                  ${ mutation.isSuccess && 'success'} 
                  ${ mutation.isError && 'error'}
                `}
                text={ mutation.isLoading ? '承認中...'
                  : mutation.isSuccess ? '成功'
                  : mutation.isError ? 'エラー'
                  : '承認'
                }
                function={() => commons.postMutation(
                  mutation,
                  {},
                  {}
                )}
              />
            </div>
            <div className="mt-5">
              <strong>
                承認すると承認先からユーザーの情報が閲覧可能になりますが、よろしいですか？
              </strong>
            </div>
          </div>
        </div>
      </main>
    </Layouts>
  )
}

export default ApprovalUser