// Modules
import React from 'react'
// Components
import { Logo } from '../../Atoms/Logo'
import { Title } from '../../Atoms/Title'

export const TitleSet: React.VFC = () => (
  // *************** JSX *************** //
  <div className="titleWrap">
    <Logo />
    <Title />
  </div>
)