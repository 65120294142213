// Modules
import React, {
  useEffect,
  useState
} from 'react'
import { Link } from 'react-router-dom'
// Components
import { Button } from "../../Atoms/Button"
import { HireUsers } from "./HireUsers"
// Functions
import { MasterFunctions } from "../../../functions/Master/MasterFunctions"
// Config
import { companiesWhereRsos } from "../../../config/Common/apiURL"

// *************** Type *************** //
type Props = {
  rsos?: any
  companies?: any
  agencies?: any
  staffs?: any
}

export const AdminCount: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { masters } = MasterFunctions()
  const [agenciesIndex, setAgenciesIndex] = useState<number>()
  const [companiesIndex, setCompaniesIndex] = useState<number>()
  const [selectedRId, setSelectedRId] = useState<string>('')
  const [sumStaffs, setSumStaffs] = useState<number>()
  const [sumAgencies, setSumAgencies] = useState<number>()
  const [sumCompanies, setSumCompanies] = useState<number>()
  const clientList = [
    {
      label: '総計登録支援機関数',
      data: props.rsos.length
    },
    {
      label: '総計送り出し機関数',
      data: sumAgencies
    },
    {
      label: '総計企業数',
      data: sumCompanies
    },
    {
      label: '総計採用者数',
      data: sumStaffs
    }
  ]

  useEffect(() => {
    if (!props.rsos) return
    masters.calcHiredNumber(
      props.rsos,
      'staffs',
      setSumStaffs
    )
    masters.calcHiredNumber(
      props.rsos,
      'companies',
      setSumCompanies
    )
    masters.calcHiredNumber(
      props.rsos,
      'agencies',
      setSumAgencies
    )
  }, [props])

  // *************** JSX *************** //
  return (
    <>
      <ul className="hiredCompanyNumberWrapper
        flex justify-between mt-5 mx-auto p-8 rounded-2xl"
      >
        { clientList.map((client: any, c: number) => (
          <li
            key={c}
            className="empInformation"
          >
            <p className="pl-2 font-bold fs2-rem">
              {client.label}：{client.data}
            </p>
          </li>
        ))}
      </ul>
      <ul className="flex flex-col mt-20">
        { props.rsos.map((rso: any, i: number) => (
          <li
            key={i}
            className="hiredWrapper w-11/12 first:mt-0 mt-10 mx-auto p-10 rounded-2xl"
          >
            <div className="companyDetails">
              <div className="empInformation">
                <h3 className="flex mt2-per text-white font-bold fs35-px">
                  <span className="text-white">登録支援機関名：</span>
                  <Link
                    to={`/rsos/detail/${rso.id}`}
                  >
                    { rso["rso-name"] ? rso["rso-name"] : '名無し' }
                  </Link>
                </h3>
                <div className="clientInfo mt-2">
                  <p className="pl-2 font-bold">総連携受け入れ企業数：{rso.companies}</p>
                  <p className="pl-2 font-bold">総連携送り出し機関数：{rso.agencies}</p>
                  <p className="pl-2 font-bold">総採用候補者数：{rso.staffs}</p>
                </div>
              </div>
              { companiesIndex === i &&
                <HireUsers
                  rso={rso}
                  queryKey1="hiredCompany"
                  queryKey2="companyStaff"
                  userType="companies"
                  nameData="company-name"
                  label="受け入れ企業"
                  url={`${companiesWhereRsos}/${selectedRId}/companiesList`}
                />
              }
              <Button
                classProps={`w-full mt-3 p-3 font-bold text-xl 
                  ${ companiesIndex === i ? "cancelButton" : "switchButton" }
                `}
                text={ companiesIndex === i ? "閉じる" : "受け入れ企業詳細" }
                function={() => {
                  companiesIndex === i ? setCompaniesIndex(-1) : setCompaniesIndex(i)
                  setSelectedRId(rso["id"])
                }}
              />
              { agenciesIndex === i &&
              <HireUsers
                rso={rso}
                queryKey1="hiredAgency"
                queryKey2="agencyStaff"
                userType="agencies"
                nameData="agency-name"
                label="送り出し機関"
                url={`${companiesWhereRsos}/${selectedRId}/agenciesList`}
              />
              }
              <Button
                classProps={`w-full mt-3 p-3 font-bold text-xl 
                  ${ agenciesIndex === i ? "cancelButton" : "switchButton" }
                `}
                text={ agenciesIndex === i ? "閉じる" : "送り出し機関詳細" }
                function={() => {
                  agenciesIndex === i ? setAgenciesIndex(-1)
                    : setAgenciesIndex(i)
                  setSelectedRId(rso["id"])
                }}
              />
            </div>
          </li>
        ))}
      </ul>
    </>
  )
}