import React from 'react'
import StaticHeader from "../../Layouts/StaticHeader"

export const Contact = () => {
  return (
    <div>
      <StaticHeader />
      <h2>何かお探しですか？</h2>
    </div>
  )
}

export default Contact