// Modules
import React, {
  useState
} from "react"
// Components
import { Label } from "../../Atoms/Label"
import { Input } from "../../Atoms/Input"
// Functions
import { CommonFunctions } from "../../../functions/CommonFunction"
import { AuthFunctions } from "../../../functions/Authentication/AuthFunctions"
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faEye,
  faEyeSlash,
  faUnlockAlt
} from "@fortawesome/free-solid-svg-icons"

// *************** Type *************** //
type Props = {
  component: string
  passwordLabel: string
  value?: any
  setValue?: any
  errorMessage?: any
  setErrorMessage?: any
}

export const AuthForm: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { auth } = AuthFunctions()
  const { commons } = CommonFunctions()
  const [passOpen, setPassOpen] = useState<boolean>(false)

  // *************** JSX *************** //
  return (
    <div className="loginForm mt-2 mx-auto text-center">
      <ul className="formList text-center">
        <li>
          <div
            className="inputSet flex mx-auto relative
              lg:w-1/2
            "
          >
            <div className="inlineItems">
              <FontAwesomeIcon icon={faEnvelope} />
              <Label
                className="sm:text-xs"
                htmlFor="email"
                text="メールアドレス"
              />
            </div>
            <Input
              className="formInput w-full py-2 pr-7 pl-44 rounded-sm"
              name="email"
              type="email"
              value={ props.value && props.value.email ? props.value.email : "" }
              function={(e: React.ChangeEvent<HTMLInputElement>) => {
                commons.checkValidation(
                  true,
                  e.target.name,
                  'メールアドレス',
                  /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/,
                  e.target.value,
                  'メールアドレスは半角英数字@ ~ . ~ の形で入力してください。',
                  props.setErrorMessage,
                  false
                )
                commons.setValue(
                  'email',
                  e.target.value,
                  props.setValue
                )
              }}
            />
          </div>
          { props.errorMessage && (
            <p className="errorMessage mt-2">
              { props.errorMessage.email }
            </p>
          )}
        </li>
        { props.component !== "forget" && (
          <li>
            <div className="inputSet flex mx-auto relative
              lg:w-1/2"
            >
              <div className="inlineItems">
                <FontAwesomeIcon icon={faUnlockAlt} />
                <Label
                  className="sm:text-xs"
                  htmlFor="password"
                  text="パスワード"
                />
              </div>
              <Input
                className="formInput w-full py-2 pr-7 pl-44 rounded-sm"
                name="password"
                type={passOpen ? "text" : "password"}
                value={ props.value.password ? props.value.password : "" }
                function={(e: React.ChangeEvent<HTMLInputElement>) => {
                  commons.checkValidation(
                    true,
                    e.target.name,
                    'パスワード',
                    /^([a-zA-Z0-9]{6,})$/,
                    e.target.value,
                    'パスワードは6文字以上の半角英数字で入力してください。',
                    props.setErrorMessage,
                    false
                  )
                  commons.setValue(
                    'password',
                    e.target.value,
                    props.setValue
                  )
                }}
              />
              <div
                className="passwordEye"
                onClick={() => auth.passwordOpen(
                  setPassOpen,
                  passOpen
                )}
              >
                { passOpen ? <FontAwesomeIcon icon={faEye} />
                  : <FontAwesomeIcon icon={faEyeSlash} />
                }
              </div>
            </div>
            { props.errorMessage && (
              <p className="errorMessage">
                { props.errorMessage.password }
              </p>
            )}
          </li>
        )}
      </ul>
    </div>
  )
}