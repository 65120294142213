// Modules
import React, {
  useState
} from "react"
import {
  useHistory,
  Link
} from "react-router-dom"
// Components
import Notification from "../components/Molecules/Layouts/Notification"
import { Logo } from "../components/Atoms/Logo"
import { Button } from "../components/Atoms/Button"
// Config
import { Can } from "../config/Common/Can"
// Stylesheets
import '../stylesheets/Layouts/Menu.scss'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from "@fortawesome/free-regular-svg-icons"
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { adminMenu } from "../config/Layouts/LayoutsConfig"

// *************** Type *************** //
type Props = {
  admin: boolean
  user: string
  uId: string
}

export const Menu: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const history = useHistory()

  // const iconMenu = [
  //   // { icon: faQuestion, link: '/Help', hover: 'ヘルプ' },
  //   // { icon: faCog, link: '#', hover: '設定' },
  // ]

  const menuList = props.admin ? adminMenu : [
    {
      list: "申請書",
      link: props.user === 'companies' ? `/document/${props.uId}` : `/document/${props.user}`,
      can: 'documentMenu'
    },
    {
      list: "人材管理",
      link: props.user === 'companies' ? `/staffs/${props.uId}` : `/staffs/${props.user}`,
      can: 'masterMenu'
    },
    {
      list: "ステータス",
      link: props.user === 'companies' ? `/SpecifiedSkill/enterStatus/${props.uId}`
      : `/SpecifiedSkill/enterStatus/${props.user}`,
      can: 'statusMenu'
    },
    {
      list: "面接",
      link: props.user === 'companies' ? `/interviewStaffs/${props.uId}` : `/interviewStaffs/${props.user}`,
      // link: props.user === 'agencies' ? `/Apply/${props.user}`
      // : `/Interview/${props.user}`,
      can: 'interviewMenu'
    },
    // {
    //   list: "投稿",
    //   link: props.user === 'companies' ? `/post/${props.uId}` : `/post/${props.user}`,
    //   can: 'postMenu'
    // },
    {
      list: "職歴",
      link: props.user === 'companies' ? `/career/${props.uId}` : `/career/${props.user}/`,
      // link: `/Career/${props.user}`,
      can: 'careerMenu'
    }
  ]

  // const popupList = [
  //   // { icon: faFileAlt, menu: "利用規約", link: '/Rule' },
  //   // { icon: faShieldAlt, menu: "プライバシーポリシー", link: '/Policy' },
  // ]

  // *************** Const *************** //
  const [openMenu, setOpenMenu] = useState<boolean>(false)
  const [popupMenu, setPopupMenu] = useState<any>()
  const [openNotification, setOpenNotification] = useState<boolean>(false)
  // const [openSub, setOpenSub] = useState(false)

  // *************** functions *************** //
  const popupOn = (i: any) => setPopupMenu(i)
  const popupOff = () => popupMenu && setPopupMenu('')

  // *************** JSX *************** //
  return (
    <div className="menuComponent">
      <div className="alwaysMenu">
        <div className="topIcons">
          <div className="logoIcon">
            <Logo />
          </div>
          <div className="menuIcon">
            <div className="menuIcon-link"
              onClick={() => {
                setOpenMenu(false)
                history.push("/")
              }}
              onMouseEnter={() => setOpenMenu(false)}
            >
              <FontAwesomeIcon icon={faHome} />
            </div>
          </div>
          <div className="menuIcon">
            <div className="menuIcon-link"
              onClick={() => setOpenMenu(!openMenu)}
              onMouseEnter={() => {
                popupOff()
                setOpenMenu(true)
                setOpenNotification(false)
              }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </div>
          </div>
          {/* <div className="menuIcon">
            <div className="menuIcon-link"
              onMouseEnter={() => {
                popupOff()
                setOpenMenu(false)
                setOpenNotification(true)
              }}
              onClick={() => {
                setOpenNotification(!openNotification)
              }}
            >
              <FontAwesomeIcon icon={faBell} />
            </div>
          </div> */}

          <div onMouseLeave={() => setOpenNotification(false)}>
            <Notification openNotification={openNotification} />
          </div>
        </div>
        {/*<ul className="helpIcons">*/}
        {/*  { iconMenu.map((icon, i) => (*/}
        {/*    <li key={i} className="menuIcon">*/}
        {/*      <div*/}
        {/*        className="menuIcon-link"*/}
        {/*        onClick={() => {*/}
        {/*          if (popupMenu) popupOff()*/}
        {/*          else {*/}
        {/*            popupOn(i)*/}
        {/*            setOpenNotification(false)*/}
        {/*            setOpenMenu(false)*/}
        {/*          }*/}
        {/*          history.push(icon.link)*/}
        {/*        }}*/}
        {/*        onMouseEnter={() => {*/}
        {/*          setOpenNotification(false)*/}
        {/*          setOpenMenu(false)*/}
        {/*        }}>*/}
        {/*        <FontAwesomeIcon icon={icon.icon} />*/}
        {/*      </div>*/}
        {/*      <div className="hoverPopup font_12">*/}
        {/*        {icon.hover}*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*  ))}*/}
        {/*</ul>*/}

        {/*<Button*/}
        {/*  classProps="trashButton p8-px br50-per cur-p"*/}
        {/*  text={ <FontAwesomeIcon icon={faTrashAlt} size={"2x"}/> }*/}
        {/*  // function={layouts.openModal}*/}
        {/*/>*/}

      </div>
      <div onMouseLeave={() => popupOff()}>
        {/*<ul className={`popupHelp ${popupMenu ? "visible" : ""}`}>*/}
        {/*  {popupList.map((list, i) => (*/}
        {/*    <li key={i}>*/}
        {/*      <div*/}
        {/*        className="popupHelp-link"*/}
        {/*        onClick={() => {*/}
        {/*          popupOff()*/}
        {/*          history.push(list.link)*/}
        {/*        }}>*/}
        {/*        <FontAwesomeIcon icon={list.icon} />*/}
        {/*        {list.menu}*/}
        {/*      </div>*/}
        {/*    </li>*/}
        {/*  ))}*/}
        {/*</ul>*/}
        <div
          className="deleteOverlay"
          onClick={popupOff}
        />
      </div>
      <nav
        className={`openMenu ${openMenu && "opened"}`}
        onMouseLeave={() => setOpenMenu(false)}
      >
        <h4 className="menu_title">メニュー</h4>
        <ul>
          { menuList.map((menu: any, i: number) => (
            <Can
              I="read"
              a={menu.can}
              key={i}
            >
              <li className="menuList">
                <Link
                  className="menuList_link bold"
                  to={menu.link}
                  onClick={() => setOpenMenu(false)}
                >
                  {menu.list}
                </Link>
                  {/*<div className="menuList_link bold"*/}
                  {/*  onClick={() => {*/}
                  {/*    setOpenMenu(false)*/}
                  {/*    history.push(menu.link)*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  {menu.list}*/}
                  {/*</div>*/}
              </li>
            </Can>
          ))}
        </ul>
      </nav>
    </div >
  )
}