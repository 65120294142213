// Modules
import React from 'react'
import { useRecoilValue } from "recoil"
// Components
import { Button } from "../../Atoms/Button"
import { AccountModal } from "./AccountModal"
// Functions
import { translateFunc } from "../../../functions/CommonFunction"
import { LayoutsFunctions } from "../../../functions/Layouts/LayoutsFunctions"
// Config
import {
  accountModal,
  storageState
} from "../../../config/Common/RecoilState"
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUser } from "@fortawesome/free-solid-svg-icons"

// *************** Type *************** //
type Props = {
  className?: string
}

export const LoginInfo: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const {
    userType,
    uId,
    userName
  } = useRecoilValue(storageState)
  const isModal = useRecoilValue(accountModal)
  const { layouts } = LayoutsFunctions()

  // *************** JSX *************** //
  return (
    <div
      className={`loginInfo ${props.className}
        pos-r p5-px br3-px"
      `}
    >
      <div className="loginUser dis-f fai-center">
        <p className="dis-inline-flex fai-center">
          <span className="">ようこそ</span>
          <span className="ml1-rem">
            { translateFunc(userType)["TabMenu"] }
          </span>
          <span className="ml1-rem fw-b">{userName}</span>
          <span>様</span>
        </p>
        <Button
          classProps="accountButton ml1-rem pv8-px ph10-px br50-per cur-p"
          text={ <FontAwesomeIcon icon={faUser} /> }
          function={layouts.openModal}
        />
      </div>
      {/* アカウントモーダル */}
      { isModal &&
        <AccountModal
          userType={userType}
          uId={uId}
        />
      }
    </div>
  )
}