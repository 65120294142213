// Modules
import React from 'react'
import { useLocation } from "react-router-dom"
import { useRecoilValue } from "recoil"
// Components
import Authentication from "./Authentication/Authentication"
import Help from "./client/Help"
import Rule from "./client/Rule"
import Policy from "./client/Policy"
import Contact from "./client/Contact"
import { Menu } from "../Layouts/Menu"
import { Content } from "../Layouts/Content"
import { NotUseContent } from "../Layouts/NotUseContent"
// Config
import { Can } from "../config/Common/Can"
import { storageState } from "../config/Common/RecoilState"

// *************** Type *************** //
type Props = {
  ability?: any
}

export const App: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { pathname } = useLocation()
  const data = useRecoilValue(storageState)

  // *************** JSX *************** //
  return (
    <>
      { !data ? (
        <Authentication />
      ) : pathname === '/Help' ? (
        <Help />
      ) : pathname === '/Rule' ? (
        <Rule />
      ) : pathname === '/Policy' ? (
        <Policy />
      ) : pathname === '/Contact' ? (
        <Contact />
      ) : (data && data.adminApproval) || data.userType === 'staffs' ? (
        <div className="whole">
          <Can
            I='read'
            a='menu'
          >
            <Menu
              admin={data.admin}
              user={data.userType}
              uId={data.uId}
            />
          </Can>
          <Content storageData={data} />
        </div>
      ) : (
        <div className="whole">
          <div className="contents">
            <Can
              I="read"
              a="header"
            >
            </Can>
            <NotUseContent />
          </div>
        </div>
      )}
    </>
  )
}

export default App