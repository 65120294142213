// Modules
import React from 'react'
// Components
import { Label } from '../../Atoms/Label'
import { Image } from "../../Atoms/Image"
import { Input } from "../../Atoms/Input"
import { Button } from "../../Atoms/Button"
// Functions
import { SysKeyFunctions } from '../../../functions/Master/SysKeyFunctions'
import { CommonFunctions } from "../../../functions/CommonFunction"
// Config
import pdfImage from '../../../Images/PDF.png'

// *************** Type *************** //
type Props = {
  data?: any
  label?: any
  userImages?: Array<any>
  setUserImages: Function
  fileImage?: any
  setFileImage: Function
  storageImg?: any
  setStorageImg: Function
  form?: string
  setForm: Function
}

export const UploadImageList: React.VFC<Props> = (
  props: Props
) => {
  // *************** State *************** //
  const { commons } = CommonFunctions()
  const { functions } = SysKeyFunctions()

  // *************** JSX *************** //
  return (
    <li className="masterForm-image">
      <div className="masterForm-image-wrapper">
        <Label
          className="pb-1 text-center"
          htmlFor="masterForm-image"
          text="画像アップロード"
        />
        <div className="formSet p-4">
          <Button
            classProps="cancelButton block ml-auto py-2 px-5"
            text="クリア"
            function={() => commons.clearArray(props.setFileImage)}
          />
          <label
            htmlFor="image"
            className="masterForm-image block w-11/12 mt-3 mx-auto text-center"
          >
            <Input
              name="image"
              type="file"
              multiple={true}
              accept="image/*, .pdf"
              function={(e: any) =>
                functions.previewImages(
                  e,
                  props.userImages,
                  props.fileImage,
                  props.setFileImage,
                  props.storageImg,
                  props.setStorageImg
                )
              }
            />
            ファイルを選択
          </label>
          {/* アップロード済み画像の表示 */}
          { props.fileImage && props.fileImage.length > 0 &&
            <ul className="UploadImages flex justify-center w-80 mx-auto">
              { props.fileImage.map((image: any, i: number) => (
                <li className="UploadImages-item mt6-per">
                  <div className="dis-inline-flex fjc-sb align-center">
                    <p>{image.name}</p>
                    <Button
                      classProps="cancelButton"
                      text="削除"
                      function={() => commons.removeArray(
                        i,
                        props.fileImage,
                        props.setFileImage
                      )}
                    />
                  </div>
                  <div
                    key={i}
                    className={`imageBlock w-32 mx-auto`}
                  >
                    <Image
                      src={ image.name.substr(-3) === 'pdf' ?
                        pdfImage : image.path
                      }
                      alt={image.label}
                    />
                  </div>
                  <Input
                    className="w-40"
                    name="label"
                    type="text"
                    value={props.form ? props.form[image.label] : "" }
                    function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setArrayValue(
                      i,
                      e.target.name,
                      e.target.value,
                      props.fileImage,
                      props.setFileImage
                    )}
                  />
                </li>
              ))}
            </ul>
          }
          <section className="mt-5">
            <h3 className="font-bold text-xl">アップロード済画像</h3>
            <ul className="UploadImages w-80 mt-5 mx-auto">
              {/*/!*データベースの画像 *!/*/}
              { props.userImages &&
                props.userImages.map((image: any, i: number) => {
                return (
                  <li className="UploadImages-item">
                    <div className="flex justify-around items-center mt-2">
                      <p>{image.name}</p>
                    </div>
                    <div
                      key={i}
                      className="imageBlock w-80 mx-auto p-5"
                    >
                      <Image
                        src={ image.name && image.name.substr(-3) === 'pdf' ?
                          pdfImage : image.path
                        }
                        alt={image.label}
                      />
                    </div>
                    <p className="font-bold">{image.label}</p>
                    {/*<Input*/}
                    {/*  className="w70-per"*/}
                    {/*  name="label"*/}
                    {/*  type="text"*/}
                    {/*  value={ props.form && props.form[image.label] ? props.form[image.label] : "" }*/}
                    {/*  function={(e: React.ChangeEvent<HTMLInputElement>) => commons.setArrayValue(*/}
                    {/*    i,*/}
                    {/*    e,*/}
                    {/*    props.userImages,*/}
                    {/*    props.setFileImage*/}
                    {/*  )}*/}
                    {/*/>*/}
                  </li>
                )
              })}
            </ul>
          </section>
        </div>
      </div>
    </li>
  )
}