// *************** Routes *************** //
export const breadRoutes = [
  {
    path: '/',
    breadcrumb: 'HOME'
  },
  {
    path: '/CompanyInfo',
    breadcrumb: 'example'
  },
  {
    path: '/POLODocuments',
    breadcrumb: 'POLO申請書一覧'
  },
  {
    path: '/POLODocuments/id',
    breadcrumb: 'POLO申請書一覧'
  },
  {
    path: '/ApprovalUser/:uId/:cId',
  },
  {
    path: '/DocumentCatalog/:visa/:id/:language',
    breadcrumb: '申請書一覧'
  },
  {
    path: '/DocTemplateForm',
    breadcrumb: '申請書テンプレート作成'
  },
  {
    path: '/:userType/Detail/:uId',
    breadcrumb: '詳細'
  },
  {
    path: '/MasterFormList',
    breadcrumb: '登録フォームテンプレ作成'
  },
  {
    path: '/StaffStatus/:uId',
    breadcrumb: '候補者ステータス'
  },
  {
    path: '/pages/client/ApplyCompany',
  },
  {
    path: `/ClientList/rsos`,
    breadcrumb: '顧客リスト'
  },
  {
    path: '/interviewStaffs/companies',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/interviewStaffs/agencies',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/applyStaffs/agencies',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/SpecifiedSkill/enterStatus/agencies',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/career/agencies',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/staffs/agencies',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/document/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/staffs/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/interviewStaffs/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/applyStaffs/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/recruitStaffs/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/SpecifiedSkill/enterStatus/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/career/rsos',
    breadcrumb: '連携ユーザー選択'
  },
  {
    path: '/document/rsos/companies/:cId',
    breadcrumb: '候補者申請書作成'
  },
  {
    path: '/staffs/:cId',
    breadcrumb: '候補者一覧'
  },
  {
    path: '/interviewStaffs/:cId',
    breadcrumb: '面接者一覧'
  },
  {
    path: '/recruitStaffs/:cId',
    breadcrumb: '内定者一覧'
  },
  {
    path: '/staffs/agencies/companies/:cId',
    breadcrumb: '候補者一覧'
  },
  {
    path: '/applyStaffs/agencies/companies/:cId',
    breadcrumb: '応募者一覧'
  },
  {
    path: '/interviewStaffs/agencies/companies/:cId',
    breadcrumb: '面接者一覧'
  },
  {
    path: '/applyStaffs/rsos/:userType/:userId',
    breadcrumb: '応募者一覧'
  },
  {
    path: '/interviewStaffs/rsos/:userType/:userId',
    breadcrumb: '面接者一覧'
  },
  {
    path: '/recruitStaffs/rsos/:userType/:uId',
    breadcrumb: '内定者一覧'
  },
  {
    path: '/staffs/rsos/companies/:cId',
    breadcrumb: '候補者一覧'
  },
  {
    path: '/staffs/rsos/agencies/:cId',
  },
  {
    path: '/:userType/:id/basic',
    breadcrumb: "基本情報登録"
  },
  {
    path: '/:userType/:id/jobOffer',
    breadcrumb: "履歴書情報登録"
  },
  {
    path: '/:userType/:id/other',
    breadcrumb: "手続き情報登録"
  },
  {
    path: '/document/:visa/:id/:language/:dId',
    breadcrumb: '申請書作成'
  },
  {
    path: '/POLODocuments/:visa/:id/:language/:dId',
    breadcrumb: 'POLO申請書作成'
  },
  {
    path: '/QuarterlyReport/:visa/:id/:language/:dId',
    breadcrumb: '定期面談報告作成'
  },
  {
    path: '/SpecifiedSkill/enterStatus/:uId',
    breadcrumb: '特定技能就労前'
  },
  {
    path: '/Trainee/enterStatus/:uId',
    breadcrumb: '技能実習就労前'
  },
  {
    path: '/SpecifiedSkill/enteredStatus/:uId',
    breadcrumb: '特定技能就労後'
  },
  {
    path: '/Trainee/enteredStatus/:uId',
    breadcrumb: '技能実習就労後'
  },
  {
    path: '/SpecifiedSkill/enterStatus/rsos/companies/:uId',
    breadcrumb: '特定技能就労前'
  },
  {
    path: '/Trainee/enterStatus/rsos/companies/:uId',
    breadcrumb: '技能実習就労前'
  },
  {
    path: '/SpecifiedSkill/enteredStatus/rsos/companies/:uId',
    breadcrumb: '特定技能就労後'
  },
  {
    path: '/Trainee/enteredStatus/rsos/companies/:uId',
    breadcrumb: '技能実習就労後'
  },
  {
    path: '/SpecifiedSkill/enterStatus/rsos/agencies/:uId',
    breadcrumb: '特定技能就労前'
  },
  {
    path: '/Trainee/enterStatus/rsos/agencies/:uId',
    breadcrumb: '技能実習就労前'
  },
  {
    path: '/SpecifiedSkill/enteredStatus/rsos/agencies/:uId',
    breadcrumb: '特定技能就労後'
  },
  {
    path: '/Trainee/enteredStatus/rsos/agencies/:uId',
    breadcrumb: '技能実習就労後'
  },
  {
    path: '/SpecifiedSkill/enterStatus/agencies/companies/:uId',
    breadcrumb: '特定技能就労前'
  },
  {
    path: '/Trainee/enterStatus/agencies/companies/:uId',
    breadcrumb: '技能実習就労前'
  },
  {
    path: '/career/:cId',
    breadcrumb: '候補者職歴'
  },
  {
    path: '/career/companies/:cId',
    breadcrumb: '候補者職歴'
  },
  {
    path: '/career/companies/:cId',
    breadcrumb: '候補者職歴'
  },
  {
    path: '/career/agencies/:aId',
    breadcrumb: '候補者職歴'
  },
  {
    path: '/ApprovalUser/:uId/:cId',
    breadcrumb: 'ユーザー承認'
  },
  {
    path: '/ApprovalAdmin/:userType/:uId',
    breadcrumb: 'ユーザー承認'
  },
]


// admin用
export const adminBreadRoutes = [
  {
    path: '/',
    breadcrumb: 'HOME'
  },
  {
    path: '/CompanyInfo',
    breadcrumb: 'example'
  },
  {
    path: '/POLODocuments',
    breadcrumb: 'POLO申請書一覧'
  },
  {
    path: '/POLODocuments/id',
    breadcrumb: 'POLO申請書一覧'
  },
  {
    path: '/ApprovalUser/:uId/:cId',
  },
  {
    path: '/DocumentCatalog/:visa/:id/:language',
    breadcrumb: '申請書一覧'
  },
  {
    path: '/DocTemplateForm',
    breadcrumb: '申請書テンプレート作成'
  },
  {
    path: '/:userType/Detail/:uId',
    breadcrumb: '詳細'
  },
  {
    path: '/MasterFormList',
    breadcrumb: '登録フォームテンプレ作成'
  },
  {
    path: '/StatusCatalog/:uId',
  },
  {
    path: '/pages/client/ApplyCompany',
  },
  {
    path: `/ClientList`,
    breadcrumb: '顧客リスト'
  },
  {
    path: '/document',
    breadcrumb: '候補者申請書作成'
  },
  {
    path: '/staffs',
    breadcrumb: '候補者一覧'
  },
  {
    path: '/companies',
    breadcrumb: '受け入れ企業一覧'
  },
  {
    path: '/kumiai',
    breadcrumb: '監理団体一覧'
  },
  {
    path: '/agencies',
    breadcrumb: '送り出し機関一覧'
  },
  {
    path: '/rsos',
    breadcrumb: '登録支援機関一覧'
  },
  {
    path: '/:userType/:id/basic',
    breadcrumb: "基本情報登録"
  },
  {
    path: '/:userType/:id/jobOffer',
    breadcrumb: "履歴書情報登録"
  },
  {
    path: '/:userType/:id/other',
    breadcrumb: "その他情報登録"
  },
  {
    path: '/document/:visa/:id/:language/:dId',
    breadcrumb: '申請書作成'
  },
  {
    path: '/POLODocuments/:visa/:id/:language/:dId',
    breadcrumb: 'POLO申請書作成'
  },
  {
    path: '/QuarterlyReport/:visa/:id/:language/:dId',
    breadcrumb: '定期面談報告作成'
  },
  {
    path: '/SpecifiedSkill/enterStatus',
    breadcrumb: '特定技能就労前'
  },
  {
    path: '/Trainee/enterStatus',
    breadcrumb: '技能実習就労前'
  },
  {
    path: '/SpecifiedSkill/enteredStatus',
    breadcrumb: '特定技能就労後'
  },
  {
    path: '/Trainee/enteredStatus',
    breadcrumb: '技能実習就労後'
  },
  {
    path: '/career',
    breadcrumb: '候補者職歴'
  },
  {
    path: '/ApprovalAdmin/:userType/:uId',
    breadcrumb: 'ユーザー承認'
  }
]