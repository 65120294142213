// Modules
import React from 'react'
// Components
import { FilterGlobal } from "./FilterGlobal"

// *************** Type *************** //
type Props = {
  className?: string
  sticky: boolean
  getTableProps: any
  getTableBodyProps: any
  headerGroups: any
  rows: any
  prepareRow: any
  state?: any
  setFilter?: any
  setGlobalFilter?: any
  visibleColumns?: any
  preGlobalFilteredRows?: any
  firstPageRows?: any
}

export const Table = ({
  className,
  sticky,
  getTableProps,
  getTableBodyProps,
  headerGroups,
  rows,
  prepareRow,
  state: { globalFilter },
  setGlobalFilter,
  preGlobalFilteredRows,
}: Props) => {
  // *************** JSX *************** //
  return (
    <div
      {...getTableProps()}
      className={`${className} tableLayout ${sticky ? 'stickyLayout' : ''}`}
    >
      <div className="headerLayout">
        { headerGroups.map((headerGroup: any) => (
          <div
            {...headerGroup.getHeaderGroupProps()}
            className="tr bg-navy"
          >
            { headerGroup.headers.map((column: any) => (
              <div
                {...column.getHeaderProps()}
                className="th py-5 px-3 text-center bg-navy text-white font-bold"
              >
                {column.render('Header')}
              </div>
            ))}
          </div>
        ))}
        <div className="tr bg-white">
          <FilterGlobal
            sticky={sticky}
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </div>
      </div>
      <div
        {...getTableBodyProps()}
        className="body"
      >
        { rows.map((row: any) => {
          prepareRow(row)
          return (
            <div
              {...row.getRowProps()}
              className="tr"
            >
              { row.cells.map((cell: any) => {
                return (
                  <div
                    {...cell.getCellProps(
                      {
                        style: cell.column.style
                      },
                    )}
                    className="td py-5 px-3 text-center"
                  >
                    {cell.render('Cell')}
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
    </div>
  )
}