// Modules
import React, {
  useState
} from 'react'
// Component
import { AuthForm } from './AuthForm'
import { Button } from "../../Atoms/Button"
// Functions
import { AuthFunctions } from "../../../functions/Authentication/AuthFunctions"

// *************** Type *************** //
type Props = {
  setForgetOn: Function
}

export const ForgetPassword: React.VFC<Props> = (
  props: Props
) => {
  // *************** Const *************** //
  const { auth } = AuthFunctions()
  const [value, setValue] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<any>({})
  const [postMessage, setPostMessage] = useState<string>('')

  // *************** JSX *************** //
  return (
    <div className="ta-c">
      <h3 className="mt3-per fw-b fs30-px">
        パスワードリセット
      </h3>
      { postMessage &&
        <p className="errorMessage mt2-per">
          { postMessage }
        </p>
      }
      <p className="comment mt2-per">
        パスワード変更用のメールを送信します
      </p>
      <AuthForm
        component="forget"
        passwordLabel="新パスワード"
        value={value}
        setValue={setValue}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
      />
      <Button
        classProps={`updateButton
          ${!!Object.values(errorMessage).filter((error: any) => error).length && 'disable'}
          w10-rem mt3-per pv15-px
        `}
        text="送信"
        disabled={
          !!Object.values(errorMessage).filter((error: any) => error).length
        }
        function={() => auth.handleReset(
          value,
          setPostMessage,
          props.setForgetOn
        )}
      />
    </div>
  )
}